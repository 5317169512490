import Link from "next/link";
import { useRouter } from "next/router";

import { ShieldIcon } from "@/components/icons/ShieldIcon";
import { Skeleton } from "@/components/Skeleton";
import { Heading } from "@/components/v2/Heading/Heading";
import { Text } from "@/components/v2/Text/Text";
import { useFunnelAnswers } from "@/modules/v2/funnel/hooks/useFunnelAnswers";
import { useFeatureFlag } from "@/services/experiments";
import { featureFlagNames } from "@/services/experiments/constants";

import { Label } from "../base/Label";
import { StickyActions } from "../base/StickyActions";

function FieldSkeleton({ label }: { label: string }) {
  return (
    <div className="flex w-full flex-col gap-2">
      <Label className="opacity-20">{label}</Label>
      <Skeleton className="h-14" />
    </div>
  );
}

export function FormSkeleton() {
  const { funnelAnswers } = useFunnelAnswers();
  const router = useRouter();
  const subPractice = router.query.subPractice as string;

  const abTestHighIntent = useFeatureFlag(
    featureFlagNames.FUNNEL_TEST_DIRECT_LSS_HIGH,
    {
      subPractice,
    },
  );
  return (
    <div className="flex flex-col gap-7">
      <Heading variant="h4" className="text-center font-normal opacity-20">
        Payment details
      </Heading>
      <Skeleton className="h-40" />
      <div className="flex flex-col gap-5">
        <Heading variant="h5" className="font-sans font-medium opacity-20">
          Enter your credit card details
        </Heading>
        <FieldSkeleton label="Name on card" />
        <FieldSkeleton label="Credit or debit card" />
        <div className="flex flex-col gap-5 md:flex-row md:justify-between md:gap-3">
          <FieldSkeleton label="Expiration date" />
          <FieldSkeleton label="CVV" />
        </div>
        <FieldSkeleton label="Billing address" />
      </div>
      <div className="flex items-center gap-2 opacity-20">
        <ShieldIcon height={18} width={16} />
        <Text className="font-medium" variant="text-6">
          SSL Encrypted, Secure Payment
        </Text>
      </div>
      <Text variant="text-6" className="text-center text-blue-40">
        By continuing, you accept our{" "}
        <Link target="_blank" href="/privacy" className="text-green-100">
          privacy policy &{" "}
        </Link>
        <Link target="_blank" href="/terms-of-use" className="text-green-100">
          terms of use.{" "}
        </Link>
        You also authorize Marble to electronically debit your account. You
        understand this authorization will remain in effect until you notify
        Marble in writing that you wish to revoke it. The revocation may take up
        to 10 business days to take effect.
      </Text>
      {abTestHighIntent.isOn && funnelAnswers.leadIntent === "high" && (
        <Skeleton className="align-center flex h-[218px]  md:h-[220px]" />
      )}
      <StickyActions classNames="flex flex-col gap-4 justify-center items-center md:self-center md:w-screen md:mb-[-32px]">
        <Skeleton className="h-14 w-full max-w-[350px]" />
      </StickyActions>
    </div>
  );
}
