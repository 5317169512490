import * as Sentry from "@sentry/nextjs";
import { add, endOfDay } from "date-fns";

import { analytics } from "@flare/analytics";

import { getCompetencies } from "@/components/Funnel/utils/get-competencies";
import { getFips } from "@/components/Funnel/utils/get-fips";
import type { HasAvailableSlotsQuery } from "@/generated/graphql.vinny";
import {
  BookeeMeetingType,
  HasAvailableSlotsDocument,
  PracticeAreaName,
} from "@/generated/graphql.vinny";
import { client as apolloClient } from "@/lib/apollo-client";
import type { FunnelAnswers } from "@/modules/v2/funnel/config";
import { BiEventsNames } from "@/services/analytics/event-names";
import { FUNNEL_DATA_SESSION_STORAGE_KEY } from "@/services/storage";
import { getData } from "@/services/storage/session-storage";
import { requestTimeout } from "@/utils/request-timeout";

const MAX_MS_BOOKIT_RESPONSE = 5000;
const MAX_DAYS_TO_LSS_MEETING = 10;
const MIN_LSS_SLOTS = 1;

export async function checkAttorneyAvailability() {
  const funnelAnswers =
    (getData(FUNNEL_DATA_SESSION_STORAGE_KEY) as FunnelAnswers) || {};
  const { practice, subPractice, county, stateCode, serviceType, sponsorType } =
    funnelAnswers;

  const practiceArea: PracticeAreaName =
    practice?.toUpperCase() === PracticeAreaName.Immigration
      ? PracticeAreaName.Immigration
      : PracticeAreaName.Family;

  const competencies = getCompetencies({
    practice: practiceArea,
    subPractice,
    serviceType,
    sponsorType,
  });

  const fips = getFips({
    county,
    stateCode,
  });

  const now = new Date();

  try {
    const { data } = await Promise.race([
      apolloClient.query<HasAvailableSlotsQuery>({
        query: HasAvailableSlotsDocument,
        variables: {
          payload: {
            meetingType: BookeeMeetingType.Lss,
            practiceArea,
            stateCode,
            fips,
            competencies,
            minSlots: MIN_LSS_SLOTS,
            from: now,
            to: endOfDay(add(now, { days: MAX_DAYS_TO_LSS_MEETING })),
          },
        },
      }),
      requestTimeout(MAX_MS_BOOKIT_RESPONSE),
    ]);

    analytics.track(BiEventsNames.WebCcAvailabilityCheck, {
      has_slots: data.hasAvailableSlots,
    });

    return data.hasAvailableSlots;
  } catch (error) {
    Sentry.captureException(error, {
      extra: {
        message: `error fetching lss slots for practiceArea: ${practice?.toString().toUpperCase()}, stateCode: ${stateCode}`,
      },
    });

    analytics.track(BiEventsNames.WebFunnelError, {
      error_type: "check_attorney_availability_error",
      error_message: error instanceof Error ? error?.message : "unknown error",
    });
    // If there is an error, we will assume we have attorney availability
    return true;
  }
}
