import { jsx as A, jsxs as it } from "react/jsx-runtime";
import * as p from "react";
import { useLayoutEffect as Zt, useEffect as Jt, forwardRef as Qt } from "react";
import { d as we, b as Y, c as st, u as Pe, a as en } from "../../index-C5hYt6jn.js";
import { u as te, a as tn } from "../../index-CCavb-1K.js";
import { P as K, d as nn } from "../../index-u2zzFwMH.js";
import { u as on } from "../../index-Citj79Jd.js";
import * as rn from "react-dom";
import sn from "react-dom";
import { u as cn } from "../../index-CicxFo47.js";
import { P as ct } from "../../index-DXZDAYJp.js";
import { cn as an } from "../../utils/cn.js";
import { focusRing as ln } from "../../utils/focus-ring.js";
function fn(e, t = globalThis == null ? void 0 : globalThis.document) {
  const n = we(e);
  p.useEffect(() => {
    const o = (r) => {
      r.key === "Escape" && n(r);
    };
    return t.addEventListener("keydown", o, { capture: !0 }), () => t.removeEventListener("keydown", o, { capture: !0 });
  }, [n, t]);
}
var un = "DismissableLayer", Ae = "dismissableLayer.update", dn = "dismissableLayer.pointerDownOutside", pn = "dismissableLayer.focusOutside", Ge, at = p.createContext({
  layers: /* @__PURE__ */ new Set(),
  layersWithOutsidePointerEventsDisabled: /* @__PURE__ */ new Set(),
  branches: /* @__PURE__ */ new Set()
}), lt = p.forwardRef(
  (e, t) => {
    const {
      disableOutsidePointerEvents: n = !1,
      onEscapeKeyDown: o,
      onPointerDownOutside: r,
      onFocusOutside: i,
      onInteractOutside: s,
      onDismiss: l,
      ...c
    } = e, a = p.useContext(at), [f, u] = p.useState(null), h = (f == null ? void 0 : f.ownerDocument) ?? (globalThis == null ? void 0 : globalThis.document), [, d] = p.useState({}), m = te(t, (E) => u(E)), g = Array.from(a.layers), [y] = [...a.layersWithOutsidePointerEventsDisabled].slice(-1), w = g.indexOf(y), v = f ? g.indexOf(f) : -1, C = a.layersWithOutsidePointerEventsDisabled.size > 0, x = v >= w, b = gn((E) => {
      const T = E.target, M = [...a.branches].some((L) => L.contains(T));
      !x || M || (r == null || r(E), s == null || s(E), E.defaultPrevented || l == null || l());
    }, h), P = yn((E) => {
      const T = E.target;
      [...a.branches].some((L) => L.contains(T)) || (i == null || i(E), s == null || s(E), E.defaultPrevented || l == null || l());
    }, h);
    return fn((E) => {
      v === a.layers.size - 1 && (o == null || o(E), !E.defaultPrevented && l && (E.preventDefault(), l()));
    }, h), p.useEffect(() => {
      if (f)
        return n && (a.layersWithOutsidePointerEventsDisabled.size === 0 && (Ge = h.body.style.pointerEvents, h.body.style.pointerEvents = "none"), a.layersWithOutsidePointerEventsDisabled.add(f)), a.layers.add(f), qe(), () => {
          n && a.layersWithOutsidePointerEventsDisabled.size === 1 && (h.body.style.pointerEvents = Ge);
        };
    }, [f, h, n, a]), p.useEffect(() => () => {
      f && (a.layers.delete(f), a.layersWithOutsidePointerEventsDisabled.delete(f), qe());
    }, [f, a]), p.useEffect(() => {
      const E = () => d({});
      return document.addEventListener(Ae, E), () => document.removeEventListener(Ae, E);
    }, []), /* @__PURE__ */ A(
      K.div,
      {
        ...c,
        ref: m,
        style: {
          pointerEvents: C ? x ? "auto" : "none" : void 0,
          ...e.style
        },
        onFocusCapture: Y(e.onFocusCapture, P.onFocusCapture),
        onBlurCapture: Y(e.onBlurCapture, P.onBlurCapture),
        onPointerDownCapture: Y(
          e.onPointerDownCapture,
          b.onPointerDownCapture
        )
      }
    );
  }
);
lt.displayName = un;
var hn = "DismissableLayerBranch", mn = p.forwardRef((e, t) => {
  const n = p.useContext(at), o = p.useRef(null), r = te(t, o);
  return p.useEffect(() => {
    const i = o.current;
    if (i)
      return n.branches.add(i), () => {
        n.branches.delete(i);
      };
  }, [n.branches]), /* @__PURE__ */ A(K.div, { ...e, ref: r });
});
mn.displayName = hn;
function gn(e, t = globalThis == null ? void 0 : globalThis.document) {
  const n = we(e), o = p.useRef(!1), r = p.useRef(() => {
  });
  return p.useEffect(() => {
    const i = (l) => {
      if (l.target && !o.current) {
        let c = function() {
          ft(
            dn,
            n,
            a,
            { discrete: !0 }
          );
        };
        const a = { originalEvent: l };
        l.pointerType === "touch" ? (t.removeEventListener("click", r.current), r.current = c, t.addEventListener("click", r.current, { once: !0 })) : c();
      } else
        t.removeEventListener("click", r.current);
      o.current = !1;
    }, s = window.setTimeout(() => {
      t.addEventListener("pointerdown", i);
    }, 0);
    return () => {
      window.clearTimeout(s), t.removeEventListener("pointerdown", i), t.removeEventListener("click", r.current);
    };
  }, [t, n]), {
    // ensures we check React component tree (not just DOM tree)
    onPointerDownCapture: () => o.current = !0
  };
}
function yn(e, t = globalThis == null ? void 0 : globalThis.document) {
  const n = we(e), o = p.useRef(!1);
  return p.useEffect(() => {
    const r = (i) => {
      i.target && !o.current && ft(pn, n, { originalEvent: i }, {
        discrete: !1
      });
    };
    return t.addEventListener("focusin", r), () => t.removeEventListener("focusin", r);
  }, [t, n]), {
    onFocusCapture: () => o.current = !0,
    onBlurCapture: () => o.current = !1
  };
}
function qe() {
  const e = new CustomEvent(Ae);
  document.dispatchEvent(e);
}
function ft(e, t, n, { discrete: o }) {
  const r = n.originalEvent.target, i = new CustomEvent(e, { bubbles: !1, cancelable: !0, detail: n });
  t && r.addEventListener(e, t, { once: !0 }), o ? nn(r, i) : r.dispatchEvent(i);
}
const wn = ["top", "right", "bottom", "left"], I = Math.min, F = Math.max, he = Math.round, de = Math.floor, G = (e) => ({
  x: e,
  y: e
}), vn = {
  left: "right",
  right: "left",
  bottom: "top",
  top: "bottom"
}, xn = {
  start: "end",
  end: "start"
};
function Re(e, t, n) {
  return F(e, I(t, n));
}
function j(e, t) {
  return typeof e == "function" ? e(t) : e;
}
function X(e) {
  return e.split("-")[0];
}
function ne(e) {
  return e.split("-")[1];
}
function Le(e) {
  return e === "x" ? "y" : "x";
}
function Se(e) {
  return e === "y" ? "height" : "width";
}
function oe(e) {
  return ["top", "bottom"].includes(X(e)) ? "y" : "x";
}
function Me(e) {
  return Le(oe(e));
}
function bn(e, t, n) {
  n === void 0 && (n = !1);
  const o = ne(e), r = Me(e), i = Se(r);
  let s = r === "x" ? o === (n ? "end" : "start") ? "right" : "left" : o === "start" ? "bottom" : "top";
  return t.reference[i] > t.floating[i] && (s = me(s)), [s, me(s)];
}
function Cn(e) {
  const t = me(e);
  return [Te(e), t, Te(t)];
}
function Te(e) {
  return e.replace(/start|end/g, (t) => xn[t]);
}
function En(e, t, n) {
  const o = ["left", "right"], r = ["right", "left"], i = ["top", "bottom"], s = ["bottom", "top"];
  switch (e) {
    case "top":
    case "bottom":
      return n ? t ? r : o : t ? o : r;
    case "left":
    case "right":
      return t ? i : s;
    default:
      return [];
  }
}
function Pn(e, t, n, o) {
  const r = ne(e);
  let i = En(X(e), n === "start", o);
  return r && (i = i.map((s) => s + "-" + r), t && (i = i.concat(i.map(Te)))), i;
}
function me(e) {
  return e.replace(/left|right|bottom|top/g, (t) => vn[t]);
}
function An(e) {
  return {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    ...e
  };
}
function ut(e) {
  return typeof e != "number" ? An(e) : {
    top: e,
    right: e,
    bottom: e,
    left: e
  };
}
function ge(e) {
  const {
    x: t,
    y: n,
    width: o,
    height: r
  } = e;
  return {
    width: o,
    height: r,
    top: n,
    left: t,
    right: t + o,
    bottom: n + r,
    x: t,
    y: n
  };
}
function Ke(e, t, n) {
  let {
    reference: o,
    floating: r
  } = e;
  const i = oe(t), s = Me(t), l = Se(s), c = X(t), a = i === "y", f = o.x + o.width / 2 - r.width / 2, u = o.y + o.height / 2 - r.height / 2, h = o[l] / 2 - r[l] / 2;
  let d;
  switch (c) {
    case "top":
      d = {
        x: f,
        y: o.y - r.height
      };
      break;
    case "bottom":
      d = {
        x: f,
        y: o.y + o.height
      };
      break;
    case "right":
      d = {
        x: o.x + o.width,
        y: u
      };
      break;
    case "left":
      d = {
        x: o.x - r.width,
        y: u
      };
      break;
    default:
      d = {
        x: o.x,
        y: o.y
      };
  }
  switch (ne(t)) {
    case "start":
      d[s] -= h * (n && a ? -1 : 1);
      break;
    case "end":
      d[s] += h * (n && a ? -1 : 1);
      break;
  }
  return d;
}
const Rn = async (e, t, n) => {
  const {
    placement: o = "bottom",
    strategy: r = "absolute",
    middleware: i = [],
    platform: s
  } = n, l = i.filter(Boolean), c = await (s.isRTL == null ? void 0 : s.isRTL(t));
  let a = await s.getElementRects({
    reference: e,
    floating: t,
    strategy: r
  }), {
    x: f,
    y: u
  } = Ke(a, o, c), h = o, d = {}, m = 0;
  for (let g = 0; g < l.length; g++) {
    const {
      name: y,
      fn: w
    } = l[g], {
      x: v,
      y: C,
      data: x,
      reset: b
    } = await w({
      x: f,
      y: u,
      initialPlacement: o,
      placement: h,
      strategy: r,
      middlewareData: d,
      rects: a,
      platform: s,
      elements: {
        reference: e,
        floating: t
      }
    });
    f = v ?? f, u = C ?? u, d = {
      ...d,
      [y]: {
        ...d[y],
        ...x
      }
    }, b && m <= 50 && (m++, typeof b == "object" && (b.placement && (h = b.placement), b.rects && (a = b.rects === !0 ? await s.getElementRects({
      reference: e,
      floating: t,
      strategy: r
    }) : b.rects), {
      x: f,
      y: u
    } = Ke(a, h, c)), g = -1);
  }
  return {
    x: f,
    y: u,
    placement: h,
    strategy: r,
    middlewareData: d
  };
};
async function se(e, t) {
  var n;
  t === void 0 && (t = {});
  const {
    x: o,
    y: r,
    platform: i,
    rects: s,
    elements: l,
    strategy: c
  } = e, {
    boundary: a = "clippingAncestors",
    rootBoundary: f = "viewport",
    elementContext: u = "floating",
    altBoundary: h = !1,
    padding: d = 0
  } = j(t, e), m = ut(d), y = l[h ? u === "floating" ? "reference" : "floating" : u], w = ge(await i.getClippingRect({
    element: (n = await (i.isElement == null ? void 0 : i.isElement(y))) == null || n ? y : y.contextElement || await (i.getDocumentElement == null ? void 0 : i.getDocumentElement(l.floating)),
    boundary: a,
    rootBoundary: f,
    strategy: c
  })), v = u === "floating" ? {
    x: o,
    y: r,
    width: s.floating.width,
    height: s.floating.height
  } : s.reference, C = await (i.getOffsetParent == null ? void 0 : i.getOffsetParent(l.floating)), x = await (i.isElement == null ? void 0 : i.isElement(C)) ? await (i.getScale == null ? void 0 : i.getScale(C)) || {
    x: 1,
    y: 1
  } : {
    x: 1,
    y: 1
  }, b = ge(i.convertOffsetParentRelativeRectToViewportRelativeRect ? await i.convertOffsetParentRelativeRectToViewportRelativeRect({
    elements: l,
    rect: v,
    offsetParent: C,
    strategy: c
  }) : v);
  return {
    top: (w.top - b.top + m.top) / x.y,
    bottom: (b.bottom - w.bottom + m.bottom) / x.y,
    left: (w.left - b.left + m.left) / x.x,
    right: (b.right - w.right + m.right) / x.x
  };
}
const Tn = (e) => ({
  name: "arrow",
  options: e,
  async fn(t) {
    const {
      x: n,
      y: o,
      placement: r,
      rects: i,
      platform: s,
      elements: l,
      middlewareData: c
    } = t, {
      element: a,
      padding: f = 0
    } = j(e, t) || {};
    if (a == null)
      return {};
    const u = ut(f), h = {
      x: n,
      y: o
    }, d = Me(r), m = Se(d), g = await s.getDimensions(a), y = d === "y", w = y ? "top" : "left", v = y ? "bottom" : "right", C = y ? "clientHeight" : "clientWidth", x = i.reference[m] + i.reference[d] - h[d] - i.floating[m], b = h[d] - i.reference[d], P = await (s.getOffsetParent == null ? void 0 : s.getOffsetParent(a));
    let E = P ? P[C] : 0;
    (!E || !await (s.isElement == null ? void 0 : s.isElement(P))) && (E = l.floating[C] || i.floating[m]);
    const T = x / 2 - b / 2, M = E / 2 - g[m] / 2 - 1, L = I(u[w], M), k = I(u[v], M), D = L, N = E - g[m] - k, S = E / 2 - g[m] / 2 + T, O = Re(D, S, N), _ = !c.arrow && ne(r) != null && S !== O && i.reference[m] / 2 - (S < D ? L : k) - g[m] / 2 < 0, R = _ ? S < D ? S - D : S - N : 0;
    return {
      [d]: h[d] + R,
      data: {
        [d]: O,
        centerOffset: S - O - R,
        ..._ && {
          alignmentOffset: R
        }
      },
      reset: _
    };
  }
}), On = function(e) {
  return e === void 0 && (e = {}), {
    name: "flip",
    options: e,
    async fn(t) {
      var n, o;
      const {
        placement: r,
        middlewareData: i,
        rects: s,
        initialPlacement: l,
        platform: c,
        elements: a
      } = t, {
        mainAxis: f = !0,
        crossAxis: u = !0,
        fallbackPlacements: h,
        fallbackStrategy: d = "bestFit",
        fallbackAxisSideDirection: m = "none",
        flipAlignment: g = !0,
        ...y
      } = j(e, t);
      if ((n = i.arrow) != null && n.alignmentOffset)
        return {};
      const w = X(r), v = X(l) === l, C = await (c.isRTL == null ? void 0 : c.isRTL(a.floating)), x = h || (v || !g ? [me(l)] : Cn(l));
      !h && m !== "none" && x.push(...Pn(l, g, m, C));
      const b = [l, ...x], P = await se(t, y), E = [];
      let T = ((o = i.flip) == null ? void 0 : o.overflows) || [];
      if (f && E.push(P[w]), u) {
        const D = bn(r, s, C);
        E.push(P[D[0]], P[D[1]]);
      }
      if (T = [...T, {
        placement: r,
        overflows: E
      }], !E.every((D) => D <= 0)) {
        var M, L;
        const D = (((M = i.flip) == null ? void 0 : M.index) || 0) + 1, N = b[D];
        if (N)
          return {
            data: {
              index: D,
              overflows: T
            },
            reset: {
              placement: N
            }
          };
        let S = (L = T.filter((O) => O.overflows[0] <= 0).sort((O, _) => O.overflows[1] - _.overflows[1])[0]) == null ? void 0 : L.placement;
        if (!S)
          switch (d) {
            case "bestFit": {
              var k;
              const O = (k = T.map((_) => [_.placement, _.overflows.filter((R) => R > 0).reduce((R, H) => R + H, 0)]).sort((_, R) => _[1] - R[1])[0]) == null ? void 0 : k[0];
              O && (S = O);
              break;
            }
            case "initialPlacement":
              S = l;
              break;
          }
        if (r !== S)
          return {
            reset: {
              placement: S
            }
          };
      }
      return {};
    }
  };
};
function Ze(e, t) {
  return {
    top: e.top - t.height,
    right: e.right - t.width,
    bottom: e.bottom - t.height,
    left: e.left - t.width
  };
}
function Je(e) {
  return wn.some((t) => e[t] >= 0);
}
const Dn = function(e) {
  return e === void 0 && (e = {}), {
    name: "hide",
    options: e,
    async fn(t) {
      const {
        rects: n
      } = t, {
        strategy: o = "referenceHidden",
        ...r
      } = j(e, t);
      switch (o) {
        case "referenceHidden": {
          const i = await se(t, {
            ...r,
            elementContext: "reference"
          }), s = Ze(i, n.reference);
          return {
            data: {
              referenceHiddenOffsets: s,
              referenceHidden: Je(s)
            }
          };
        }
        case "escaped": {
          const i = await se(t, {
            ...r,
            altBoundary: !0
          }), s = Ze(i, n.floating);
          return {
            data: {
              escapedOffsets: s,
              escaped: Je(s)
            }
          };
        }
        default:
          return {};
      }
    }
  };
};
async function Ln(e, t) {
  const {
    placement: n,
    platform: o,
    elements: r
  } = e, i = await (o.isRTL == null ? void 0 : o.isRTL(r.floating)), s = X(n), l = ne(n), c = oe(n) === "y", a = ["left", "top"].includes(s) ? -1 : 1, f = i && c ? -1 : 1, u = j(t, e);
  let {
    mainAxis: h,
    crossAxis: d,
    alignmentAxis: m
  } = typeof u == "number" ? {
    mainAxis: u,
    crossAxis: 0,
    alignmentAxis: null
  } : {
    mainAxis: 0,
    crossAxis: 0,
    alignmentAxis: null,
    ...u
  };
  return l && typeof m == "number" && (d = l === "end" ? m * -1 : m), c ? {
    x: d * f,
    y: h * a
  } : {
    x: h * a,
    y: d * f
  };
}
const Sn = function(e) {
  return e === void 0 && (e = 0), {
    name: "offset",
    options: e,
    async fn(t) {
      var n, o;
      const {
        x: r,
        y: i,
        placement: s,
        middlewareData: l
      } = t, c = await Ln(t, e);
      return s === ((n = l.offset) == null ? void 0 : n.placement) && (o = l.arrow) != null && o.alignmentOffset ? {} : {
        x: r + c.x,
        y: i + c.y,
        data: {
          ...c,
          placement: s
        }
      };
    }
  };
}, Mn = function(e) {
  return e === void 0 && (e = {}), {
    name: "shift",
    options: e,
    async fn(t) {
      const {
        x: n,
        y: o,
        placement: r
      } = t, {
        mainAxis: i = !0,
        crossAxis: s = !1,
        limiter: l = {
          fn: (y) => {
            let {
              x: w,
              y: v
            } = y;
            return {
              x: w,
              y: v
            };
          }
        },
        ...c
      } = j(e, t), a = {
        x: n,
        y: o
      }, f = await se(t, c), u = oe(X(r)), h = Le(u);
      let d = a[h], m = a[u];
      if (i) {
        const y = h === "y" ? "top" : "left", w = h === "y" ? "bottom" : "right", v = d + f[y], C = d - f[w];
        d = Re(v, d, C);
      }
      if (s) {
        const y = u === "y" ? "top" : "left", w = u === "y" ? "bottom" : "right", v = m + f[y], C = m - f[w];
        m = Re(v, m, C);
      }
      const g = l.fn({
        ...t,
        [h]: d,
        [u]: m
      });
      return {
        ...g,
        data: {
          x: g.x - n,
          y: g.y - o
        }
      };
    }
  };
}, kn = function(e) {
  return e === void 0 && (e = {}), {
    options: e,
    fn(t) {
      const {
        x: n,
        y: o,
        placement: r,
        rects: i,
        middlewareData: s
      } = t, {
        offset: l = 0,
        mainAxis: c = !0,
        crossAxis: a = !0
      } = j(e, t), f = {
        x: n,
        y: o
      }, u = oe(r), h = Le(u);
      let d = f[h], m = f[u];
      const g = j(l, t), y = typeof g == "number" ? {
        mainAxis: g,
        crossAxis: 0
      } : {
        mainAxis: 0,
        crossAxis: 0,
        ...g
      };
      if (c) {
        const C = h === "y" ? "height" : "width", x = i.reference[h] - i.floating[C] + y.mainAxis, b = i.reference[h] + i.reference[C] - y.mainAxis;
        d < x ? d = x : d > b && (d = b);
      }
      if (a) {
        var w, v;
        const C = h === "y" ? "width" : "height", x = ["top", "left"].includes(X(r)), b = i.reference[u] - i.floating[C] + (x && ((w = s.offset) == null ? void 0 : w[u]) || 0) + (x ? 0 : y.crossAxis), P = i.reference[u] + i.reference[C] + (x ? 0 : ((v = s.offset) == null ? void 0 : v[u]) || 0) - (x ? y.crossAxis : 0);
        m < b ? m = b : m > P && (m = P);
      }
      return {
        [h]: d,
        [u]: m
      };
    }
  };
}, _n = function(e) {
  return e === void 0 && (e = {}), {
    name: "size",
    options: e,
    async fn(t) {
      const {
        placement: n,
        rects: o,
        platform: r,
        elements: i
      } = t, {
        apply: s = () => {
        },
        ...l
      } = j(e, t), c = await se(t, l), a = X(n), f = ne(n), u = oe(n) === "y", {
        width: h,
        height: d
      } = o.floating;
      let m, g;
      a === "top" || a === "bottom" ? (m = a, g = f === (await (r.isRTL == null ? void 0 : r.isRTL(i.floating)) ? "start" : "end") ? "left" : "right") : (g = a, m = f === "end" ? "top" : "bottom");
      const y = d - c.top - c.bottom, w = h - c.left - c.right, v = I(d - c[m], y), C = I(h - c[g], w), x = !t.middlewareData.shift;
      let b = v, P = C;
      if (u ? P = f || x ? I(C, w) : w : b = f || x ? I(v, y) : y, x && !f) {
        const T = F(c.left, 0), M = F(c.right, 0), L = F(c.top, 0), k = F(c.bottom, 0);
        u ? P = h - 2 * (T !== 0 || M !== 0 ? T + M : F(c.left, c.right)) : b = d - 2 * (L !== 0 || k !== 0 ? L + k : F(c.top, c.bottom));
      }
      await s({
        ...t,
        availableWidth: P,
        availableHeight: b
      });
      const E = await r.getDimensions(i.floating);
      return h !== E.width || d !== E.height ? {
        reset: {
          rects: !0
        }
      } : {};
    }
  };
};
function re(e) {
  return dt(e) ? (e.nodeName || "").toLowerCase() : "#document";
}
function $(e) {
  var t;
  return (e == null || (t = e.ownerDocument) == null ? void 0 : t.defaultView) || window;
}
function U(e) {
  var t;
  return (t = (dt(e) ? e.ownerDocument : e.document) || window.document) == null ? void 0 : t.documentElement;
}
function dt(e) {
  return e instanceof Node || e instanceof $(e).Node;
}
function W(e) {
  return e instanceof Element || e instanceof $(e).Element;
}
function V(e) {
  return e instanceof HTMLElement || e instanceof $(e).HTMLElement;
}
function Qe(e) {
  return typeof ShadowRoot > "u" ? !1 : e instanceof ShadowRoot || e instanceof $(e).ShadowRoot;
}
function ae(e) {
  const {
    overflow: t,
    overflowX: n,
    overflowY: o,
    display: r
  } = B(e);
  return /auto|scroll|overlay|hidden|clip/.test(t + o + n) && !["inline", "contents"].includes(r);
}
function Nn(e) {
  return ["table", "td", "th"].includes(re(e));
}
function ke(e) {
  const t = _e(), n = B(e);
  return n.transform !== "none" || n.perspective !== "none" || (n.containerType ? n.containerType !== "normal" : !1) || !t && (n.backdropFilter ? n.backdropFilter !== "none" : !1) || !t && (n.filter ? n.filter !== "none" : !1) || ["transform", "perspective", "filter"].some((o) => (n.willChange || "").includes(o)) || ["paint", "layout", "strict", "content"].some((o) => (n.contain || "").includes(o));
}
function Hn(e) {
  let t = q(e);
  for (; V(t) && !Q(t); ) {
    if (ke(t))
      return t;
    t = q(t);
  }
  return null;
}
function _e() {
  return typeof CSS > "u" || !CSS.supports ? !1 : CSS.supports("-webkit-backdrop-filter", "none");
}
function Q(e) {
  return ["html", "body", "#document"].includes(re(e));
}
function B(e) {
  return $(e).getComputedStyle(e);
}
function ve(e) {
  return W(e) ? {
    scrollLeft: e.scrollLeft,
    scrollTop: e.scrollTop
  } : {
    scrollLeft: e.pageXOffset,
    scrollTop: e.pageYOffset
  };
}
function q(e) {
  if (re(e) === "html")
    return e;
  const t = (
    // Step into the shadow DOM of the parent of a slotted node.
    e.assignedSlot || // DOM Element detected.
    e.parentNode || // ShadowRoot detected.
    Qe(e) && e.host || // Fallback.
    U(e)
  );
  return Qe(t) ? t.host : t;
}
function pt(e) {
  const t = q(e);
  return Q(t) ? e.ownerDocument ? e.ownerDocument.body : e.body : V(t) && ae(t) ? t : pt(t);
}
function ce(e, t, n) {
  var o;
  t === void 0 && (t = []), n === void 0 && (n = !0);
  const r = pt(e), i = r === ((o = e.ownerDocument) == null ? void 0 : o.body), s = $(r);
  return i ? t.concat(s, s.visualViewport || [], ae(r) ? r : [], s.frameElement && n ? ce(s.frameElement) : []) : t.concat(r, ce(r, [], n));
}
function ht(e) {
  const t = B(e);
  let n = parseFloat(t.width) || 0, o = parseFloat(t.height) || 0;
  const r = V(e), i = r ? e.offsetWidth : n, s = r ? e.offsetHeight : o, l = he(n) !== i || he(o) !== s;
  return l && (n = i, o = s), {
    width: n,
    height: o,
    $: l
  };
}
function Ne(e) {
  return W(e) ? e : e.contextElement;
}
function J(e) {
  const t = Ne(e);
  if (!V(t))
    return G(1);
  const n = t.getBoundingClientRect(), {
    width: o,
    height: r,
    $: i
  } = ht(t);
  let s = (i ? he(n.width) : n.width) / o, l = (i ? he(n.height) : n.height) / r;
  return (!s || !Number.isFinite(s)) && (s = 1), (!l || !Number.isFinite(l)) && (l = 1), {
    x: s,
    y: l
  };
}
const Fn = /* @__PURE__ */ G(0);
function mt(e) {
  const t = $(e);
  return !_e() || !t.visualViewport ? Fn : {
    x: t.visualViewport.offsetLeft,
    y: t.visualViewport.offsetTop
  };
}
function $n(e, t, n) {
  return t === void 0 && (t = !1), !n || t && n !== $(e) ? !1 : t;
}
function Z(e, t, n, o) {
  t === void 0 && (t = !1), n === void 0 && (n = !1);
  const r = e.getBoundingClientRect(), i = Ne(e);
  let s = G(1);
  t && (o ? W(o) && (s = J(o)) : s = J(e));
  const l = $n(i, n, o) ? mt(i) : G(0);
  let c = (r.left + l.x) / s.x, a = (r.top + l.y) / s.y, f = r.width / s.x, u = r.height / s.y;
  if (i) {
    const h = $(i), d = o && W(o) ? $(o) : o;
    let m = h, g = m.frameElement;
    for (; g && o && d !== m; ) {
      const y = J(g), w = g.getBoundingClientRect(), v = B(g), C = w.left + (g.clientLeft + parseFloat(v.paddingLeft)) * y.x, x = w.top + (g.clientTop + parseFloat(v.paddingTop)) * y.y;
      c *= y.x, a *= y.y, f *= y.x, u *= y.y, c += C, a += x, m = $(g), g = m.frameElement;
    }
  }
  return ge({
    width: f,
    height: u,
    x: c,
    y: a
  });
}
const Bn = [":popover-open", ":modal"];
function He(e) {
  return Bn.some((t) => {
    try {
      return e.matches(t);
    } catch {
      return !1;
    }
  });
}
function In(e) {
  let {
    elements: t,
    rect: n,
    offsetParent: o,
    strategy: r
  } = e;
  const i = r === "fixed", s = U(o), l = t ? He(t.floating) : !1;
  if (o === s || l && i)
    return n;
  let c = {
    scrollLeft: 0,
    scrollTop: 0
  }, a = G(1);
  const f = G(0), u = V(o);
  if ((u || !u && !i) && ((re(o) !== "body" || ae(s)) && (c = ve(o)), V(o))) {
    const h = Z(o);
    a = J(o), f.x = h.x + o.clientLeft, f.y = h.y + o.clientTop;
  }
  return {
    width: n.width * a.x,
    height: n.height * a.y,
    x: n.x * a.x - c.scrollLeft * a.x + f.x,
    y: n.y * a.y - c.scrollTop * a.y + f.y
  };
}
function Wn(e) {
  return Array.from(e.getClientRects());
}
function gt(e) {
  return Z(U(e)).left + ve(e).scrollLeft;
}
function Vn(e) {
  const t = U(e), n = ve(e), o = e.ownerDocument.body, r = F(t.scrollWidth, t.clientWidth, o.scrollWidth, o.clientWidth), i = F(t.scrollHeight, t.clientHeight, o.scrollHeight, o.clientHeight);
  let s = -n.scrollLeft + gt(e);
  const l = -n.scrollTop;
  return B(o).direction === "rtl" && (s += F(t.clientWidth, o.clientWidth) - r), {
    width: r,
    height: i,
    x: s,
    y: l
  };
}
function zn(e, t) {
  const n = $(e), o = U(e), r = n.visualViewport;
  let i = o.clientWidth, s = o.clientHeight, l = 0, c = 0;
  if (r) {
    i = r.width, s = r.height;
    const a = _e();
    (!a || a && t === "fixed") && (l = r.offsetLeft, c = r.offsetTop);
  }
  return {
    width: i,
    height: s,
    x: l,
    y: c
  };
}
function Yn(e, t) {
  const n = Z(e, !0, t === "fixed"), o = n.top + e.clientTop, r = n.left + e.clientLeft, i = V(e) ? J(e) : G(1), s = e.clientWidth * i.x, l = e.clientHeight * i.y, c = r * i.x, a = o * i.y;
  return {
    width: s,
    height: l,
    x: c,
    y: a
  };
}
function et(e, t, n) {
  let o;
  if (t === "viewport")
    o = zn(e, n);
  else if (t === "document")
    o = Vn(U(e));
  else if (W(t))
    o = Yn(t, n);
  else {
    const r = mt(e);
    o = {
      ...t,
      x: t.x - r.x,
      y: t.y - r.y
    };
  }
  return ge(o);
}
function yt(e, t) {
  const n = q(e);
  return n === t || !W(n) || Q(n) ? !1 : B(n).position === "fixed" || yt(n, t);
}
function jn(e, t) {
  const n = t.get(e);
  if (n)
    return n;
  let o = ce(e, [], !1).filter((l) => W(l) && re(l) !== "body"), r = null;
  const i = B(e).position === "fixed";
  let s = i ? q(e) : e;
  for (; W(s) && !Q(s); ) {
    const l = B(s), c = ke(s);
    !c && l.position === "fixed" && (r = null), (i ? !c && !r : !c && l.position === "static" && !!r && ["absolute", "fixed"].includes(r.position) || ae(s) && !c && yt(e, s)) ? o = o.filter((f) => f !== s) : r = l, s = q(s);
  }
  return t.set(e, o), o;
}
function Xn(e) {
  let {
    element: t,
    boundary: n,
    rootBoundary: o,
    strategy: r
  } = e;
  const s = [...n === "clippingAncestors" ? He(t) ? [] : jn(t, this._c) : [].concat(n), o], l = s[0], c = s.reduce((a, f) => {
    const u = et(t, f, r);
    return a.top = F(u.top, a.top), a.right = I(u.right, a.right), a.bottom = I(u.bottom, a.bottom), a.left = F(u.left, a.left), a;
  }, et(t, l, r));
  return {
    width: c.right - c.left,
    height: c.bottom - c.top,
    x: c.left,
    y: c.top
  };
}
function Un(e) {
  const {
    width: t,
    height: n
  } = ht(e);
  return {
    width: t,
    height: n
  };
}
function Gn(e, t, n) {
  const o = V(t), r = U(t), i = n === "fixed", s = Z(e, !0, i, t);
  let l = {
    scrollLeft: 0,
    scrollTop: 0
  };
  const c = G(0);
  if (o || !o && !i)
    if ((re(t) !== "body" || ae(r)) && (l = ve(t)), o) {
      const u = Z(t, !0, i, t);
      c.x = u.x + t.clientLeft, c.y = u.y + t.clientTop;
    } else r && (c.x = gt(r));
  const a = s.left + l.scrollLeft - c.x, f = s.top + l.scrollTop - c.y;
  return {
    x: a,
    y: f,
    width: s.width,
    height: s.height
  };
}
function Ee(e) {
  return B(e).position === "static";
}
function tt(e, t) {
  return !V(e) || B(e).position === "fixed" ? null : t ? t(e) : e.offsetParent;
}
function wt(e, t) {
  const n = $(e);
  if (He(e))
    return n;
  if (!V(e)) {
    let r = q(e);
    for (; r && !Q(r); ) {
      if (W(r) && !Ee(r))
        return r;
      r = q(r);
    }
    return n;
  }
  let o = tt(e, t);
  for (; o && Nn(o) && Ee(o); )
    o = tt(o, t);
  return o && Q(o) && Ee(o) && !ke(o) ? n : o || Hn(e) || n;
}
const qn = async function(e) {
  const t = this.getOffsetParent || wt, n = this.getDimensions, o = await n(e.floating);
  return {
    reference: Gn(e.reference, await t(e.floating), e.strategy),
    floating: {
      x: 0,
      y: 0,
      width: o.width,
      height: o.height
    }
  };
};
function Kn(e) {
  return B(e).direction === "rtl";
}
const Zn = {
  convertOffsetParentRelativeRectToViewportRelativeRect: In,
  getDocumentElement: U,
  getClippingRect: Xn,
  getOffsetParent: wt,
  getElementRects: qn,
  getClientRects: Wn,
  getDimensions: Un,
  getScale: J,
  isElement: W,
  isRTL: Kn
};
function Jn(e, t) {
  let n = null, o;
  const r = U(e);
  function i() {
    var l;
    clearTimeout(o), (l = n) == null || l.disconnect(), n = null;
  }
  function s(l, c) {
    l === void 0 && (l = !1), c === void 0 && (c = 1), i();
    const {
      left: a,
      top: f,
      width: u,
      height: h
    } = e.getBoundingClientRect();
    if (l || t(), !u || !h)
      return;
    const d = de(f), m = de(r.clientWidth - (a + u)), g = de(r.clientHeight - (f + h)), y = de(a), v = {
      rootMargin: -d + "px " + -m + "px " + -g + "px " + -y + "px",
      threshold: F(0, I(1, c)) || 1
    };
    let C = !0;
    function x(b) {
      const P = b[0].intersectionRatio;
      if (P !== c) {
        if (!C)
          return s();
        P ? s(!1, P) : o = setTimeout(() => {
          s(!1, 1e-7);
        }, 1e3);
      }
      C = !1;
    }
    try {
      n = new IntersectionObserver(x, {
        ...v,
        // Handle <iframe>s
        root: r.ownerDocument
      });
    } catch {
      n = new IntersectionObserver(x, v);
    }
    n.observe(e);
  }
  return s(!0), i;
}
function Qn(e, t, n, o) {
  o === void 0 && (o = {});
  const {
    ancestorScroll: r = !0,
    ancestorResize: i = !0,
    elementResize: s = typeof ResizeObserver == "function",
    layoutShift: l = typeof IntersectionObserver == "function",
    animationFrame: c = !1
  } = o, a = Ne(e), f = r || i ? [...a ? ce(a) : [], ...ce(t)] : [];
  f.forEach((w) => {
    r && w.addEventListener("scroll", n, {
      passive: !0
    }), i && w.addEventListener("resize", n);
  });
  const u = a && l ? Jn(a, n) : null;
  let h = -1, d = null;
  s && (d = new ResizeObserver((w) => {
    let [v] = w;
    v && v.target === a && d && (d.unobserve(t), cancelAnimationFrame(h), h = requestAnimationFrame(() => {
      var C;
      (C = d) == null || C.observe(t);
    })), n();
  }), a && !c && d.observe(a), d.observe(t));
  let m, g = c ? Z(e) : null;
  c && y();
  function y() {
    const w = Z(e);
    g && (w.x !== g.x || w.y !== g.y || w.width !== g.width || w.height !== g.height) && n(), g = w, m = requestAnimationFrame(y);
  }
  return n(), () => {
    var w;
    f.forEach((v) => {
      r && v.removeEventListener("scroll", n), i && v.removeEventListener("resize", n);
    }), u == null || u(), (w = d) == null || w.disconnect(), d = null, c && cancelAnimationFrame(m);
  };
}
const eo = Sn, to = Mn, no = On, oo = _n, ro = Dn, nt = Tn, io = kn, so = (e, t, n) => {
  const o = /* @__PURE__ */ new Map(), r = {
    platform: Zn,
    ...n
  }, i = {
    ...r.platform,
    _c: o
  };
  return Rn(e, t, {
    ...r,
    platform: i
  });
};
var pe = typeof document < "u" ? Zt : Jt;
function ye(e, t) {
  if (e === t)
    return !0;
  if (typeof e != typeof t)
    return !1;
  if (typeof e == "function" && e.toString() === t.toString())
    return !0;
  let n, o, r;
  if (e && t && typeof e == "object") {
    if (Array.isArray(e)) {
      if (n = e.length, n !== t.length) return !1;
      for (o = n; o-- !== 0; )
        if (!ye(e[o], t[o]))
          return !1;
      return !0;
    }
    if (r = Object.keys(e), n = r.length, n !== Object.keys(t).length)
      return !1;
    for (o = n; o-- !== 0; )
      if (!{}.hasOwnProperty.call(t, r[o]))
        return !1;
    for (o = n; o-- !== 0; ) {
      const i = r[o];
      if (!(i === "_owner" && e.$$typeof) && !ye(e[i], t[i]))
        return !1;
    }
    return !0;
  }
  return e !== e && t !== t;
}
function vt(e) {
  return typeof window > "u" ? 1 : (e.ownerDocument.defaultView || window).devicePixelRatio || 1;
}
function ot(e, t) {
  const n = vt(e);
  return Math.round(t * n) / n;
}
function rt(e) {
  const t = p.useRef(e);
  return pe(() => {
    t.current = e;
  }), t;
}
function co(e) {
  e === void 0 && (e = {});
  const {
    placement: t = "bottom",
    strategy: n = "absolute",
    middleware: o = [],
    platform: r,
    elements: {
      reference: i,
      floating: s
    } = {},
    transform: l = !0,
    whileElementsMounted: c,
    open: a
  } = e, [f, u] = p.useState({
    x: 0,
    y: 0,
    strategy: n,
    placement: t,
    middlewareData: {},
    isPositioned: !1
  }), [h, d] = p.useState(o);
  ye(h, o) || d(o);
  const [m, g] = p.useState(null), [y, w] = p.useState(null), v = p.useCallback((R) => {
    R !== P.current && (P.current = R, g(R));
  }, []), C = p.useCallback((R) => {
    R !== E.current && (E.current = R, w(R));
  }, []), x = i || m, b = s || y, P = p.useRef(null), E = p.useRef(null), T = p.useRef(f), M = c != null, L = rt(c), k = rt(r), D = p.useCallback(() => {
    if (!P.current || !E.current)
      return;
    const R = {
      placement: t,
      strategy: n,
      middleware: h
    };
    k.current && (R.platform = k.current), so(P.current, E.current, R).then((H) => {
      const z = {
        ...H,
        isPositioned: !0
      };
      N.current && !ye(T.current, z) && (T.current = z, rn.flushSync(() => {
        u(z);
      }));
    });
  }, [h, t, n, k]);
  pe(() => {
    a === !1 && T.current.isPositioned && (T.current.isPositioned = !1, u((R) => ({
      ...R,
      isPositioned: !1
    })));
  }, [a]);
  const N = p.useRef(!1);
  pe(() => (N.current = !0, () => {
    N.current = !1;
  }), []), pe(() => {
    if (x && (P.current = x), b && (E.current = b), x && b) {
      if (L.current)
        return L.current(x, b, D);
      D();
    }
  }, [x, b, D, L, M]);
  const S = p.useMemo(() => ({
    reference: P,
    floating: E,
    setReference: v,
    setFloating: C
  }), [v, C]), O = p.useMemo(() => ({
    reference: x,
    floating: b
  }), [x, b]), _ = p.useMemo(() => {
    const R = {
      position: n,
      left: 0,
      top: 0
    };
    if (!O.floating)
      return R;
    const H = ot(O.floating, f.x), z = ot(O.floating, f.y);
    return l ? {
      ...R,
      transform: "translate(" + H + "px, " + z + "px)",
      ...vt(O.floating) >= 1.5 && {
        willChange: "transform"
      }
    } : {
      position: n,
      left: H,
      top: z
    };
  }, [n, l, O.floating, f.x, f.y]);
  return p.useMemo(() => ({
    ...f,
    update: D,
    refs: S,
    elements: O,
    floatingStyles: _
  }), [f, D, S, O, _]);
}
const ao = (e) => {
  function t(n) {
    return {}.hasOwnProperty.call(n, "current");
  }
  return {
    name: "arrow",
    options: e,
    fn(n) {
      const {
        element: o,
        padding: r
      } = typeof e == "function" ? e(n) : e;
      return o && t(o) ? o.current != null ? nt({
        element: o.current,
        padding: r
      }).fn(n) : {} : o ? nt({
        element: o,
        padding: r
      }).fn(n) : {};
    }
  };
}, lo = (e, t) => ({
  ...eo(e),
  options: [e, t]
}), fo = (e, t) => ({
  ...to(e),
  options: [e, t]
}), uo = (e, t) => ({
  ...io(e),
  options: [e, t]
}), po = (e, t) => ({
  ...no(e),
  options: [e, t]
}), ho = (e, t) => ({
  ...oo(e),
  options: [e, t]
}), mo = (e, t) => ({
  ...ro(e),
  options: [e, t]
}), go = (e, t) => ({
  ...ao(e),
  options: [e, t]
});
var yo = "Arrow", xt = p.forwardRef((e, t) => {
  const { children: n, width: o = 10, height: r = 5, ...i } = e;
  return /* @__PURE__ */ A(
    K.svg,
    {
      ...i,
      ref: t,
      width: o,
      height: r,
      viewBox: "0 0 30 10",
      preserveAspectRatio: "none",
      children: e.asChild ? n : /* @__PURE__ */ A("polygon", { points: "0,0 30,0 15,10" })
    }
  );
});
xt.displayName = yo;
var wo = xt, Fe = "Popper", [bt, Ct] = st(Fe), [vo, Et] = bt(Fe), Pt = (e) => {
  const { __scopePopper: t, children: n } = e, [o, r] = p.useState(null);
  return /* @__PURE__ */ A(vo, { scope: t, anchor: o, onAnchorChange: r, children: n });
};
Pt.displayName = Fe;
var At = "PopperAnchor", Rt = p.forwardRef(
  (e, t) => {
    const { __scopePopper: n, virtualRef: o, ...r } = e, i = Et(At, n), s = p.useRef(null), l = te(t, s);
    return p.useEffect(() => {
      i.onAnchorChange((o == null ? void 0 : o.current) || s.current);
    }), o ? null : /* @__PURE__ */ A(K.div, { ...r, ref: l });
  }
);
Rt.displayName = At;
var $e = "PopperContent", [xo, bo] = bt($e), Tt = p.forwardRef(
  (e, t) => {
    var We, Ve, ze, Ye, je, Xe;
    const {
      __scopePopper: n,
      side: o = "bottom",
      sideOffset: r = 0,
      align: i = "center",
      alignOffset: s = 0,
      arrowPadding: l = 0,
      avoidCollisions: c = !0,
      collisionBoundary: a = [],
      collisionPadding: f = 0,
      sticky: u = "partial",
      hideWhenDetached: h = !1,
      updatePositionStrategy: d = "optimized",
      onPlaced: m,
      ...g
    } = e, y = Et($e, n), [w, v] = p.useState(null), C = te(t, (ie) => v(ie)), [x, b] = p.useState(null), P = cn(x), E = (P == null ? void 0 : P.width) ?? 0, T = (P == null ? void 0 : P.height) ?? 0, M = o + (i !== "center" ? "-" + i : ""), L = typeof f == "number" ? f : { top: 0, right: 0, bottom: 0, left: 0, ...f }, k = Array.isArray(a) ? a : [a], D = k.length > 0, N = {
      padding: L,
      boundary: k.filter(Eo),
      // with `strategy: 'fixed'`, this is the only way to get it to respect boundaries
      altBoundary: D
    }, { refs: S, floatingStyles: O, placement: _, isPositioned: R, middlewareData: H } = co({
      // default to `fixed` strategy so users don't have to pick and we also avoid focus scroll issues
      strategy: "fixed",
      placement: M,
      whileElementsMounted: (...ie) => Qn(...ie, {
        animationFrame: d === "always"
      }),
      elements: {
        reference: y.anchor
      },
      middleware: [
        lo({ mainAxis: r + T, alignmentAxis: s }),
        c && fo({
          mainAxis: !0,
          crossAxis: !1,
          limiter: u === "partial" ? uo() : void 0,
          ...N
        }),
        c && po({ ...N }),
        ho({
          ...N,
          apply: ({ elements: ie, rects: Ue, availableWidth: Ut, availableHeight: Gt }) => {
            const { width: qt, height: Kt } = Ue.reference, ue = ie.floating.style;
            ue.setProperty("--radix-popper-available-width", `${Ut}px`), ue.setProperty("--radix-popper-available-height", `${Gt}px`), ue.setProperty("--radix-popper-anchor-width", `${qt}px`), ue.setProperty("--radix-popper-anchor-height", `${Kt}px`);
          }
        }),
        x && go({ element: x, padding: l }),
        Po({ arrowWidth: E, arrowHeight: T }),
        h && mo({ strategy: "referenceHidden", ...N })
      ]
    }), [z, Wt] = Lt(_), fe = we(m);
    Pe(() => {
      R && (fe == null || fe());
    }, [R, fe]);
    const Vt = (We = H.arrow) == null ? void 0 : We.x, zt = (Ve = H.arrow) == null ? void 0 : Ve.y, Yt = ((ze = H.arrow) == null ? void 0 : ze.centerOffset) !== 0, [jt, Xt] = p.useState();
    return Pe(() => {
      w && Xt(window.getComputedStyle(w).zIndex);
    }, [w]), /* @__PURE__ */ A(
      "div",
      {
        ref: S.setFloating,
        "data-radix-popper-content-wrapper": "",
        style: {
          ...O,
          transform: R ? O.transform : "translate(0, -200%)",
          // keep off the page when measuring
          minWidth: "max-content",
          zIndex: jt,
          "--radix-popper-transform-origin": [
            (Ye = H.transformOrigin) == null ? void 0 : Ye.x,
            (je = H.transformOrigin) == null ? void 0 : je.y
          ].join(" "),
          // hide the content if using the hide middleware and should be hidden
          // set visibility to hidden and disable pointer events so the UI behaves
          // as if the PopperContent isn't there at all
          ...((Xe = H.hide) == null ? void 0 : Xe.referenceHidden) && {
            visibility: "hidden",
            pointerEvents: "none"
          }
        },
        dir: e.dir,
        children: /* @__PURE__ */ A(
          xo,
          {
            scope: n,
            placedSide: z,
            onArrowChange: b,
            arrowX: Vt,
            arrowY: zt,
            shouldHideArrow: Yt,
            children: /* @__PURE__ */ A(
              K.div,
              {
                "data-side": z,
                "data-align": Wt,
                ...g,
                ref: C,
                style: {
                  ...g.style,
                  // if the PopperContent hasn't been placed yet (not all measurements done)
                  // we prevent animations so that users's animation don't kick in too early referring wrong sides
                  animation: R ? void 0 : "none"
                }
              }
            )
          }
        )
      }
    );
  }
);
Tt.displayName = $e;
var Ot = "PopperArrow", Co = {
  top: "bottom",
  right: "left",
  bottom: "top",
  left: "right"
}, Dt = p.forwardRef(function(t, n) {
  const { __scopePopper: o, ...r } = t, i = bo(Ot, o), s = Co[i.placedSide];
  return (
    // we have to use an extra wrapper because `ResizeObserver` (used by `useSize`)
    // doesn't report size as we'd expect on SVG elements.
    // it reports their bounding box which is effectively the largest path inside the SVG.
    /* @__PURE__ */ A(
      "span",
      {
        ref: i.onArrowChange,
        style: {
          position: "absolute",
          left: i.arrowX,
          top: i.arrowY,
          [s]: 0,
          transformOrigin: {
            top: "",
            right: "0 0",
            bottom: "center 0",
            left: "100% 0"
          }[i.placedSide],
          transform: {
            top: "translateY(100%)",
            right: "translateY(50%) rotate(90deg) translateX(-50%)",
            bottom: "rotate(180deg)",
            left: "translateY(50%) rotate(-90deg) translateX(50%)"
          }[i.placedSide],
          visibility: i.shouldHideArrow ? "hidden" : void 0
        },
        children: /* @__PURE__ */ A(
          wo,
          {
            ...r,
            ref: n,
            style: {
              ...r.style,
              // ensures the element can be measured correctly (mostly for if SVG)
              display: "block"
            }
          }
        )
      }
    )
  );
});
Dt.displayName = Ot;
function Eo(e) {
  return e !== null;
}
var Po = (e) => ({
  name: "transformOrigin",
  options: e,
  fn(t) {
    var y, w, v;
    const { placement: n, rects: o, middlewareData: r } = t, s = ((y = r.arrow) == null ? void 0 : y.centerOffset) !== 0, l = s ? 0 : e.arrowWidth, c = s ? 0 : e.arrowHeight, [a, f] = Lt(n), u = { start: "0%", center: "50%", end: "100%" }[f], h = (((w = r.arrow) == null ? void 0 : w.x) ?? 0) + l / 2, d = (((v = r.arrow) == null ? void 0 : v.y) ?? 0) + c / 2;
    let m = "", g = "";
    return a === "bottom" ? (m = s ? u : `${h}px`, g = `${-c}px`) : a === "top" ? (m = s ? u : `${h}px`, g = `${o.floating.height + c}px`) : a === "right" ? (m = `${-c}px`, g = s ? u : `${d}px`) : a === "left" && (m = `${o.floating.width + c}px`, g = s ? u : `${d}px`), { data: { x: m, y: g } };
  }
});
function Lt(e) {
  const [t, n = "center"] = e.split("-");
  return [t, n];
}
var Ao = Pt, Ro = Rt, To = Tt, Oo = Dt, Do = "Portal", St = p.forwardRef((e, t) => {
  var l;
  const { container: n, ...o } = e, [r, i] = p.useState(!1);
  Pe(() => i(!0), []);
  const s = n || r && ((l = globalThis == null ? void 0 : globalThis.document) == null ? void 0 : l.body);
  return s ? sn.createPortal(/* @__PURE__ */ A(K.div, { ...o, ref: t }), s) : null;
});
St.displayName = Do;
var Lo = "VisuallyHidden", Mt = p.forwardRef(
  (e, t) => /* @__PURE__ */ A(
    K.span,
    {
      ...e,
      ref: t,
      style: {
        // See: https://github.com/twbs/bootstrap/blob/master/scss/mixins/_screen-reader.scss
        position: "absolute",
        border: 0,
        width: 1,
        height: 1,
        padding: 0,
        margin: -1,
        overflow: "hidden",
        clip: "rect(0, 0, 0, 0)",
        whiteSpace: "nowrap",
        wordWrap: "normal",
        ...e.style
      }
    }
  )
);
Mt.displayName = Lo;
var So = Mt, [xe, fr] = st("Tooltip", [
  Ct
]), be = Ct(), kt = "TooltipProvider", Mo = 700, Oe = "tooltip.open", [ko, Be] = xe(kt), _t = (e) => {
  const {
    __scopeTooltip: t,
    delayDuration: n = Mo,
    skipDelayDuration: o = 300,
    disableHoverableContent: r = !1,
    children: i
  } = e, [s, l] = p.useState(!0), c = p.useRef(!1), a = p.useRef(0);
  return p.useEffect(() => {
    const f = a.current;
    return () => window.clearTimeout(f);
  }, []), /* @__PURE__ */ A(
    ko,
    {
      scope: t,
      isOpenDelayed: s,
      delayDuration: n,
      onOpen: p.useCallback(() => {
        window.clearTimeout(a.current), l(!1);
      }, []),
      onClose: p.useCallback(() => {
        window.clearTimeout(a.current), a.current = window.setTimeout(
          () => l(!0),
          o
        );
      }, [o]),
      isPointerInTransitRef: c,
      onPointerInTransitChange: p.useCallback((f) => {
        c.current = f;
      }, []),
      disableHoverableContent: r,
      children: i
    }
  );
};
_t.displayName = kt;
var Ce = "Tooltip", [_o, le] = xe(Ce), Nt = (e) => {
  const {
    __scopeTooltip: t,
    children: n,
    open: o,
    defaultOpen: r = !1,
    onOpenChange: i,
    disableHoverableContent: s,
    delayDuration: l
  } = e, c = Be(Ce, e.__scopeTooltip), a = be(t), [f, u] = p.useState(null), h = on(), d = p.useRef(0), m = s ?? c.disableHoverableContent, g = l ?? c.delayDuration, y = p.useRef(!1), [w = !1, v] = en({
    prop: o,
    defaultProp: r,
    onChange: (E) => {
      E ? (c.onOpen(), document.dispatchEvent(new CustomEvent(Oe))) : c.onClose(), i == null || i(E);
    }
  }), C = p.useMemo(() => w ? y.current ? "delayed-open" : "instant-open" : "closed", [w]), x = p.useCallback(() => {
    window.clearTimeout(d.current), y.current = !1, v(!0);
  }, [v]), b = p.useCallback(() => {
    window.clearTimeout(d.current), v(!1);
  }, [v]), P = p.useCallback(() => {
    window.clearTimeout(d.current), d.current = window.setTimeout(() => {
      y.current = !0, v(!0);
    }, g);
  }, [g, v]);
  return p.useEffect(() => () => window.clearTimeout(d.current), []), /* @__PURE__ */ A(Ao, { ...a, children: /* @__PURE__ */ A(
    _o,
    {
      scope: t,
      contentId: h,
      open: w,
      stateAttribute: C,
      trigger: f,
      onTriggerChange: u,
      onTriggerEnter: p.useCallback(() => {
        c.isOpenDelayed ? P() : x();
      }, [c.isOpenDelayed, P, x]),
      onTriggerLeave: p.useCallback(() => {
        m ? b() : window.clearTimeout(d.current);
      }, [b, m]),
      onOpen: x,
      onClose: b,
      disableHoverableContent: m,
      children: n
    }
  ) });
};
Nt.displayName = Ce;
var De = "TooltipTrigger", Ht = p.forwardRef(
  (e, t) => {
    const { __scopeTooltip: n, ...o } = e, r = le(De, n), i = Be(De, n), s = be(n), l = p.useRef(null), c = te(t, l, r.onTriggerChange), a = p.useRef(!1), f = p.useRef(!1), u = p.useCallback(() => a.current = !1, []);
    return p.useEffect(() => () => document.removeEventListener("pointerup", u), [u]), /* @__PURE__ */ A(Ro, { asChild: !0, ...s, children: /* @__PURE__ */ A(
      K.button,
      {
        "aria-describedby": r.open ? r.contentId : void 0,
        "data-state": r.stateAttribute,
        ...o,
        ref: c,
        onPointerMove: Y(e.onPointerMove, (h) => {
          h.pointerType !== "touch" && !f.current && !i.isPointerInTransitRef.current && (r.onTriggerEnter(), f.current = !0);
        }),
        onPointerLeave: Y(e.onPointerLeave, () => {
          r.onTriggerLeave(), f.current = !1;
        }),
        onPointerDown: Y(e.onPointerDown, () => {
          a.current = !0, document.addEventListener("pointerup", u, { once: !0 });
        }),
        onFocus: Y(e.onFocus, () => {
          a.current || r.onOpen();
        }),
        onBlur: Y(e.onBlur, r.onClose),
        onClick: Y(e.onClick, r.onClose)
      }
    ) });
  }
);
Ht.displayName = De;
var Ie = "TooltipPortal", [No, Ho] = xe(Ie, {
  forceMount: void 0
}), Ft = (e) => {
  const { __scopeTooltip: t, forceMount: n, children: o, container: r } = e, i = le(Ie, t);
  return /* @__PURE__ */ A(No, { scope: t, forceMount: n, children: /* @__PURE__ */ A(ct, { present: n || i.open, children: /* @__PURE__ */ A(St, { asChild: !0, container: r, children: o }) }) });
};
Ft.displayName = Ie;
var ee = "TooltipContent", $t = p.forwardRef(
  (e, t) => {
    const n = Ho(ee, e.__scopeTooltip), { forceMount: o = n.forceMount, side: r = "top", ...i } = e, s = le(ee, e.__scopeTooltip);
    return /* @__PURE__ */ A(ct, { present: o || s.open, children: s.disableHoverableContent ? /* @__PURE__ */ A(Bt, { side: r, ...i, ref: t }) : /* @__PURE__ */ A(Fo, { side: r, ...i, ref: t }) });
  }
), Fo = p.forwardRef((e, t) => {
  const n = le(ee, e.__scopeTooltip), o = Be(ee, e.__scopeTooltip), r = p.useRef(null), i = te(t, r), [s, l] = p.useState(null), { trigger: c, onClose: a } = n, f = r.current, { onPointerInTransitChange: u } = o, h = p.useCallback(() => {
    l(null), u(!1);
  }, [u]), d = p.useCallback(
    (m, g) => {
      const y = m.currentTarget, w = { x: m.clientX, y: m.clientY }, v = Wo(w, y.getBoundingClientRect()), C = Vo(w, v), x = zo(g.getBoundingClientRect()), b = jo([...C, ...x]);
      l(b), u(!0);
    },
    [u]
  );
  return p.useEffect(() => () => h(), [h]), p.useEffect(() => {
    if (c && f) {
      const m = (y) => d(y, f), g = (y) => d(y, c);
      return c.addEventListener("pointerleave", m), f.addEventListener("pointerleave", g), () => {
        c.removeEventListener("pointerleave", m), f.removeEventListener("pointerleave", g);
      };
    }
  }, [c, f, d, h]), p.useEffect(() => {
    if (s) {
      const m = (g) => {
        const y = g.target, w = { x: g.clientX, y: g.clientY }, v = (c == null ? void 0 : c.contains(y)) || (f == null ? void 0 : f.contains(y)), C = !Yo(w, s);
        v ? h() : C && (h(), a());
      };
      return document.addEventListener("pointermove", m), () => document.removeEventListener("pointermove", m);
    }
  }, [c, f, s, a, h]), /* @__PURE__ */ A(Bt, { ...e, ref: i });
}), [$o, Bo] = xe(Ce, { isInside: !1 }), Bt = p.forwardRef(
  (e, t) => {
    const {
      __scopeTooltip: n,
      children: o,
      "aria-label": r,
      onEscapeKeyDown: i,
      onPointerDownOutside: s,
      ...l
    } = e, c = le(ee, n), a = be(n), { onClose: f } = c;
    return p.useEffect(() => (document.addEventListener(Oe, f), () => document.removeEventListener(Oe, f)), [f]), p.useEffect(() => {
      if (c.trigger) {
        const u = (h) => {
          const d = h.target;
          d != null && d.contains(c.trigger) && f();
        };
        return window.addEventListener("scroll", u, { capture: !0 }), () => window.removeEventListener("scroll", u, { capture: !0 });
      }
    }, [c.trigger, f]), /* @__PURE__ */ A(
      lt,
      {
        asChild: !0,
        disableOutsidePointerEvents: !1,
        onEscapeKeyDown: i,
        onPointerDownOutside: s,
        onFocusOutside: (u) => u.preventDefault(),
        onDismiss: f,
        children: /* @__PURE__ */ it(
          To,
          {
            "data-state": c.stateAttribute,
            ...a,
            ...l,
            ref: t,
            style: {
              ...l.style,
              "--radix-tooltip-content-transform-origin": "var(--radix-popper-transform-origin)",
              "--radix-tooltip-content-available-width": "var(--radix-popper-available-width)",
              "--radix-tooltip-content-available-height": "var(--radix-popper-available-height)",
              "--radix-tooltip-trigger-width": "var(--radix-popper-anchor-width)",
              "--radix-tooltip-trigger-height": "var(--radix-popper-anchor-height)"
            },
            children: [
              /* @__PURE__ */ A(tn, { children: o }),
              /* @__PURE__ */ A($o, { scope: n, isInside: !0, children: /* @__PURE__ */ A(So, { id: c.contentId, role: "tooltip", children: r || o }) })
            ]
          }
        )
      }
    );
  }
);
$t.displayName = ee;
var It = "TooltipArrow", Io = p.forwardRef(
  (e, t) => {
    const { __scopeTooltip: n, ...o } = e, r = be(n);
    return Bo(
      It,
      n
    ).isInside ? null : /* @__PURE__ */ A(Oo, { ...r, ...o, ref: t });
  }
);
Io.displayName = It;
function Wo(e, t) {
  const n = Math.abs(t.top - e.y), o = Math.abs(t.bottom - e.y), r = Math.abs(t.right - e.x), i = Math.abs(t.left - e.x);
  switch (Math.min(n, o, r, i)) {
    case i:
      return "left";
    case r:
      return "right";
    case n:
      return "top";
    case o:
      return "bottom";
    default:
      throw new Error("unreachable");
  }
}
function Vo(e, t, n = 5) {
  const o = [];
  switch (t) {
    case "top":
      o.push(
        { x: e.x - n, y: e.y + n },
        { x: e.x + n, y: e.y + n }
      );
      break;
    case "bottom":
      o.push(
        { x: e.x - n, y: e.y - n },
        { x: e.x + n, y: e.y - n }
      );
      break;
    case "left":
      o.push(
        { x: e.x + n, y: e.y - n },
        { x: e.x + n, y: e.y + n }
      );
      break;
    case "right":
      o.push(
        { x: e.x - n, y: e.y - n },
        { x: e.x - n, y: e.y + n }
      );
      break;
  }
  return o;
}
function zo(e) {
  const { top: t, right: n, bottom: o, left: r } = e;
  return [
    { x: r, y: t },
    { x: n, y: t },
    { x: n, y: o },
    { x: r, y: o }
  ];
}
function Yo(e, t) {
  const { x: n, y: o } = e;
  let r = !1;
  for (let i = 0, s = t.length - 1; i < t.length; s = i++) {
    const l = t[i].x, c = t[i].y, a = t[s].x, f = t[s].y;
    c > o != f > o && n < (a - l) * (o - c) / (f - c) + l && (r = !r);
  }
  return r;
}
function jo(e) {
  const t = e.slice();
  return t.sort((n, o) => n.x < o.x ? -1 : n.x > o.x ? 1 : n.y < o.y ? -1 : n.y > o.y ? 1 : 0), Xo(t);
}
function Xo(e) {
  if (e.length <= 1) return e.slice();
  const t = [];
  for (let o = 0; o < e.length; o++) {
    const r = e[o];
    for (; t.length >= 2; ) {
      const i = t[t.length - 1], s = t[t.length - 2];
      if ((i.x - s.x) * (r.y - s.y) >= (i.y - s.y) * (r.x - s.x)) t.pop();
      else break;
    }
    t.push(r);
  }
  t.pop();
  const n = [];
  for (let o = e.length - 1; o >= 0; o--) {
    const r = e[o];
    for (; n.length >= 2; ) {
      const i = n[n.length - 1], s = n[n.length - 2];
      if ((i.x - s.x) * (r.y - s.y) >= (i.y - s.y) * (r.x - s.x)) n.pop();
      else break;
    }
    n.push(r);
  }
  return n.pop(), t.length === 1 && n.length === 1 && t[0].x === n[0].x && t[0].y === n[0].y ? t : t.concat(n);
}
var Uo = _t, Go = Nt, qo = Ht, Ko = Ft, Zo = $t;
const Jo = Qt(
  ({
    children: e,
    className: t,
    content: n,
    defaultOpen: o,
    open: r,
    onClick: i,
    onOpenChange: s,
    side: l,
    asChild: c = !1,
    align: a = "center",
    ...f
  }, u) => /* @__PURE__ */ A(Uo, { delayDuration: 150, children: /* @__PURE__ */ it(
    Go,
    {
      open: r,
      defaultOpen: o,
      onOpenChange: s,
      children: [
        /* @__PURE__ */ A(
          qo,
          {
            className: ln,
            onClick: i,
            asChild: c,
            children: e
          }
        ),
        /* @__PURE__ */ A(Ko, { children: /* @__PURE__ */ A(
          Zo,
          {
            ref: u,
            side: l,
            sideOffset: 4,
            align: a,
            className: an(
              // base
              "min-w-s-6 max-w-[calc(var(--fui-baseline)*8+var(--fui-subline)*2)] rounded-xs bg-el-bg-tooltip px-sp-2 py-sp-1 text-center text-t3 text-text-primary-inverted",
              // transition
              "data-[side=top]:animate-slide-up-and-fade data-[state=closed]:animate-hide",
              t
            ),
            ...f,
            children: n
          }
        ) })
      ]
    }
  ) })
);
Jo.displayName = "Tooltip";
export {
  Jo as Tooltip
};
