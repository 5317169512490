/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null | undefined;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  JSON: { input: any; output: any; }
};

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  county?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
};

export type AddressInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
};

export enum AdministratorSubSteps {
  Admins = 'Admins',
  Cover = 'Cover',
  PersonalStatement = 'PersonalStatement',
  Summary = 'Summary'
}

export enum App {
  Lp = 'LP'
}

export type AppointmentPreferences = {
  courtAppearance?: InputMaybe<Scalars['Boolean']['input']>;
  criminalHistory?: InputMaybe<Scalars['Boolean']['input']>;
  duration?: InputMaybe<Scalars['Float']['input']>;
  ethnicities?: InputMaybe<Array<Scalars['String']['input']>>;
  excludedProviders?: InputMaybe<Array<Scalars['String']['input']>>;
  gender?: InputMaybe<DtoGender>;
  languages?: InputMaybe<Array<Scalars['String']['input']>>;
  preferredDateRange?: InputMaybe<DateRange>;
  preferredProviders?: InputMaybe<Array<Scalars['String']['input']>>;
  religions?: InputMaybe<Array<Scalars['String']['input']>>;
  urgentCases?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Attendee = {
  __typename?: 'Attendee';
  attendeeType: AttendeeType;
  id: Scalars['String']['output'];
};

export enum AttendeeType {
  Attorney = 'ATTORNEY',
  Client = 'CLIENT'
}

export type Attorney = {
  __typename?: 'Attorney';
  attorneyId: Scalars['String']['output'];
  attorneyReviewData?: Maybe<AttorneyReviewData>;
};

export type AttorneyData = {
  __typename?: 'AttorneyData';
  clientEmailResponsiveness?: Maybe<ClientEmailResponseTime>;
  education?: Maybe<Array<Education>>;
  gender?: Maybe<Gender>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  languages?: Maybe<Array<Language>>;
  practiceAreas?: Maybe<Array<AttorneyPracticeArea>>;
  practiceAreasIds?: Maybe<Array<Scalars['String']['output']>>;
  preferredCommunicationMethods?: Maybe<Array<CommunicationMethod>>;
};

export type AttorneyPracticeArea = {
  __typename?: 'AttorneyPracticeArea';
  handlesCases: Scalars['Boolean']['output'];
  locations: Array<PracticeAreaLocation>;
  performsLSS: Scalars['Boolean']['output'];
  practiceArea: PracticeArea;
  practiceAreaId: Scalars['String']['output'];
  serviceExpertises: Array<ServiceExpertise>;
  servicePreferences: Array<ServicePreference>;
};

export type AttorneyReview = {
  __typename?: 'AttorneyReview';
  attorneyId: Scalars['String']['output'];
  comment?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  reviewDate: Scalars['Date']['output'];
  score: Scalars['Float']['output'];
};

export type AttorneyReviewData = {
  __typename?: 'AttorneyReviewData';
  averageScore: Scalars['Float']['output'];
  reviews: Array<AttorneyReview>;
};


export type AttorneyReviewDataReviewsArgs = {
  sort?: InputMaybe<SingleFieldSort>;
};

export type AttorneyReviewsFilter = {
  attorneyId: Scalars['String']['input'];
  hasDetailedComment?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  scores?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export enum AuthError {
  UnknownError = 'UNKNOWN_ERROR'
}

export type AvailableSlot = {
  __typename?: 'AvailableSlot';
  end: Scalars['String']['output'];
  start: Scalars['String']['output'];
};

export type BackupCharity = {
  __typename?: 'BackupCharity';
  address?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  percent: Scalars['Float']['output'];
};

export type BackupCharityInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  percent: Scalars['Float']['input'];
};

export type BackupPerson = {
  __typename?: 'BackupPerson';
  name: Scalars['String']['output'];
  percent: Scalars['Float']['output'];
  relation: Relation;
};

export type BackupPersonInput = {
  name: Scalars['String']['input'];
  percent: Scalars['Float']['input'];
  relation: Relation;
};

export type Backups = {
  __typename?: 'Backups';
  backupCharity?: Maybe<Array<BackupCharity>>;
  backupPerson?: Maybe<Array<BackupPerson>>;
  otherBackup?: Maybe<OtherBackup>;
};

export type BackupsInput = {
  backupCharity?: InputMaybe<Array<BackupCharityInput>>;
  backupPerson?: InputMaybe<Array<BackupPersonInput>>;
  otherBackup?: InputMaybe<OtherBackupInput>;
};

export type BankOptionsResponse = {
  __typename?: 'BankOptionsResponse';
  persons?: Maybe<Array<Person>>;
};

export enum BasicInfoSubSteps {
  Address = 'Address',
  Cover = 'Cover',
  PersonalData = 'PersonalData',
  Summary = 'Summary'
}

export type BeneficiaryCharity = {
  __typename?: 'BeneficiaryCharity';
  address?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type BeneficiaryCharityInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type BeneficiaryPerson = {
  __typename?: 'BeneficiaryPerson';
  name: Scalars['String']['output'];
  relation: Relation;
};

export type BeneficiaryPersonInput = {
  name: Scalars['String']['input'];
  relation: Relation;
};

export type BookItMeeting = {
  __typename?: 'BookItMeeting';
  clientId?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  endDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['String']['output'];
  meetingId?: Maybe<Scalars['String']['output']>;
  meetingType?: Maybe<CalendarMeetingType>;
  notes?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['Date']['output']>;
  status: BookItMeetingStatus;
  timezone?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum BookItMeetingStatus {
  Cancelled = 'CANCELLED',
  Expired = 'EXPIRED',
  Fulfilled = 'FULFILLED',
  Pending = 'PENDING',
  Scheduled = 'SCHEDULED'
}

export enum BookeeMeetingType {
  EscalatedLss = 'ESCALATED_LSS',
  Lss = 'LSS',
  OneOnOne = 'ONE_ON_ONE'
}

export type BookitRequestResponse = {
  __typename?: 'BookitRequestResponse';
  id: Scalars['String']['output'];
  timezone: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export enum Burial {
  Cremation = 'CREMATION',
  FamilyDecides = 'FAMILY_DECIDES',
  Ground = 'GROUND',
  Other = 'OTHER'
}

export enum CalendarMeetingType {
  CourtPrep = 'COURT_PREP',
  NewClientCall = 'NEW_CLIENT_CALL',
  Other = 'OTHER',
  Update = 'UPDATE'
}

export type CalendlyCanceledEventResponse = {
  __typename?: 'CalendlyCanceledEventResponse';
  errorMessage?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export enum CalendlyEventName {
  LssEvent = 'LSS_EVENT',
  StrategyReviewCall = 'STRATEGY_REVIEW_CALL'
}

export type CalendlyLinksResponse = {
  __typename?: 'CalendlyLinksResponse';
  cancelUrl: Scalars['String']['output'];
  isRescheduleDatesAvailable: Scalars['Boolean']['output'];
  rescheduleUrl: Scalars['String']['output'];
};

export type CancelCalendlyEventRequest = {
  calendlyEventId: Scalars['String']['input'];
  eventId: Scalars['String']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
};

export type Case = {
  __typename?: 'Case';
  additionalFields?: Maybe<Scalars['JSON']['output']>;
  caseStartDate?: Maybe<Scalars['Date']['output']>;
  caseUpdate?: Maybe<GetCaseUpdateResponse>;
  createdAt: Scalars['Date']['output'];
  events?: Maybe<Array<CaseEvent>>;
  forms?: Maybe<Array<CaseForm>>;
  id: Scalars['String']['output'];
  legalTeam?: Maybe<CaseLegalTeam>;
  opposingParty?: Maybe<Scalars['String']['output']>;
  practiceArea: PracticeArea;
  practiceAreaId: Scalars['String']['output'];
  preClientEvents?: Maybe<Array<GetPreClientEventsResponse>>;
  repeats?: Maybe<Array<Repeat>>;
  services?: Maybe<Array<Service>>;
  status: CaseStatus;
  strategyReviewCallComplete?: Maybe<Scalars['Boolean']['output']>;
  strategyReviewCallCompleteDate?: Maybe<Scalars['Date']['output']>;
  userId: Scalars['String']['output'];
};

export type CaseEvent = {
  __typename?: 'CaseEvent';
  description?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['String']['output']>;
  eventType: Scalars['String']['output'];
  id: Scalars['String']['output'];
  location?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  parentId: Scalars['String']['output'];
  startDate: Scalars['String']['output'];
  timezone?: Maybe<Scalars['String']['output']>;
  users: Array<Scalars['String']['output']>;
};

export type CaseForm = {
  __typename?: 'CaseForm';
  name: FormName;
  saved?: Maybe<Scalars['Boolean']['output']>;
  submitted?: Maybe<Scalars['Boolean']['output']>;
  submittedAt?: Maybe<Scalars['Date']['output']>;
  uuid: Scalars['String']['output'];
};

export type CaseLegalTeam = {
  __typename?: 'CaseLegalTeam';
  caseManager?: Maybe<User>;
  paralegal?: Maybe<User>;
  responsibleAttorney?: Maybe<User>;
};

export enum CaseStatus {
  Accepted = 'ACCEPTED',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export enum CasesError {
  CaseNotFound = 'CASE_NOT_FOUND'
}

export type CasesResolverResponse = {
  __typename?: 'CasesResolverResponse';
  error?: Maybe<CasesError>;
  success: Scalars['Boolean']['output'];
};

export type CasesStatuses = {
  __typename?: 'CasesStatuses';
  accepted: Scalars['Boolean']['output'];
  pending: Scalars['Boolean']['output'];
  rejected: Scalars['Boolean']['output'];
};

export type CashGift = {
  __typename?: 'CashGift';
  amount: Scalars['Float']['output'];
  beneficiaryCharity?: Maybe<BeneficiaryCharity>;
  beneficiaryPerson?: Maybe<BeneficiaryPerson>;
};

export type CashGiftInput = {
  amount: Scalars['Float']['input'];
  beneficiaryCharity?: InputMaybe<BeneficiaryCharityInput>;
  beneficiaryPerson?: InputMaybe<BeneficiaryPersonInput>;
};

export enum Ceremony {
  FamilyDecides = 'FAMILY_DECIDES',
  Funeral = 'FUNERAL',
  FuneralAndMemorial = 'FUNERAL_AND_MEMORIAL',
  Memorial = 'MEMORIAL',
  NoCeremony = 'NO_CEREMONY',
  Other = 'OTHER'
}

export type Charity = {
  __typename?: 'Charity';
  address?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type CharityInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export enum ClientEmailResponseTime {
  FortyEightToSeventyTwoHours = 'FORTY_EIGHT_TO_SEVENTY_TWO_HOURS',
  SameBusinessDay = 'SAME_BUSINESS_DAY',
  SameDay = 'SAME_DAY',
  TwentyFourToFortyEightHours = 'TWENTY_FOUR_TO_FORTY_EIGHT_HOURS'
}

export enum CommunicationMethod {
  Email = 'EMAIL',
  PhoneCall = 'PHONE_CALL',
  TextMessage = 'TEXT_MESSAGE',
  VideoConference = 'VIDEO_CONFERENCE'
}

export type CreateBookitRequestPayload = {
  appointmentPreferences?: InputMaybe<AppointmentPreferences>;
  competencies: Array<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  customerEmail: Scalars['String']['input'];
  customerFirstName?: InputMaybe<Scalars['String']['input']>;
  customerFlareId?: InputMaybe<Scalars['String']['input']>;
  customerLastName?: InputMaybe<Scalars['String']['input']>;
  fips?: InputMaybe<Scalars['String']['input']>;
  meetingType: BookeeMeetingType;
  metadata?: InputMaybe<Scalars['JSON']['input']>;
  practiceArea: PracticeAreaName;
  stateCode?: InputMaybe<Scalars['String']['input']>;
};

export type CreateVisitorInput = {
  address?: InputMaybe<AddressInput>;
  anonymousId: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  funnelAnswers?: InputMaybe<Scalars['JSON']['input']>;
  leadScore?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<PartialNameInput>;
  phone?: InputMaybe<Scalars['String']['input']>;
  utmData?: InputMaybe<Scalars['JSON']['input']>;
};

export type CreateWillFormStepsRequest = {
  administrator: WillAdministratorInfoInput;
  basicInformation: WillBasicInfoInput;
  family: WillFamilyInfoInput;
  funeralWishes: WillStepInfoInput;
  gifts: WillStepInfoInput;
  residuary: WillResiduaryInfoInput;
  review: WillReviewInfoInput;
};

export type CreateWillRequest = {
  administrators?: InputMaybe<Array<PersonInput>>;
  burial?: InputMaybe<Burial>;
  burialAdditionalInfo?: InputMaybe<Scalars['String']['input']>;
  ceremony?: InputMaybe<Ceremony>;
  ceremonyAdditionalInfo?: InputMaybe<Scalars['String']['input']>;
  childrenGuardians?: InputMaybe<Array<PersonInput>>;
  gifts?: InputMaybe<GiftsInput>;
  hasChildrenInheritance?: InputMaybe<Scalars['Boolean']['input']>;
  hasChildrenUnder18?: InputMaybe<Scalars['Boolean']['input']>;
  hasPartnerInheritance?: InputMaybe<Scalars['Boolean']['input']>;
  personalData: WillPersonalDataInput;
  pets?: InputMaybe<Array<PetInput>>;
  petsFunds?: InputMaybe<Scalars['Float']['input']>;
  petsGuardians?: InputMaybe<Array<PersonInput>>;
  residuaryPlan?: InputMaybe<ResiduaryPlanInput>;
  shouldAddResiduaryPlanBackups?: InputMaybe<Scalars['Boolean']['input']>;
  statement?: InputMaybe<Scalars['String']['input']>;
  wishesAdministrators?: InputMaybe<Array<PersonInput>>;
};

export type CustomerData = {
  __typename?: 'CustomerData';
  consentForCreditCheck?: Maybe<Scalars['Boolean']['output']>;
  customerState?: Maybe<CustomerState>;
  dateOfBirth?: Maybe<Scalars['Date']['output']>;
};

export type CustomerState = {
  __typename?: 'CustomerState';
  blockAllCommunications?: Maybe<Scalars['Boolean']['output']>;
  emailConfirmed?: Maybe<Scalars['Boolean']['output']>;
  firstEmailSent?: Maybe<Scalars['Boolean']['output']>;
  freezeData?: Maybe<FreezeData>;
  postBookingShown?: Maybe<Scalars['Boolean']['output']>;
  repeatFlowType?: Maybe<RepeatFlowType>;
  submittedForms?: Maybe<Scalars['Boolean']['output']>;
  visitedMyMarbleWeb?: Maybe<Scalars['Boolean']['output']>;
  visitedOnboardingWizard?: Maybe<Scalars['Boolean']['output']>;
};

export type CustomersList = {
  __typename?: 'CustomersList';
  count: Scalars['Int']['output'];
  customers: Array<User>;
  pagination: Pagination;
};

export type CustomersListFilter = {
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type DataPoints = {
  __typename?: 'DataPoints';
  dataPoints: Scalars['JSON']['output'];
};

export enum DataPointsSubmissionError {
  DataPointsSubmissionFailed = 'DATA_POINTS_SUBMISSION_FAILED'
}

export type DataPointsSubmissionInput = {
  dataPoints: Scalars['JSON']['input'];
  isFinalSubmission: Scalars['Boolean']['input'];
};

export type DateRange = {
  from: Scalars['String']['input'];
  to: Scalars['String']['input'];
};

export type DigitalAsset = {
  __typename?: 'DigitalAsset';
  beneficiaryCharity?: Maybe<BeneficiaryCharity>;
  beneficiaryPerson?: Maybe<BeneficiaryPerson>;
  description: Scalars['String']['output'];
};

export type DigitalAssetInput = {
  beneficiaryCharity?: InputMaybe<BeneficiaryCharityInput>;
  beneficiaryPerson?: InputMaybe<BeneficiaryPersonInput>;
  description: Scalars['String']['input'];
};

export type Document = {
  __typename?: 'Document';
  caseId: Scalars['String']['output'];
  customerMarbleId: Scalars['String']['output'];
  customerVisibility: Scalars['Boolean']['output'];
  documentType: DocumentType;
  documentTypeId: Scalars['String']['output'];
  fileName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  s3UploadDate?: Maybe<Scalars['Date']['output']>;
  sourceApp: SourceApps;
};

export type DocumentDataPoint = {
  documentIds: Array<Scalars['String']['input']>;
  documentTypeId: Scalars['String']['input'];
};

export type DocumentType = {
  __typename?: 'DocumentType';
  category: Scalars['String']['output'];
  description: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  howToObtain: Scalars['String']['output'];
  id: Scalars['String']['output'];
  importanceDescription: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type DocumentUploadRequest = {
  caseId: Scalars['String']['input'];
  customerVisibility: Scalars['Boolean']['input'];
  documentTypeId: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
  sourceApp: SourceApps;
  uploaderMarbleId: Scalars['String']['input'];
};

export type DocumentUploadResponse = {
  __typename?: 'DocumentUploadResponse';
  document: Document;
  documentId: Scalars['String']['output'];
  uploadUrl: Scalars['String']['output'];
};

export type DocumentsDataPointsSubmissionInput = {
  attorneyId: Scalars['String']['input'];
  documentsDataPoints: Array<DocumentDataPoint>;
};

export type DraftSchema = {
  __typename?: 'DraftSchema';
  jsonSchema: Scalars['JSON']['output'];
};

export enum DtoGender {
  Female = 'Female',
  Male = 'Male',
  Na = 'Na',
  Other = 'Other'
}

export type Education = {
  __typename?: 'Education';
  graduationYear?: Maybe<Scalars['Float']['output']>;
  schoolName: Scalars['String']['output'];
};

export type Event = {
  __typename?: 'Event';
  attendees: Array<Attendee>;
  bookeeEventId?: Maybe<Scalars['String']['output']>;
  bookeeMeetingId?: Maybe<Scalars['String']['output']>;
  calendlyEventId?: Maybe<Scalars['String']['output']>;
  caseId: Scalars['String']['output'];
  endDate: Scalars['Date']['output'];
  eventType: EventType;
  id: Scalars['String']['output'];
  isCancelled: Scalars['Boolean']['output'];
  isCompleted: Scalars['Boolean']['output'];
  startDate: Scalars['Date']['output'];
  syncInfo?: Maybe<SyncInfo>;
};

export enum EventType {
  CalendarEvent = 'CALENDAR_EVENT',
  Event = 'EVENT',
  LssEvent = 'LSS_EVENT'
}

export enum FamilySubSteps {
  Cover = 'Cover',
  Kids = 'Kids',
  KidsGuardian = 'KidsGuardian',
  MaritalStatus = 'MaritalStatus',
  Pets = 'Pets',
  Summary = 'Summary'
}

export enum FeedbackType {
  Negative = 'NEGATIVE',
  Positive = 'POSITIVE'
}

export enum FormName {
  ClientQuestionnaire = 'CLIENT_QUESTIONNAIRE',
  RepeatRequest = 'REPEAT_REQUEST'
}

export type FreezeData = {
  __typename?: 'FreezeData';
  frozenAt?: Maybe<Scalars['Date']['output']>;
  initiatedBy?: Maybe<Scalars['String']['output']>;
  isFrozen?: Maybe<Scalars['Boolean']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
};

export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE',
  Other = 'OTHER'
}

export type GenerateWillReponse = {
  __typename?: 'GenerateWillReponse';
  downloadUrl: Scalars['String']['output'];
};

export type GetAvailableSlotsRequest = {
  competencies: Array<Scalars['String']['input']>;
  fips?: InputMaybe<Scalars['String']['input']>;
  from?: InputMaybe<Scalars['Date']['input']>;
  meetingType: BookeeMeetingType;
  minSlots?: InputMaybe<Scalars['Int']['input']>;
  practiceArea: PracticeAreaName;
  stateCode?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['Date']['input']>;
};

export type GetBookItMeetingSlotsInput = {
  from?: InputMaybe<Scalars['Date']['input']>;
  requestId: Scalars['String']['input'];
  to?: InputMaybe<Scalars['Date']['input']>;
};

export type GetBookItMeetingsInput = {
  clientEmail?: InputMaybe<Scalars['String']['input']>;
  meetingTypeId?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  statuses?: InputMaybe<Array<BookItMeetingStatus>>;
};

export type GetBookItMeetingsResponse = {
  __typename?: 'GetBookItMeetingsResponse';
  meetings: Array<BookItMeeting>;
  page?: Maybe<Scalars['Int']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
};

export type GetCaseUpdateResponse = {
  __typename?: 'GetCaseUpdateResponse';
  caseUpdate?: Maybe<Scalars['String']['output']>;
  caseUpdateId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  customerMarbleId?: Maybe<Scalars['String']['output']>;
  feedbackCreatedAt?: Maybe<Scalars['String']['output']>;
  feedbackType?: Maybe<FeedbackType>;
  negativeFeedbackFreeText?: Maybe<Scalars['String']['output']>;
  negativeFeedbackReason?: Maybe<Scalars['String']['output']>;
};

export type GetPreClientEventsResponse = Event | LssEvent;

export type GetUserCasesInput = {
  statuses?: InputMaybe<Array<CaseStatus>>;
};

export type Gifts = {
  __typename?: 'Gifts';
  cashGifts?: Maybe<Array<CashGift>>;
  digitalAssets?: Maybe<Array<DigitalAsset>>;
  properties?: Maybe<Array<Property>>;
  specificItems?: Maybe<Array<SpecificItem>>;
  vehicles?: Maybe<Array<Vehicle>>;
};

export type GiftsInput = {
  cashGifts?: InputMaybe<Array<CashGiftInput>>;
  digitalAssets?: InputMaybe<Array<DigitalAssetInput>>;
  properties?: InputMaybe<Array<PropertyInput>>;
  specificItems?: InputMaybe<Array<SpecificItemInput>>;
  vehicles?: InputMaybe<Array<VehicleInput>>;
};

export enum HashAlgorithm {
  Md5 = 'MD5',
  Sha256 = 'SHA256',
  Sha512 = 'SHA512'
}

export enum HashError {
  UnknownError = 'UNKNOWN_ERROR'
}

export type HashOptions = {
  algorithm?: InputMaybe<HashAlgorithm>;
  useSalt?: InputMaybe<Scalars['Boolean']['input']>;
};

export type HashRequest = {
  hashOptions?: InputMaybe<HashOptions>;
  input: Scalars['JSON']['input'];
};

export type HashResponse = {
  __typename?: 'HashResponse';
  error?: Maybe<HashError>;
  hash?: Maybe<Scalars['JSON']['output']>;
  success: Scalars['Boolean']['output'];
};

export enum Language {
  Abkhazian = 'ABKHAZIAN',
  Afar = 'AFAR',
  Afrikaans = 'AFRIKAANS',
  Akan = 'AKAN',
  Albanian = 'ALBANIAN',
  Amharic = 'AMHARIC',
  Arabic = 'ARABIC',
  Aragonese = 'ARAGONESE',
  Armenian = 'ARMENIAN',
  Assamese = 'ASSAMESE',
  Avaric = 'AVARIC',
  Avestan = 'AVESTAN',
  Aymara = 'AYMARA',
  Azerbaijani = 'AZERBAIJANI',
  Bambara = 'BAMBARA',
  Bashkir = 'BASHKIR',
  Basque = 'BASQUE',
  Belarusian = 'BELARUSIAN',
  Bengali = 'BENGALI',
  Bislama = 'BISLAMA',
  Bosnian = 'BOSNIAN',
  Breton = 'BRETON',
  Bulgarian = 'BULGARIAN',
  Burmese = 'BURMESE',
  Catalan = 'CATALAN',
  CentralKhmer = 'CENTRAL_KHMER',
  Chamorro = 'CHAMORRO',
  Chechen = 'CHECHEN',
  Chichewa = 'CHICHEWA',
  Chinese = 'CHINESE',
  ChurchSlavonic = 'CHURCH_SLAVONIC',
  Chuvash = 'CHUVASH',
  Cornish = 'CORNISH',
  Corsican = 'CORSICAN',
  Cree = 'CREE',
  Croatian = 'CROATIAN',
  Czech = 'CZECH',
  Danish = 'DANISH',
  Divehi = 'DIVEHI',
  Dutch = 'DUTCH',
  Dzongkha = 'DZONGKHA',
  English = 'ENGLISH',
  Esperanto = 'ESPERANTO',
  Estonian = 'ESTONIAN',
  Ewe = 'EWE',
  Faroese = 'FAROESE',
  Fijian = 'FIJIAN',
  Finnish = 'FINNISH',
  French = 'FRENCH',
  Fulah = 'FULAH',
  Gaelic = 'GAELIC',
  Galician = 'GALICIAN',
  Ganda = 'GANDA',
  Georgian = 'GEORGIAN',
  German = 'GERMAN',
  Greek = 'GREEK',
  Guarani = 'GUARANI',
  Gujarati = 'GUJARATI',
  Haitian = 'HAITIAN',
  Hausa = 'HAUSA',
  Hebrew = 'HEBREW',
  Herero = 'HERERO',
  Hindi = 'HINDI',
  HiriMotu = 'HIRI_MOTU',
  Hungarian = 'HUNGARIAN',
  Icelandic = 'ICELANDIC',
  Ido = 'IDO',
  Igbo = 'IGBO',
  Indonesian = 'INDONESIAN',
  Interlingua = 'INTERLINGUA',
  Interlingue = 'INTERLINGUE',
  Inuktitut = 'INUKTITUT',
  Inupiaq = 'INUPIAQ',
  Irish = 'IRISH',
  Italian = 'ITALIAN',
  Japanese = 'JAPANESE',
  Javanese = 'JAVANESE',
  Kalaallisut = 'KALAALLISUT',
  Kannada = 'KANNADA',
  Kanuri = 'KANURI',
  Kashmiri = 'KASHMIRI',
  Kazakh = 'KAZAKH',
  Kikuyu = 'KIKUYU',
  Kinyarwanda = 'KINYARWANDA',
  Kirghiz = 'KIRGHIZ',
  Komi = 'KOMI',
  Kongo = 'KONGO',
  Korean = 'KOREAN',
  Kuanyama = 'KUANYAMA',
  Kurdish = 'KURDISH',
  Lao = 'LAO',
  Latin = 'LATIN',
  Latvian = 'LATVIAN',
  Limburgan = 'LIMBURGAN',
  Lingala = 'LINGALA',
  Lithuanian = 'LITHUANIAN',
  LubaKatanga = 'LUBA_KATANGA',
  Luxembourgish = 'LUXEMBOURGISH',
  Macedonian = 'MACEDONIAN',
  Malagasy = 'MALAGASY',
  Malay = 'MALAY',
  Malayalam = 'MALAYALAM',
  Maltese = 'MALTESE',
  Manx = 'MANX',
  Maori = 'MAORI',
  Marathi = 'MARATHI',
  Marshallese = 'MARSHALLESE',
  Mongolian = 'MONGOLIAN',
  Nauru = 'NAURU',
  Navajo = 'NAVAJO',
  Ndonga = 'NDONGA',
  Nepali = 'NEPALI',
  NorthernSami = 'NORTHERN_SAMI',
  NorthNdebele = 'NORTH_NDEBELE',
  Norwegian = 'NORWEGIAN',
  NorwegianBokmal = 'NORWEGIAN_BOKMAL',
  NorwegianNynorsk = 'NORWEGIAN_NYNORSK',
  Occitan = 'OCCITAN',
  Ojibwa = 'OJIBWA',
  Oriya = 'ORIYA',
  Oromo = 'OROMO',
  Ossetian = 'OSSETIAN',
  Pali = 'PALI',
  Pashto = 'PASHTO',
  Persian = 'PERSIAN',
  Polish = 'POLISH',
  Portuguese = 'PORTUGUESE',
  Punjabi = 'PUNJABI',
  Quechua = 'QUECHUA',
  Romanian = 'ROMANIAN',
  Romansh = 'ROMANSH',
  Rundi = 'RUNDI',
  Russian = 'RUSSIAN',
  Samoan = 'SAMOAN',
  Sango = 'SANGO',
  Sanskrit = 'SANSKRIT',
  Sardinian = 'SARDINIAN',
  Serbian = 'SERBIAN',
  Shona = 'SHONA',
  SichuanYi = 'SICHUAN_YI',
  Sindhi = 'SINDHI',
  Sinhala = 'SINHALA',
  Slovak = 'SLOVAK',
  Slovenian = 'SLOVENIAN',
  Somali = 'SOMALI',
  SouthernSotho = 'SOUTHERN_SOTHO',
  SouthNdebele = 'SOUTH_NDEBELE',
  Spanish = 'SPANISH',
  Sundanese = 'SUNDANESE',
  Swahili = 'SWAHILI',
  Swati = 'SWATI',
  Swedish = 'SWEDISH',
  Tagalog = 'TAGALOG',
  Tahitian = 'TAHITIAN',
  Tajik = 'TAJIK',
  Tamil = 'TAMIL',
  Tatar = 'TATAR',
  Telugu = 'TELUGU',
  Thai = 'THAI',
  Tibetan = 'TIBETAN',
  Tigrinya = 'TIGRINYA',
  Tonga = 'TONGA',
  Tsonga = 'TSONGA',
  Tswana = 'TSWANA',
  Turkish = 'TURKISH',
  Turkmen = 'TURKMEN',
  Twi = 'TWI',
  Uighur = 'UIGHUR',
  Ukrainian = 'UKRAINIAN',
  Urdu = 'URDU',
  Uzbek = 'UZBEK',
  Venda = 'VENDA',
  Vietnamese = 'VIETNAMESE',
  Volapuk = 'VOLAPUK',
  Walloon = 'WALLOON',
  Welsh = 'WELSH',
  WesternFrisian = 'WESTERN_FRISIAN',
  Wolof = 'WOLOF',
  Xhosa = 'XHOSA',
  Yiddish = 'YIDDISH',
  Yoruba = 'YORUBA',
  Zhuang = 'ZHUANG',
  Zulu = 'ZULU'
}

export type LegalTeamMember = {
  __typename?: 'LegalTeamMember';
  role: LegalTeamMemberRole;
  userId: Scalars['String']['output'];
};

export enum LegalTeamMemberRole {
  CaseManager = 'CASE_MANAGER',
  Paralegal = 'PARALEGAL',
  ResponsibleAttorney = 'RESPONSIBLE_ATTORNEY'
}

export type Location = {
  __typename?: 'Location';
  county?: Maybe<Scalars['String']['output']>;
  state: Scalars['String']['output'];
};

export type LoginByEmailRequest = {
  email: Scalars['String']['input'];
  isOtpLogin?: InputMaybe<Scalars['Boolean']['input']>;
  redirectUrl?: InputMaybe<Scalars['String']['input']>;
};

export type LoginByPhoneRequest = {
  phone: Scalars['String']['input'];
};

export type LoginByTokenRequest = {
  token: Scalars['String']['input'];
};

export type LoginByTokenResponse = {
  __typename?: 'LoginByTokenResponse';
  error?: Maybe<LoginError>;
  success: Scalars['Boolean']['output'];
  token?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export enum LoginError {
  UnknownError = 'UNKNOWN_ERROR',
  UserNotFound = 'USER_NOT_FOUND',
  WrongCodeVerification = 'WRONG_CODE_VERIFICATION'
}

export type LoginImpersonateRequest = {
  email: Scalars['String']['input'];
  impersonationRoles?: InputMaybe<Array<Role>>;
};

export type LoginImpersonateResponse = {
  __typename?: 'LoginImpersonateResponse';
  error?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
  token?: Maybe<Scalars['String']['output']>;
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  error?: Maybe<LoginError>;
  success: Scalars['Boolean']['output'];
};

export type LssEvent = {
  __typename?: 'LssEvent';
  attendees: Array<Attendee>;
  bookeeEventId?: Maybe<Scalars['String']['output']>;
  bookeeMeetingId?: Maybe<Scalars['String']['output']>;
  calendlyEventId?: Maybe<Scalars['String']['output']>;
  caseId: Scalars['String']['output'];
  data: LssEventData;
  endDate: Scalars['Date']['output'];
  eventType: EventType;
  id: Scalars['String']['output'];
  isCancelled: Scalars['Boolean']['output'];
  isCompleted: Scalars['Boolean']['output'];
  startDate: Scalars['Date']['output'];
  syncInfo?: Maybe<SyncInfo>;
};

export type LssEventData = {
  __typename?: 'LssEventData';
  lssStatus: LssStatus;
};

export enum LssStatus {
  Completed = 'COMPLETED',
  CompletedNoShow = 'COMPLETED_NO_SHOW',
  Created = 'CREATED',
  Rejected = 'REJECTED'
}

export type Mutation = {
  __typename?: 'Mutation';
  cancelCalendlyScheduledEvent: CalendlyCanceledEventResponse;
  createBookitRequest: BookitRequestResponse;
  createVisitor: Visitor;
  createWillDataPoints: WillDataPoints;
  createWillFormSteps?: Maybe<WillFormSteps>;
  flagSubmittedQuestionnaire: CasesResolverResponse;
  flagVisitedMyMarbleWeb: UsersResolverResponse;
  flagVisitedOnboardingWizard: UsersResolverResponse;
  generateSingleUseSchedulingUrl?: Maybe<Scalars['String']['output']>;
  generateWillPdf?: Maybe<GenerateWillReponse>;
  hash: HashResponse;
  loginByEmail: LoginResponse;
  loginByPhone: LoginResponse;
  loginByToken: LoginByTokenResponse;
  loginImpersonate: LoginImpersonateResponse;
  patchWillDataPoints?: Maybe<WillDataPoints>;
  predict: PredictResponse;
  refreshToken: RefreshTokenResponse;
  registerCaseUpdateFeedback: CasesResolverResponse;
  registerDevice: Scalars['Boolean']['output'];
  registerUserSeenUpdate: CasesResolverResponse;
  removeDocumentCustomerVisibility: UpdateResponse;
  renameDocument: UpdateResponse;
  requestDocumentUpload: DocumentUploadResponse;
  sendSupportMsg?: Maybe<SendSupportMsgResponse>;
  submitDocumentsDataPoints: SubmitDocumentsDataPointsResponse;
  submitRepeatSupportRequest: SubmitRepeatSupportRequestResponse;
  submitUnavailableDocument: SubmitUnavailableDocumentResponse;
  submitUserDraftData: DataPoints;
  updateRepeat: Repeat;
  updateWillFormSteps?: Maybe<UpdateWillFormSteps>;
  verifyOTP: VerifyOtpResponse;
};


export type MutationCancelCalendlyScheduledEventArgs = {
  payload: CancelCalendlyEventRequest;
};


export type MutationCreateBookitRequestArgs = {
  payload: CreateBookitRequestPayload;
};


export type MutationCreateVisitorArgs = {
  newVisitor: CreateVisitorInput;
};


export type MutationCreateWillDataPointsArgs = {
  payload: CreateWillRequest;
};


export type MutationCreateWillFormStepsArgs = {
  payload: CreateWillFormStepsRequest;
};


export type MutationFlagSubmittedQuestionnaireArgs = {
  caseId: Scalars['String']['input'];
};


export type MutationGenerateSingleUseSchedulingUrlArgs = {
  payload: ScheduleLinkRequest;
};


export type MutationGenerateWillPdfArgs = {
  willId: Scalars['String']['input'];
};


export type MutationHashArgs = {
  request: HashRequest;
};


export type MutationLoginByEmailArgs = {
  loginByEmailRequest: LoginByEmailRequest;
};


export type MutationLoginByPhoneArgs = {
  loginByPhoneRequest: LoginByPhoneRequest;
};


export type MutationLoginByTokenArgs = {
  loginByTokenRequest: LoginByTokenRequest;
};


export type MutationLoginImpersonateArgs = {
  loginImpersonateRequest: LoginImpersonateRequest;
};


export type MutationPatchWillDataPointsArgs = {
  payload: PatchWillRequest;
};


export type MutationPredictArgs = {
  payload: PredictRequest;
};


export type MutationRefreshTokenArgs = {
  refreshTokenRequest: RefreshTokenRequest;
};


export type MutationRegisterCaseUpdateFeedbackArgs = {
  input: RegisterCaseUpdateFeedbackInput;
};


export type MutationRegisterDeviceArgs = {
  input: RegisterDeviceInput;
};


export type MutationRegisterUserSeenUpdateArgs = {
  caseUpdateId: Scalars['String']['input'];
};


export type MutationRemoveDocumentCustomerVisibilityArgs = {
  id: Scalars['String']['input'];
};


export type MutationRenameDocumentArgs = {
  id: Scalars['String']['input'];
  newFileName: Scalars['String']['input'];
};


export type MutationRequestDocumentUploadArgs = {
  payload: DocumentUploadRequest;
};


export type MutationSendSupportMsgArgs = {
  msg: Scalars['String']['input'];
};


export type MutationSubmitDocumentsDataPointsArgs = {
  input: DocumentsDataPointsSubmissionInput;
};


export type MutationSubmitRepeatSupportRequestArgs = {
  input: RepeatSupportRequestInput;
};


export type MutationSubmitUnavailableDocumentArgs = {
  input: SubmitUnavailableDocumentInput;
};


export type MutationSubmitUserDraftDataArgs = {
  input: DataPointsSubmissionInput;
};


export type MutationUpdateRepeatArgs = {
  payload: UpdateRepeatRequest;
  repeatId: Scalars['String']['input'];
};


export type MutationUpdateWillFormStepsArgs = {
  payload: UpdateWillFormStepsRequest;
};


export type MutationVerifyOtpArgs = {
  verifyOTPRequest: VerifyOtpRequest;
};

export type Name = {
  __typename?: 'Name';
  first: Scalars['String']['output'];
  last: Scalars['String']['output'];
  middle?: Maybe<Scalars['String']['output']>;
};

export type NameInput = {
  first: Scalars['String']['input'];
  last: Scalars['String']['input'];
  middle?: InputMaybe<Scalars['String']['input']>;
};

export type OtherBackup = {
  __typename?: 'OtherBackup';
  optionType: OtherBackupType;
};

export type OtherBackupInput = {
  optionType: OtherBackupType;
};

export enum OtherBackupType {
  Children = 'CHILDREN',
  Descendants = 'DESCENDANTS',
  ListedInInheritance = 'LISTED_IN_INHERITANCE'
}

export type Pagination = {
  __typename?: 'Pagination';
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
};

export type PaginationInput = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type PartialName = {
  __typename?: 'PartialName';
  first?: Maybe<Scalars['String']['output']>;
  last?: Maybe<Scalars['String']['output']>;
  middle?: Maybe<Scalars['String']['output']>;
};

export type PartialNameInput = {
  first?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['String']['input']>;
  middle?: InputMaybe<Scalars['String']['input']>;
};

export type PatchWillRequest = {
  administrators?: InputMaybe<Array<PersonInput>>;
  burial?: InputMaybe<Burial>;
  burialAdditionalInfo?: InputMaybe<Scalars['String']['input']>;
  ceremony?: InputMaybe<Ceremony>;
  ceremonyAdditionalInfo?: InputMaybe<Scalars['String']['input']>;
  childrenGuardians?: InputMaybe<Array<PersonInput>>;
  gifts?: InputMaybe<GiftsInput>;
  hasChildrenInheritance?: InputMaybe<Scalars['Boolean']['input']>;
  hasChildrenUnder18?: InputMaybe<Scalars['Boolean']['input']>;
  hasPartnerInheritance?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
  personalData?: InputMaybe<WillPersonalDataPatchInput>;
  pets?: InputMaybe<Array<PetInput>>;
  petsFunds?: InputMaybe<Scalars['Float']['input']>;
  petsGuardians?: InputMaybe<Array<PersonInput>>;
  residuaryPlan?: InputMaybe<ResiduaryPlanInput>;
  shouldAddResiduaryPlanBackups?: InputMaybe<Scalars['Boolean']['input']>;
  statement?: InputMaybe<Scalars['String']['input']>;
  wishesAdministrators?: InputMaybe<Array<PersonInput>>;
};

export type Person = {
  __typename?: 'Person';
  name: Scalars['String']['output'];
  relation: Relation;
};

export type PersonInput = {
  name: Scalars['String']['input'];
  relation: Relation;
};

export enum PersonalStatues {
  DomesticPartnership = 'DOMESTIC_PARTNERSHIP',
  InTheProgressOfADivorce = 'IN_THE_PROGRESS_OF_A_DIVORCE',
  Married = 'MARRIED',
  Single = 'SINGLE',
  Widowed = 'WIDOWED'
}

export type Pet = {
  __typename?: 'Pet';
  name: Scalars['String']['output'];
  species: Species;
};

export type PetInput = {
  name: Scalars['String']['input'];
  species: Species;
};

export enum PlatformType {
  Android = 'ANDROID',
  Ios = 'IOS'
}

export type PracticeArea = {
  __typename?: 'PracticeArea';
  displayName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  key: Scalars['String']['output'];
};

export type PracticeAreaLocation = {
  __typename?: 'PracticeAreaLocation';
  fips: Scalars['String']['output'];
  stateId: Scalars['String']['output'];
};

export enum PracticeAreaName {
  Family = 'FAMILY',
  Immigration = 'IMMIGRATION'
}

export type PredictRequest = {
  features: Scalars['JSON']['input'];
};

export type PredictResponse = {
  __typename?: 'PredictResponse';
  probability: Scalars['Float']['output'];
};

export type Property = {
  __typename?: 'Property';
  address: WillsAddress;
  beneficiaryCharity?: Maybe<BeneficiaryCharity>;
  beneficiaryPerson?: Maybe<BeneficiaryPerson>;
  description: Scalars['String']['output'];
};

export type PropertyInput = {
  address: WillsAddressInput;
  beneficiaryCharity?: InputMaybe<BeneficiaryCharityInput>;
  beneficiaryPerson?: InputMaybe<BeneficiaryPersonInput>;
  description: Scalars['String']['input'];
};

export type Query = {
  __typename?: 'Query';
  getAllPersonValuesByWillId?: Maybe<BankOptionsResponse>;
  getAttorney: Attorney;
  getAttorneyReviews?: Maybe<Array<AttorneyReview>>;
  getAuthenticatedUser: UsersResolverResponse;
  getAvailableSlots: Array<AvailableSlot>;
  getBookItMeetingSlots: Array<AvailableSlot>;
  getBookItMeetings: GetBookItMeetingsResponse;
  getCalendlyLinks: CalendlyLinksResponse;
  getCaseById?: Maybe<Case>;
  getCasesByUserId?: Maybe<Array<Case>>;
  getCustomer: User;
  getCustomerCases?: Maybe<Array<Case>>;
  getCustomerDocuments: Array<Document>;
  getDocumentDownloadUrl: Scalars['String']['output'];
  getDocumentsByCaseId: Array<Document>;
  getRequiredDocuments: RequiredDocumentsResponse;
  getScheduledEventData: ScheduledEventResponse;
  getServiceDrafts: ServiceDrafts;
  getServingTaskByCaseId?: Maybe<Array<ServingTask>>;
  getStaticCaseUpdate?: Maybe<GetCaseUpdateResponse>;
  getSupportedUploadDocumentTypes: Array<DocumentType>;
  getUserCasesByStatus?: Maybe<Array<Case>>;
  getUserDraftsDataPoints: DataPoints;
  getUserDraftsRequestSchema: DraftSchema;
  getVisitorById: Visitor;
  getVisitorStatus: VisitorStatus;
  getWillDataById?: Maybe<WillDataPoints>;
  getWillFormStepsByUser?: Maybe<Array<WillFormSteps>>;
  getWillsDataByUserId: Array<WillDataPoints>;
  hasAvailableSlots: Scalars['Boolean']['output'];
  listCustomers: CustomersList;
};


export type QueryGetAllPersonValuesByWillIdArgs = {
  willId: Scalars['String']['input'];
};


export type QueryGetAttorneyReviewsArgs = {
  filter: AttorneyReviewsFilter;
  sort?: InputMaybe<SingleFieldSort>;
};


export type QueryGetAvailableSlotsArgs = {
  payload: GetAvailableSlotsRequest;
};


export type QueryGetBookItMeetingSlotsArgs = {
  input: GetBookItMeetingSlotsInput;
};


export type QueryGetBookItMeetingsArgs = {
  getBookItMeetingsInput: GetBookItMeetingsInput;
};


export type QueryGetCalendlyLinksArgs = {
  eventId: Scalars['String']['input'];
};


export type QueryGetCaseByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetCasesByUserIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetCustomerArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetDocumentDownloadUrlArgs = {
  documentId: Scalars['String']['input'];
};


export type QueryGetDocumentsByCaseIdArgs = {
  caseId: Scalars['String']['input'];
};


export type QueryGetScheduledEventDataArgs = {
  scheduledEventUri: Scalars['String']['input'];
};


export type QueryGetServingTaskByCaseIdArgs = {
  caseId: Scalars['String']['input'];
};


export type QueryGetStaticCaseUpdateArgs = {
  input: StaticCaseUpdateInput;
};


export type QueryGetUserCasesByStatusArgs = {
  filter?: InputMaybe<GetUserCasesInput>;
};


export type QueryGetVisitorByIdArgs = {
  visitorId: Scalars['String']['input'];
};


export type QueryGetVisitorStatusArgs = {
  visitorStatusInput: VisitorStatusInputReq;
};


export type QueryGetWillDataByIdArgs = {
  willId: Scalars['String']['input'];
};


export type QueryHasAvailableSlotsArgs = {
  payload: GetAvailableSlotsRequest;
};


export type QueryListCustomersArgs = {
  filter?: InputMaybe<CustomersListFilter>;
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<SingleFieldSort>;
};

export type RefreshTokenRequest = {
  token: Scalars['String']['input'];
};

export type RefreshTokenResponse = {
  __typename?: 'RefreshTokenResponse';
  error?: Maybe<AuthError>;
  success: Scalars['Boolean']['output'];
  token?: Maybe<Scalars['String']['output']>;
};

export type RegisterCaseUpdateFeedbackInput = {
  caseUpdateId: Scalars['String']['input'];
  feedbackType: FeedbackType;
  negativeFeedbackFreeText?: InputMaybe<Scalars['String']['input']>;
  negativeFeedbackReason?: InputMaybe<Scalars['String']['input']>;
};

export type RegisterDeviceInput = {
  app: App;
  platform: PlatformType;
  token: Scalars['String']['input'];
};

export type RejectedModel = {
  __typename?: 'RejectedModel';
  rejectedNotes?: Maybe<Scalars['String']['output']>;
  rejectedReason?: Maybe<Scalars['String']['output']>;
};

export enum Relation {
  Child = 'CHILD',
  Friend = 'FRIEND',
  Other = 'OTHER',
  Parent = 'PARENT',
  Partner = 'PARTNER',
  Relative = 'RELATIVE',
  Sibling = 'SIBLING'
}

export type Repeat = {
  __typename?: 'Repeat';
  attorneyId: Scalars['String']['output'];
  attorneyNotes?: Maybe<Scalars['String']['output']>;
  caseId: Scalars['String']['output'];
  changedReason?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['String']['output'];
  isNoTouchFlowEligible?: Maybe<Scalars['Boolean']['output']>;
  rejected?: Maybe<RejectedModel>;
  services: RepeatService;
  status?: Maybe<RepeatStatus>;
};

export enum RepeatFlowType {
  NoTouch = 'NO_TOUCH',
  Touch = 'TOUCH'
}

export type RepeatService = {
  __typename?: 'RepeatService';
  addedServiceIds: Array<Scalars['String']['output']>;
  removedServicesIds: Array<Scalars['String']['output']>;
};

export enum RepeatStatus {
  Approved = 'APPROVED',
  Declined = 'DECLINED',
  Open = 'OPEN'
}

export enum RepeatSupportRequestError {
  RepeatSupportRequestNotSent = 'REPEAT_SUPPORT_REQUEST_NOT_SENT'
}

export type RepeatSupportRequestInput = {
  message: Scalars['String']['input'];
  repeatId: Scalars['String']['input'];
  supportRequestType: RepeatSupportRequestTypeEnum;
};

export enum RepeatSupportRequestTypeEnum {
  Other = 'OTHER',
  Payment = 'PAYMENT',
  Services = 'SERVICES'
}

export type RequiredDocumentsResponse = {
  __typename?: 'RequiredDocumentsResponse';
  documentTypes: Array<DocumentType>;
  lssIds: Array<Scalars['String']['output']>;
  repeatIds: Array<Scalars['String']['output']>;
};

export type ResiduaryPlan = {
  __typename?: 'ResiduaryPlan';
  charities?: Maybe<Array<ResiduaryPlanCharities>>;
  persons?: Maybe<Array<ResiduaryPlanPersons>>;
};

export type ResiduaryPlanCharities = {
  __typename?: 'ResiduaryPlanCharities';
  backups?: Maybe<Backups>;
  charity: Charity;
  percent: Scalars['Float']['output'];
};

export type ResiduaryPlanCharitiesInput = {
  backups?: InputMaybe<BackupsInput>;
  charity: CharityInput;
  percent: Scalars['Float']['input'];
};

export type ResiduaryPlanInput = {
  charities?: InputMaybe<Array<ResiduaryPlanCharitiesInput>>;
  persons?: InputMaybe<Array<ResiduaryPlanPersonsInput>>;
};

export type ResiduaryPlanPersons = {
  __typename?: 'ResiduaryPlanPersons';
  backups?: Maybe<Backups>;
  percent: Scalars['Float']['output'];
  person: Person;
};

export type ResiduaryPlanPersonsInput = {
  backups?: InputMaybe<BackupsInput>;
  percent: Scalars['Float']['input'];
  person: PersonInput;
};

export enum ResiduarySubSteps {
  Backups = 'Backups',
  Cover = 'Cover',
  DesignateBackups = 'DesignateBackups',
  Plan = 'Plan',
  Summary = 'Summary'
}

export enum ReviewSubSteps {
  Main = 'Main'
}

export enum Role {
  Admin = 'ADMIN',
  Attorney = 'ATTORNEY',
  Customer = 'CUSTOMER',
  ExternalAttorney = 'EXTERNAL_ATTORNEY',
  ExternalParalegal = 'EXTERNAL_PARALEGAL',
  Paralegal = 'PARALEGAL'
}

export type ScheduleLinkRequest = {
  eventName: CalendlyEventName;
  inviteeId: Scalars['String']['input'];
  inviterId: Scalars['String']['input'];
};

export type ScheduledEventResponse = {
  __typename?: 'ScheduledEventResponse';
  endTime: Scalars['String']['output'];
  eventName: Scalars['String']['output'];
  startTime: Scalars['String']['output'];
};

export type Service = {
  __typename?: 'Service';
  caseId: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  legalTeam: Array<LegalTeamMember>;
  location: Location;
  name: Scalars['String']['output'];
  practiceAreaId: Scalars['String']['output'];
  serviceType?: Maybe<ServiceType>;
  serviceTypeId: Scalars['String']['output'];
  status: ServiceStatus;
  userId: Scalars['String']['output'];
};

export enum ServiceAttitude {
  Dislike = 'DISLIKE',
  NoPrefrence = 'NO_PREFRENCE',
  Prefer = 'PREFER'
}

export type ServiceDraft = {
  __typename?: 'ServiceDraft';
  caseId: Scalars['String']['output'];
  serviceId: Scalars['String']['output'];
  status: ServiceDraftsStatus;
  userId: Scalars['String']['output'];
};

export type ServiceDrafts = {
  __typename?: 'ServiceDrafts';
  serviceDraftsList: Array<ServiceDraft>;
};

/** The possible status values for service drafts */
export enum ServiceDraftsStatus {
  Attorney = 'ATTORNEY',
  Client = 'CLIENT',
  Completed = 'COMPLETED'
}

export type ServiceExpertise = {
  __typename?: 'ServiceExpertise';
  attitude?: Maybe<ServiceAttitude>;
  key: Scalars['String']['output'];
};

export type ServicePreference = {
  __typename?: 'ServicePreference';
  key: Scalars['String']['output'];
};

export enum ServiceStatus {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Disengaged = 'DISENGAGED',
  Open = 'OPEN',
  PartiallyRendered = 'PARTIALLY_RENDERED',
  Pending = 'PENDING'
}

export type ServiceType = {
  __typename?: 'ServiceType';
  category?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  descriptionForClient?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  practiceAreaId: Scalars['String']['output'];
  stepsForClient?: Maybe<Array<StepsForClientDto>>;
  suggestedNextSteps?: Maybe<Scalars['String']['output']>;
  whatHappensNext?: Maybe<Scalars['String']['output']>;
  whatIsIncluded?: Maybe<Array<Scalars['String']['output']>>;
  whatIsNotIncluded?: Maybe<Scalars['String']['output']>;
};

export type ServingAttempt = {
  __typename?: 'ServingAttempt';
  address: Scalars['String']['output'];
  attemptDate: Scalars['Date']['output'];
  attemptId: Scalars['String']['output'];
  description: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export enum ServingStatus {
  Assigned = 'ASSIGNED',
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Created = 'CREATED',
  Deleted = 'DELETED',
  InProgress = 'IN_PROGRESS',
  InReview = 'IN_REVIEW',
  Pending = 'PENDING',
  PendingFiling = 'PENDING_FILING',
  PendingNotary = 'PENDING_NOTARY',
  PendingRating = 'PENDING_RATING',
  Reassigned = 'REASSIGNED',
  Unassigned = 'UNASSIGNED',
  Unknown = 'UNKNOWN'
}

export type ServingTask = {
  __typename?: 'ServingTask';
  attempts: Array<ServingAttempt>;
  status: ServingStatus;
};

export type SingleFieldSort = {
  field: Scalars['String']['input'];
  order: SortOrder;
};

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum SourceApps {
  AttorneysWebApp = 'ATTORNEYS_WEB_APP',
  EmailProxy = 'EMAIL_PROXY',
  Flarex = 'FLAREX',
  GoogleDrive = 'GOOGLE_DRIVE',
  Lawmatics = 'LAWMATICS',
  MyMarbleMobile = 'MY_MARBLE_MOBILE',
  MyMarbleWeb = 'MY_MARBLE_WEB',
  Netsuite = 'NETSUITE',
  Pactsafe = 'PACTSAFE'
}

export enum Species {
  Bird = 'BIRD',
  Cat = 'CAT',
  Dog = 'DOG',
  Fish = 'FISH',
  Other = 'OTHER'
}

export type SpecificItem = {
  __typename?: 'SpecificItem';
  beneficiaryCharity?: Maybe<BeneficiaryCharity>;
  beneficiaryPerson?: Maybe<BeneficiaryPerson>;
  description: Scalars['String']['output'];
};

export type SpecificItemInput = {
  beneficiaryCharity?: InputMaybe<BeneficiaryCharityInput>;
  beneficiaryPerson?: InputMaybe<BeneficiaryPersonInput>;
  description: Scalars['String']['input'];
};

export enum StaticCaseUpdateEnum {
  ASrcC = 'A_SRC_C',
  ASrcIp = 'A_SRC_IP',
  ASrcNs = 'A_SRC_NS',
  BSrcC = 'B_SRC_C',
  BSrcIp = 'B_SRC_IP',
  BSrcNs = 'B_SRC_NS'
}

export type StaticCaseUpdateInput = {
  step?: InputMaybe<StaticCaseUpdateEnum>;
};

export type StepsForClientDto = {
  __typename?: 'StepsForClientDto';
  description?: Maybe<Scalars['String']['output']>;
  estimation?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  stepNumber: Scalars['Float']['output'];
};

export type SubmitDocumentsDataPointsResponse = {
  __typename?: 'SubmitDocumentsDataPointsResponse';
  error?: Maybe<DataPointsSubmissionError>;
  success: Scalars['Boolean']['output'];
};

export type SubmitRepeatSupportRequestResponse = {
  __typename?: 'SubmitRepeatSupportRequestResponse';
  error?: Maybe<RepeatSupportRequestError>;
  success: Scalars['Boolean']['output'];
};

export enum SubmitUnavailableDocumentError {
  SubmitUnavailableDocumentError = 'SUBMIT_UNAVAILABLE_DOCUMENT_ERROR'
}

export type SubmitUnavailableDocumentInput = {
  attorneyId: Scalars['String']['input'];
  documentTypeId: Scalars['String']['input'];
  unavailableDocumentReason: Scalars['String']['input'];
};

export type SubmitUnavailableDocumentResponse = {
  __typename?: 'SubmitUnavailableDocumentResponse';
  error?: Maybe<SubmitUnavailableDocumentError>;
  success: Scalars['Boolean']['output'];
};

export type SyncInfo = {
  __typename?: 'SyncInfo';
  salesforceOpportunityId: Scalars['String']['output'];
};

export enum UpdateDocumentError {
  DocumentNotFound = 'DOCUMENT_NOT_FOUND',
  UnknownError = 'UNKNOWN_ERROR'
}

export type UpdateRepeatRequest = {
  status: RepeatStatus;
};

export type UpdateResponse = {
  __typename?: 'UpdateResponse';
  error?: Maybe<UpdateDocumentError>;
  success: Scalars['Boolean']['output'];
};

export type UpdateWillFormSteps = {
  __typename?: 'UpdateWillFormSteps';
  administrator?: Maybe<WillAdministratorInfo>;
  basicInformation?: Maybe<WillBasicInfo>;
  family?: Maybe<WillFamilyInfo>;
  funeralWishes?: Maybe<WillStepInfo>;
  gifts?: Maybe<WillStepInfo>;
  id: Scalars['String']['output'];
  residuary?: Maybe<WillResiduaryInfo>;
  review?: Maybe<WillReviewInfo>;
  userId: Scalars['String']['output'];
};

export type UpdateWillFormStepsRequest = {
  administrator?: InputMaybe<WillAdministratorInfoInput>;
  basicInformation?: InputMaybe<WillBasicInfoInput>;
  family?: InputMaybe<WillFamilyInfoInput>;
  funeralWishes?: InputMaybe<WillStepInfoInput>;
  gifts?: InputMaybe<WillStepInfoInput>;
  id: Scalars['String']['input'];
  residuary?: InputMaybe<WillResiduaryInfoInput>;
  review?: InputMaybe<WillReviewInfoInput>;
};

export type User = {
  __typename?: 'User';
  address?: Maybe<Address>;
  attorneyData?: Maybe<AttorneyData>;
  bookItId?: Maybe<Scalars['String']['output']>;
  casesStatuses: CasesStatuses;
  customerData?: Maybe<CustomerData>;
  email: Scalars['String']['output'];
  emailAlias?: Maybe<Scalars['String']['output']>;
  hasCase?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  marbleId: Scalars['String']['output'];
  name: Name;
  phone?: Maybe<Scalars['String']['output']>;
  roles: Array<Role>;
};

export enum UsersError {
  UnknownError = 'UNKNOWN_ERROR',
  UserIdRequiredError = 'USER_ID_REQUIRED_ERROR',
  UserNotFound = 'USER_NOT_FOUND',
  WrongCodeVerification = 'WRONG_CODE_VERIFICATION'
}

export type UsersResolverResponse = {
  __typename?: 'UsersResolverResponse';
  error?: Maybe<UsersError>;
  success: Scalars['Boolean']['output'];
  user?: Maybe<User>;
};

export type Vehicle = {
  __typename?: 'Vehicle';
  beneficiaryCharity?: Maybe<BeneficiaryCharity>;
  beneficiaryPerson?: Maybe<BeneficiaryPerson>;
  description: Scalars['String']['output'];
};

export type VehicleInput = {
  beneficiaryCharity?: InputMaybe<BeneficiaryCharityInput>;
  beneficiaryPerson?: InputMaybe<BeneficiaryPersonInput>;
  description: Scalars['String']['input'];
};

export type VerifyOtpRequest = {
  code: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type VerifyOtpResponse = {
  __typename?: 'VerifyOTPResponse';
  error?: Maybe<LoginError>;
  success: Scalars['Boolean']['output'];
  token?: Maybe<Scalars['String']['output']>;
};

export type Visitor = {
  __typename?: 'Visitor';
  address?: Maybe<Address>;
  anonymousId: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  funnelAnswers?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['String']['output'];
  leadScore?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<PartialName>;
  phone?: Maybe<Scalars['String']['output']>;
  utmData?: Maybe<Scalars['JSON']['output']>;
};

export type VisitorStatus = {
  __typename?: 'VisitorStatus';
  statusCode: VisitorStatusCode;
};

export enum VisitorStatusCode {
  Cnf3 = 'CNF3',
  Crh6 = 'CRH6',
  Csi2 = 'CSI2',
  Cur4 = 'CUR4',
  Cws5 = 'CWS5',
  Drc7 = 'DRC7',
  Elg1 = 'ELG1',
  Error = 'ERROR'
}

export type VisitorStatusInputReq = {
  answers: Scalars['JSON']['input'];
};

export type WillAdministratorInfo = {
  __typename?: 'WillAdministratorInfo';
  lastActiveSubStep: AdministratorSubSteps;
  stepStatus: WillStepStatuses;
};

export type WillAdministratorInfoInput = {
  lastActiveSubStep: AdministratorSubSteps;
  stepStatus: WillStepStatuses;
};

export type WillBasicInfo = {
  __typename?: 'WillBasicInfo';
  lastActiveSubStep: BasicInfoSubSteps;
  stepStatus: WillStepStatuses;
};

export type WillBasicInfoInput = {
  lastActiveSubStep: BasicInfoSubSteps;
  stepStatus: WillStepStatuses;
};

export type WillDataPoints = {
  __typename?: 'WillDataPoints';
  administrators?: Maybe<Array<Person>>;
  burial?: Maybe<Burial>;
  burialAdditionalInfo?: Maybe<Scalars['String']['output']>;
  ceremony?: Maybe<Ceremony>;
  ceremonyAdditionalInfo?: Maybe<Scalars['String']['output']>;
  childrenGuardians?: Maybe<Array<Person>>;
  gifts?: Maybe<Gifts>;
  hasChildrenInheritance?: Maybe<Scalars['Boolean']['output']>;
  hasChildrenUnder18?: Maybe<Scalars['Boolean']['output']>;
  hasPartnerInheritance?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  personalData: WillPersonalData;
  pets?: Maybe<Array<Pet>>;
  petsFunds?: Maybe<Scalars['Float']['output']>;
  petsGuardians?: Maybe<Array<Person>>;
  residuaryPlan?: Maybe<ResiduaryPlan>;
  shouldAddResiduaryPlanBackups?: Maybe<Scalars['Boolean']['output']>;
  statement?: Maybe<Scalars['String']['output']>;
  userId: Scalars['String']['output'];
  wishesAdministrators?: Maybe<Array<Person>>;
};

export type WillFamilyInfo = {
  __typename?: 'WillFamilyInfo';
  lastActiveSubStep: FamilySubSteps;
  stepStatus: WillStepStatuses;
};

export type WillFamilyInfoInput = {
  lastActiveSubStep: FamilySubSteps;
  stepStatus: WillStepStatuses;
};

export type WillFormSteps = {
  __typename?: 'WillFormSteps';
  administrator: WillAdministratorInfo;
  basicInformation: WillBasicInfo;
  family: WillFamilyInfo;
  funeralWishes: WillStepInfo;
  gifts: WillStepInfo;
  id: Scalars['String']['output'];
  residuary: WillResiduaryInfo;
  review: WillReviewInfo;
};

export type WillPersonalData = {
  __typename?: 'WillPersonalData';
  address?: Maybe<WillsAddress>;
  children?: Maybe<Array<Person>>;
  dateOfBirth: Scalars['Date']['output'];
  email: Scalars['String']['output'];
  name: Name;
  partner?: Maybe<Person>;
  personalStatus?: Maybe<PersonalStatues>;
};

export type WillPersonalDataInput = {
  address?: InputMaybe<WillsAddressInput>;
  children?: InputMaybe<Array<PersonInput>>;
  dateOfBirth: Scalars['Date']['input'];
  email: Scalars['String']['input'];
  name: NameInput;
  partner?: InputMaybe<PersonInput>;
  personalStatus?: InputMaybe<PersonalStatues>;
};

export type WillPersonalDataPatchInput = {
  address?: InputMaybe<WillsAddressInput>;
  children?: InputMaybe<Array<PersonInput>>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<NameInput>;
  partner?: InputMaybe<PersonInput>;
  personalStatus?: InputMaybe<PersonalStatues>;
};

export type WillResiduaryInfo = {
  __typename?: 'WillResiduaryInfo';
  lastActiveSubStep: ResiduarySubSteps;
  stepStatus: WillStepStatuses;
};

export type WillResiduaryInfoInput = {
  lastActiveSubStep: ResiduarySubSteps;
  stepStatus: WillStepStatuses;
};

export type WillReviewInfo = {
  __typename?: 'WillReviewInfo';
  lastActiveSubStep: ReviewSubSteps;
  stepStatus: WillStepStatuses;
};

export type WillReviewInfoInput = {
  lastActiveSubStep: ReviewSubSteps;
  stepStatus: WillStepStatuses;
};

export type WillStepInfo = {
  __typename?: 'WillStepInfo';
  lastActiveSubStep?: Maybe<Scalars['String']['output']>;
  stepStatus: WillStepStatuses;
};

export type WillStepInfoInput = {
  lastActiveSubStep?: InputMaybe<Scalars['String']['input']>;
  stepStatus: WillStepStatuses;
};

export enum WillStepStatuses {
  Blocked = 'BLOCKED',
  Done = 'DONE',
  InProgress = 'IN_PROGRESS'
}

export type WillsAddress = {
  __typename?: 'WillsAddress';
  city: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
  state: Scalars['String']['output'];
  street: Scalars['String']['output'];
  streetDetails?: Maybe<Scalars['String']['output']>;
};

export type WillsAddressInput = {
  city: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  state: Scalars['String']['input'];
  street: Scalars['String']['input'];
  streetDetails?: InputMaybe<Scalars['String']['input']>;
};

export type SendSupportMsgResponse = {
  __typename?: 'sendSupportMsgResponse';
  isSended: Scalars['Boolean']['output'];
};

export type HashMutationVariables = Exact<{
  request: HashRequest;
}>;


export type HashMutation = {
  __typename?: 'Mutation',
  hash: {
    __typename?: 'HashResponse',
    success: boolean,
    hash?: any | null | undefined,
    error?: HashError | null | undefined
  }
};

export type CreateBookitRequestMutationVariables = Exact<{
  payload: CreateBookitRequestPayload;
}>;


export type CreateBookitRequestMutation = {
  __typename?: 'Mutation',
  createBookitRequest: {
    __typename?: 'BookitRequestResponse',
    id: string
  }
};

export type GetAvailableSlotsQueryVariables = Exact<{
  payload: GetAvailableSlotsRequest;
}>;


export type GetAvailableSlotsQuery = {
  __typename?: 'Query',
  getAvailableSlots: Array<{
    __typename?: 'AvailableSlot',
    start: string
  }>
};

export type GetVisitorStatusQueryVariables = Exact<{
  visitorStatusInput: VisitorStatusInputReq;
}>;


export type GetVisitorStatusQuery = {
  __typename?: 'Query',
  getVisitorStatus: {
    __typename?: 'VisitorStatus',
    statusCode: VisitorStatusCode
  }
};

export type HasAvailableSlotsQueryVariables = Exact<{
  payload: GetAvailableSlotsRequest;
}>;


export type HasAvailableSlotsQuery = {
  __typename?: 'Query',
  hasAvailableSlots: boolean
};


export const HashDocument = gql`
    mutation Hash($request: HashRequest!) {
  hash(request: $request) {
    success
    hash
    error
  }
}
    `;
export type HashMutationFn = Apollo.MutationFunction<HashMutation, HashMutationVariables>;

/**
 * __useHashMutation__
 *
 * To run a mutation, you first call `useHashMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHashMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [hashMutation, { data, loading, error }] = useHashMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useHashMutation(baseOptions?: Apollo.MutationHookOptions<HashMutation, HashMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HashMutation, HashMutationVariables>(HashDocument, options);
      }
export type HashMutationHookResult = ReturnType<typeof useHashMutation>;
export type HashMutationResult = Apollo.MutationResult<HashMutation>;
export type HashMutationOptions = Apollo.BaseMutationOptions<HashMutation, HashMutationVariables>;
export const CreateBookitRequestDocument = gql`
    mutation CreateBookitRequest($payload: CreateBookitRequestPayload!) {
  createBookitRequest(payload: $payload) {
    id
  }
}
    `;
export type CreateBookitRequestMutationFn = Apollo.MutationFunction<CreateBookitRequestMutation, CreateBookitRequestMutationVariables>;

/**
 * __useCreateBookitRequestMutation__
 *
 * To run a mutation, you first call `useCreateBookitRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBookitRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBookitRequestMutation, { data, loading, error }] = useCreateBookitRequestMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useCreateBookitRequestMutation(baseOptions?: Apollo.MutationHookOptions<CreateBookitRequestMutation, CreateBookitRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBookitRequestMutation, CreateBookitRequestMutationVariables>(CreateBookitRequestDocument, options);
      }
export type CreateBookitRequestMutationHookResult = ReturnType<typeof useCreateBookitRequestMutation>;
export type CreateBookitRequestMutationResult = Apollo.MutationResult<CreateBookitRequestMutation>;
export type CreateBookitRequestMutationOptions = Apollo.BaseMutationOptions<CreateBookitRequestMutation, CreateBookitRequestMutationVariables>;
export const GetAvailableSlotsDocument = gql`
    query GetAvailableSlots($payload: GetAvailableSlotsRequest!) {
  getAvailableSlots(payload: $payload) {
    start
  }
}
    `;

/**
 * __useGetAvailableSlotsQuery__
 *
 * To run a query within a React component, call `useGetAvailableSlotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableSlotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableSlotsQuery({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useGetAvailableSlotsQuery(baseOptions: Apollo.QueryHookOptions<GetAvailableSlotsQuery, GetAvailableSlotsQueryVariables> & ({ variables: GetAvailableSlotsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAvailableSlotsQuery, GetAvailableSlotsQueryVariables>(GetAvailableSlotsDocument, options);
      }
export function useGetAvailableSlotsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAvailableSlotsQuery, GetAvailableSlotsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAvailableSlotsQuery, GetAvailableSlotsQueryVariables>(GetAvailableSlotsDocument, options);
        }
export function useGetAvailableSlotsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAvailableSlotsQuery, GetAvailableSlotsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAvailableSlotsQuery, GetAvailableSlotsQueryVariables>(GetAvailableSlotsDocument, options);
        }
export type GetAvailableSlotsQueryHookResult = ReturnType<typeof useGetAvailableSlotsQuery>;
export type GetAvailableSlotsLazyQueryHookResult = ReturnType<typeof useGetAvailableSlotsLazyQuery>;
export type GetAvailableSlotsSuspenseQueryHookResult = ReturnType<typeof useGetAvailableSlotsSuspenseQuery>;
export type GetAvailableSlotsQueryResult = Apollo.QueryResult<GetAvailableSlotsQuery, GetAvailableSlotsQueryVariables>;
export function refetchGetAvailableSlotsQuery(variables: GetAvailableSlotsQueryVariables) {
      return { query: GetAvailableSlotsDocument, variables: variables }
    }
export const GetVisitorStatusDocument = gql`
    query GetVisitorStatus($visitorStatusInput: VisitorStatusInputReq!) {
  getVisitorStatus(visitorStatusInput: $visitorStatusInput) {
    statusCode
  }
}
    `;

/**
 * __useGetVisitorStatusQuery__
 *
 * To run a query within a React component, call `useGetVisitorStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVisitorStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVisitorStatusQuery({
 *   variables: {
 *      visitorStatusInput: // value for 'visitorStatusInput'
 *   },
 * });
 */
export function useGetVisitorStatusQuery(baseOptions: Apollo.QueryHookOptions<GetVisitorStatusQuery, GetVisitorStatusQueryVariables> & ({ variables: GetVisitorStatusQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVisitorStatusQuery, GetVisitorStatusQueryVariables>(GetVisitorStatusDocument, options);
      }
export function useGetVisitorStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVisitorStatusQuery, GetVisitorStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVisitorStatusQuery, GetVisitorStatusQueryVariables>(GetVisitorStatusDocument, options);
        }
export function useGetVisitorStatusSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetVisitorStatusQuery, GetVisitorStatusQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetVisitorStatusQuery, GetVisitorStatusQueryVariables>(GetVisitorStatusDocument, options);
        }
export type GetVisitorStatusQueryHookResult = ReturnType<typeof useGetVisitorStatusQuery>;
export type GetVisitorStatusLazyQueryHookResult = ReturnType<typeof useGetVisitorStatusLazyQuery>;
export type GetVisitorStatusSuspenseQueryHookResult = ReturnType<typeof useGetVisitorStatusSuspenseQuery>;
export type GetVisitorStatusQueryResult = Apollo.QueryResult<GetVisitorStatusQuery, GetVisitorStatusQueryVariables>;
export function refetchGetVisitorStatusQuery(variables: GetVisitorStatusQueryVariables) {
      return { query: GetVisitorStatusDocument, variables: variables }
    }
export const HasAvailableSlotsDocument = gql`
    query HasAvailableSlots($payload: GetAvailableSlotsRequest!) {
  hasAvailableSlots(payload: $payload)
}
    `;

/**
 * __useHasAvailableSlotsQuery__
 *
 * To run a query within a React component, call `useHasAvailableSlotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasAvailableSlotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasAvailableSlotsQuery({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useHasAvailableSlotsQuery(baseOptions: Apollo.QueryHookOptions<HasAvailableSlotsQuery, HasAvailableSlotsQueryVariables> & ({ variables: HasAvailableSlotsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HasAvailableSlotsQuery, HasAvailableSlotsQueryVariables>(HasAvailableSlotsDocument, options);
      }
export function useHasAvailableSlotsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HasAvailableSlotsQuery, HasAvailableSlotsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HasAvailableSlotsQuery, HasAvailableSlotsQueryVariables>(HasAvailableSlotsDocument, options);
        }
export function useHasAvailableSlotsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<HasAvailableSlotsQuery, HasAvailableSlotsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HasAvailableSlotsQuery, HasAvailableSlotsQueryVariables>(HasAvailableSlotsDocument, options);
        }
export type HasAvailableSlotsQueryHookResult = ReturnType<typeof useHasAvailableSlotsQuery>;
export type HasAvailableSlotsLazyQueryHookResult = ReturnType<typeof useHasAvailableSlotsLazyQuery>;
export type HasAvailableSlotsSuspenseQueryHookResult = ReturnType<typeof useHasAvailableSlotsSuspenseQuery>;
export type HasAvailableSlotsQueryResult = Apollo.QueryResult<HasAvailableSlotsQuery, HasAvailableSlotsQueryVariables>;
export function refetchHasAvailableSlotsQuery(variables: HasAvailableSlotsQueryVariables) {
      return { query: HasAvailableSlotsDocument, variables: variables }
    }