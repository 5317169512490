import { useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import type { BuilderStore } from "@builder.io/react";
import { useBookit, useBookSlot } from "@marbletech/bookit-core";
import { captureException } from "@sentry/nextjs";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import type { StripeError } from "@stripe/stripe-js";
import { isEqual } from "date-fns";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";

import { analytics } from "@flare/analytics";

import bannerImg from "@/../public/next/images/checkout_form_banner.png";
import { ShieldIcon } from "@/components/icons/ShieldIcon";
import { Heading } from "@/components/v2/Heading/Heading";
import { Text } from "@/components/v2/Text/Text";
import { useAddPaymentMutation } from "@/generated/graphql.old_backend";
import { PracticeAreaName } from "@/generated/graphql.vinny";
import { useFunnelAnswers } from "@/modules/v2/funnel/hooks/useFunnelAnswers";
import { useFunnelMeta } from "@/modules/v2/funnel/hooks/useFunnelMeta";
import { Interlude } from "@/modules/v2/funnel/Interlude";
import { BiEventsNames } from "@/services/analytics/event-names";
import { useFeatureFlag } from "@/services/experiments";
import { featureFlagNames } from "@/services/experiments/constants";

import { Button } from "../base/Button";
import { StickyActions } from "../base/StickyActions";
import { meetingType } from "../funnel.consts";
import { SkipButton } from "../SkipButton";
import { TextField } from "../TextField";

import type { LssPricesKeys } from "./LssCheckout.consts";
import {
  CARD_CVC,
  CARD_EXPIRY,
  CARD_NUMBER,
  CARD_OWNER,
  CARD_ZIP_CODE,
  CHECKOUT_STATUS,
  lssPrices,
} from "./LssCheckout.consts";
import { PriceInfoCard } from "./PriceInfoCard";
import { StripeComponent } from "./StripeComponent";
import { WalletPay } from "./WalletPay";

export function CheckoutForm({
  clientSecret,
  leadId,
  builderState,
}: {
  builderState: BuilderStore;
  clientSecret: string;
  leadId: string;
}) {
  const [addPaymentMethodMutation] = useAddPaymentMutation();
  const { mutateAsync: bookSlot } = useBookSlot();
  const { meeting } = useBookit();

  const stripe = useStripe();
  const elements = useElements();
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const {
    unregister,
    watch,
    trigger,
    formState: { isValid },
  } = useFormContext();
  const router = useRouter();
  const [isSubmittingPayment, setIsSubmittingPayment] = useState(false);
  const { funnelAnswers } = useFunnelAnswers();
  const practice = router.query.practice as string;
  const subPractice = router.query.subPractice as string;
  const step = router.query.step as string;

  const abTestHighIntent = useFeatureFlag(
    featureFlagNames.FUNNEL_TEST_DIRECT_LSS_HIGH,
    {
      subPractice,
    },
  );

  const { getFunnelMeta, setFunnelMeta } = useFunnelMeta();
  const { funnelActionId, lssStartTime } = funnelAnswers || {};

  function triggerExternalFormSubmit() {
    // Unregister Stripe fields to remove them from form values
    unregister([CARD_NUMBER, CARD_EXPIRY, CARD_CVC, CARD_ZIP_CODE, CARD_OWNER]);
    // Trigger form onSubmit in [step].tsx
    if (submitButtonRef.current) {
      submitButtonRef.current.click();
    }
  }

  async function handlePayment(e: React.MouseEvent) {
    // Prevent form submission in [step].tsx
    e.preventDefault();

    if (!isValid) {
      trigger();
      return;
    }

    analytics.track(BiEventsNames.WebFunnelPaymentClick, {
      value: "submit",
      type: "checkout_form",
      payment_type: meetingType.lss,
      sf_funnel_action_id: funnelActionId,
    });

    setIsSubmittingPayment(true);

    if (!stripe || !elements) {
      console.error("Stripe has not been properly initialized");
      return;
    }

    const card = elements.getElement(CardNumberElement);

    if (!card) {
      console.error("Card information is not available");
      return;
    }

    if (!lssStartTime) {
      console.error("LSS start time is not available");
      return;
    }

    const funnelMeta = getFunnelMeta();

    let confirmCardSetup;

    // Setting up a payment method with the intent to charge it later
    try {
      confirmCardSetup = await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card,
          billing_details: {
            name: watch(CARD_OWNER),
            address: {
              postal_code: watch(CARD_ZIP_CODE),
            },
          },
        },
      });

      if (confirmCardSetup.error) {
        throw confirmCardSetup.error;
      }
    } catch (error) {
      const stripeError = error as StripeError;
      analytics.track(BiEventsNames.WebFunnelPaymentDeclined, {
        current_step_key: step,
        practice,
        ...stripeError,
      });

      captureException(error, {
        extra: {
          action: "confirmCardSetup",
        },
      });

      setFunnelMeta({
        ...funnelMeta,
        [CHECKOUT_STATUS]: "payment-declined",
      });

      triggerExternalFormSubmit();

      return;
    }

    const slot = {
      start: new Date(lssStartTime),
    };
    // If the selected slot is already booked for the user, skip the booking
    if (isEqual(slot.start, new Date(meeting?.dateTime || ""))) {
      analytics.track(BiEventsNames.WebCalendarSkip, {
        slot: slot.start,
        meeting_type: meetingType.lss,
        sf_funnel_action_id: funnelActionId,
      });
    } else {
      // Execute bookit submit meeting
      try {
        await bookSlot(slot);

        analytics.track(BiEventsNames.WebCalendarSuccess, {
          slot: slot.start,
          meeting_type: meetingType.lss,
          email_hash: funnelMeta?.hashedEmail,
          phone_hash: funnelMeta?.hashedPhone,
          email_hash_unsalted: funnelMeta?.unsaltedSanitizedEmail,
          phone_hash_unsalted: funnelMeta?.unsaltedSanitizedPhone,
          sf_funnel_action_id: funnelActionId,
        });
      } catch (error) {
        analytics.track(BiEventsNames.WebFunnelError, {
          current_step_key: step,
          error_type: "book_slot",
          error_message:
            error instanceof Error ? error.message : "bookSlot unknown error",
          practice,
        });

        captureException(error, {
          extra: {
            action: "bookSlot",
          },
        });

        setFunnelMeta({
          ...funnelMeta,
          [CHECKOUT_STATUS]: "slot-is-taken",
        });

        triggerExternalFormSubmit();

        return;
      }
    }

    // Execute the payment
    try {
      await addPaymentMethodMutation({
        variables: {
          input: {
            opportunityId: leadId,
            setupIntentId: confirmCardSetup.setupIntent.id,
          },
        },
      });
    } catch (error) {
      analytics.track(BiEventsNames.WebFunnelError, {
        current_step_key: step,
        error_type: "add_payment",
        error_message:
          error instanceof Error ? error.message : "addPayment unknown error",
        practice,
      });

      setFunnelMeta({
        ...funnelMeta,
        [CHECKOUT_STATUS]: "payment-declined",
      });

      triggerExternalFormSubmit();

      return;
    }

    // Payment successful
    analytics.track(BiEventsNames.WebFunnelPaymentSuccess, {
      payment_type: meetingType.lss,
      sf_funnel_action_id: funnelActionId,
    });
    setFunnelMeta({
      ...funnelMeta,
      [CHECKOUT_STATUS]: "lss-confirmation",
    });

    triggerExternalFormSubmit();
  }

  if (!abTestHighIntent.isReady) return null;

  const IntakeLink = abTestHighIntent.isOn &&
    funnelAnswers.leadIntent === "high" && (
      <div className="-mx-5 flex items-center justify-between bg-neutral-50 p-6 md:rounded-2xl md:p-8">
        <div className="w-7/12 md:w-8/12 ">
          <Text variant="text-4" className="mb-2 font-medium md:text-xl">
            Have questions?
          </Text>
          <Text variant="text-5" className="mb-4 md:mb-6 md:text-base">
            Book an introductory call with our specialist to get answers and
            learn how we can support your needs.
          </Text>
          <SkipButton
            variant="outline"
            builderState={builderState}
            className="h-9 min-w-0 rounded-lg px-4 text-sm md:h-12 md:px-6 md:text-base"
          >
            Get in touch
          </SkipButton>
        </div>
        <div className="flex w-5/12 items-center justify-end md:w-4/12">
          <Image
            src={bannerImg}
            className="h-min w-[147px]"
            alt="Person holds phone"
          />
        </div>
      </div>
    );

  let nextStepsText = "Recommendations for your next steps";
  if (
    practice?.toUpperCase() === PracticeAreaName.Immigration &&
    funnelAnswers.subPractice
  ) {
    nextStepsText = `Next steps for your ${funnelAnswers.subPractice.replace("-", " ")} application`;
  }

  return (
    <>
      {isSubmittingPayment && <Interlude heading="Confirming your payment" />}
      <div className="flex flex-col gap-7">
        <Heading variant="h4" className="text-center font-normal">
          Payment details
        </Heading>
        <PriceInfoCard
          nextStepsText={nextStepsText}
          price={lssPrices[practice.toUpperCase() as LssPricesKeys]}
        />
        <div className="flex flex-col gap-5">
          <WalletPay
            clientSecret={clientSecret}
            leadId={leadId}
            triggerExternalFormSubmit={triggerExternalFormSubmit}
            setIsSubmittingPayment={setIsSubmittingPayment}
          />
          <Heading variant="h5" className="font-sans font-medium">
            Enter your credit card details
          </Heading>
          <TextField
            name={CARD_OWNER}
            label="Name on card"
            placeholder="Full name"
            validationErrorMessage="This field is required"
            type="text"
            onChange={() => {
              trigger(CARD_OWNER);
            }}
            required
          />
          <StripeComponent
            component={CardNumberElement}
            name={CARD_NUMBER}
            label="Credit or debit card"
            placeholder="Card number"
            onChange={() => {
              trigger(CARD_NUMBER);
            }}
          />
          <div className="flex flex-col gap-5 md:flex-row md:justify-between md:gap-3">
            <StripeComponent
              component={CardExpiryElement}
              name={CARD_EXPIRY}
              label="Expiration date"
              onChange={() => {
                trigger(CARD_EXPIRY);
              }}
            />
            <StripeComponent
              component={CardCvcElement}
              name={CARD_CVC}
              label="CVC"
              placeholder="123"
              onChange={() => {
                trigger(CARD_CVC);
              }}
            />
          </div>
          <TextField
            name={CARD_ZIP_CODE}
            label="Zip code"
            placeholder="Zip code"
            type="zipCode"
            validationErrorMessage="Enter a valid zip code"
            maxLength={5}
            onChange={(e) => {
              e.target.value = e.target.value.replace(/\D/g, "");
              trigger(CARD_ZIP_CODE);
            }}
            required
          />
        </div>
        <div className="flex items-center gap-2">
          <ShieldIcon height={18} width={16} />
          <Text className="font-medium" variant="text-6">
            SSL Encrypted, Secure Payment
          </Text>
        </div>
        <Text variant="text-6" className="text-center text-blue-40">
          By continuing, you accept our{" "}
          <Link target="_blank" href="/privacy" className="text-green-100">
            privacy policy &{" "}
          </Link>
          <Link target="_blank" href="/terms-of-use" className="text-green-100">
            terms of use.{" "}
          </Link>
          You also authorize Marble to electronically debit your account. You
          understand this authorization will remain in effect until you notify
          Marble in writing that you wish to revoke it. The revocation may take
          up to 10 business days to take effect.
        </Text>
        {IntakeLink}

        <StickyActions classNames="flex flex-col gap-4 justify-center items-center md:self-center md:w-screen md:mb-[-32px]">
          <Button
            onClick={handlePayment}
            disabled={isSubmittingPayment}
            className="w-full max-w-[450px] sm:max-w-[350px]"
          >
            Schedule your strategy session
          </Button>
        </StickyActions>

        <button ref={submitButtonRef} type="submit" className="hidden" hidden />
      </div>
    </>
  );
}
