import { jsxs as _, jsx as i } from "react/jsx-runtime";
import * as r from "react";
import { c as B, a as H, b as I } from "../../index-C5hYt6jn.js";
import { u as M } from "../../index-CCavb-1K.js";
import { u as j } from "../../index-BZPx6jYI.js";
import { u as z } from "../../index-CicxFo47.js";
import { P as C } from "../../index-u2zzFwMH.js";
import { cn as S } from "../../utils/cn.js";
import { focusRing as q } from "../../utils/focus-ring.js";
var v = "Switch", [A, Y] = B(v), [O, D] = A(v), y = r.forwardRef(
  (e, o) => {
    const {
      __scopeSwitch: t,
      name: a,
      checked: c,
      defaultChecked: u,
      required: d,
      disabled: s,
      value: l = "on",
      onCheckedChange: f,
      ...k
    } = e, [n, b] = r.useState(null), E = M(o, (h) => b(h)), m = r.useRef(!1), w = n ? !!n.closest("form") : !0, [p = !1, N] = H({
      prop: c,
      defaultProp: u,
      onChange: f
    });
    return /* @__PURE__ */ _(O, { scope: t, checked: p, disabled: s, children: [
      /* @__PURE__ */ i(
        C.button,
        {
          type: "button",
          role: "switch",
          "aria-checked": p,
          "aria-required": d,
          "data-state": x(p),
          "data-disabled": s ? "" : void 0,
          disabled: s,
          value: l,
          ...k,
          ref: E,
          onClick: I(e.onClick, (h) => {
            N((T) => !T), w && (m.current = h.isPropagationStopped(), m.current || h.stopPropagation());
          })
        }
      ),
      w && /* @__PURE__ */ i(
        F,
        {
          control: n,
          bubbles: !m.current,
          name: a,
          value: l,
          checked: p,
          required: d,
          disabled: s,
          style: { transform: "translateX(-100%)" }
        }
      )
    ] });
  }
);
y.displayName = v;
var g = "SwitchThumb", P = r.forwardRef(
  (e, o) => {
    const { __scopeSwitch: t, ...a } = e, c = D(g, t);
    return /* @__PURE__ */ i(
      C.span,
      {
        "data-state": x(c.checked),
        "data-disabled": c.disabled ? "" : void 0,
        ...a,
        ref: o
      }
    );
  }
);
P.displayName = g;
var F = (e) => {
  const { control: o, checked: t, bubbles: a = !0, ...c } = e, u = r.useRef(null), d = j(t), s = z(o);
  return r.useEffect(() => {
    const l = u.current, f = window.HTMLInputElement.prototype, n = Object.getOwnPropertyDescriptor(f, "checked").set;
    if (d !== t && n) {
      const b = new Event("click", { bubbles: a });
      n.call(l, t), l.dispatchEvent(b);
    }
  }, [d, t, a]), /* @__PURE__ */ i(
    "input",
    {
      type: "checkbox",
      "aria-hidden": !0,
      defaultChecked: t,
      ...c,
      tabIndex: -1,
      ref: u,
      style: {
        ...e.style,
        ...s,
        position: "absolute",
        pointerEvents: "none",
        opacity: 0,
        margin: 0
      }
    }
  );
};
function x(e) {
  return e ? "checked" : "unchecked";
}
var R = y, L = P;
const U = r.forwardRef(({ className: e, ...o }, t) => /* @__PURE__ */ i(
  R,
  {
    className: S(
      q,
      "inline-flex h-s-3 w-[calc(var(--fui-baseline)+var(--fui-subline)*3)] shrink-0 cursor-pointer items-center rounded-full border-[calc(var(--fui-subline)/2)] border-transparent bg-action-secondary-0 hover:bg-action-secondary-1 disabled:pointer-events-none disabled:bg-action-disable-1 data-[state=checked]:bg-action-primary-0 data-[state=checked]:hover:bg-action-primary-0-1 disabled:data-[state=checked]:bg-action-disable-1",
      e
    ),
    ...o,
    ref: t,
    children: /* @__PURE__ */ i(
      L,
      {
        className: S(
          "pointer-events-none block size-[calc(var(--fui-subline)*4)] rounded-full bg-action-secondary-6 transition-transform data-[state=checked]:translate-x-sp-4 data-[state=unchecked]:translate-x-[0]"
        )
      }
    )
  }
));
U.displayName = R.displayName;
export {
  U as Switch
};
