import { useEffect, useState } from "react";
import type { BuilderStore } from "@builder.io/react";
import { Builder } from "@builder.io/sdk";
import { BookitProvider } from "@marbletech/bookit-core";
import { captureException, captureMessage } from "@sentry/nextjs";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useRouter } from "next/router";
import { useSessionStorage } from "usehooks-ts";

import { analytics } from "@flare/analytics";

import { FUNNEL_LSS_CHECKOUT } from "@/builder/components-sections";
import type { RegisterStripeCustomerInput } from "@/generated/graphql.old_backend";
import {
  useGetLeadIdByFunnelActionIdLazyQuery,
  useRegisterStripeCustomerMutation,
} from "@/generated/graphql.old_backend";
import type { CreateBookitRequestPayload } from "@/generated/graphql.vinny";
import {
  BookeeMeetingType,
  PracticeAreaName,
  useCreateBookitRequestMutation,
} from "@/generated/graphql.vinny";
import { useFunnelAnswers } from "@/modules/v2/funnel/hooks/useFunnelAnswers";
import {
  isQualifiedInternalEmail,
  testCounty,
  testStateCode,
} from "@/modules/v2/funnel/visitor-checks/checkQualifiedInternalEmail";
import { BiEventsNames } from "@/services/analytics/event-names";

import { getCompetencies } from "../utils/get-competencies";
import { getFips } from "../utils/get-fips";

import { CheckoutForm } from "./CheckoutForm";
import { FormSkeleton } from "./FormSkeleton";

const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_KEY || "");

const bookitBaseUrl = process.env.NEXT_PUBLIC_BOOKIT_BASE_URL || "";

export function LssCheckout({ builderState }: { builderState: BuilderStore }) {
  const { query } = useRouter();
  const [clientSecret, setClientSecret] = useState("");
  const [leadId, setLeadId] = useState("");
  const { funnelAnswers } = useFunnelAnswers();
  const {
    funnelActionId,
    firstName,
    lastName,
    email,
    phone,
    stateCode,
    county,
    subPractice,
    serviceType,
    sponsorType,
  } = funnelAnswers || {};

  const practice = query.practice as string;
  const step = query.step as string;

  const [getLeadIdByFunnelActionId] = useGetLeadIdByFunnelActionIdLazyQuery();
  const [registerStripeCustomerMutation] = useRegisterStripeCustomerMutation();
  const [createBookitRequestMutation] = useCreateBookitRequestMutation();
  const [bookitRequestId, setBookitRequestId] = useSessionStorage<
    string | null
  >("bookitRequestId", null);

  useEffect(() => {
    if (!funnelActionId) {
      analytics.track(BiEventsNames.WebFunnelError, {
        current_step_key: step,
        error_type: "no_funnel_action_id_provided",
        practice,
      });
      captureMessage("No funnel action ID provided", {
        extra: { step: "lss-checkout" },
      });
    }
  }, [funnelActionId, practice, step]);

  useEffect(() => {
    async function fetchLeadIdAndRegisterCustomer() {
      if (!funnelActionId) return;

      try {
        const { data: leadData } = await getLeadIdByFunnelActionId({
          variables: { funnelActionId },
        });

        const leadId = leadData?.leadIdByFunnelActionId?.id;

        if (!leadId) {
          analytics.track(BiEventsNames.WebFunnelError, {
            current_step_key: step,
            error_type: "get_lead_id_by_funnel_action_id",
            practice,
          });
          throw new Error(`Failed to fetch lead ID`);
        }

        setLeadId(leadId);

        const payload: RegisterStripeCustomerInput = {
          customerDetails: {
            name: `${firstName} ${lastName}`,
            email,
            phone,
          },
          description: { practiceArea: practice },
          metadata: {
            deal_id: leadId,
          },
        };

        const { data: stripeCustomerData } =
          await registerStripeCustomerMutation({
            variables: { input: payload },
          });

        const clientSecret =
          stripeCustomerData?.registerStripeCustomer?.clientSecret;

        if (!clientSecret) {
          analytics.track(BiEventsNames.WebFunnelError, {
            current_step_key: step,
            error_type: "register_stripe_customer",
            practice,
          });
          throw new Error(`Failed to register Stripe customer`);
        }

        setClientSecret(clientSecret);
      } catch (error) {
        captureException(error, {
          extra: {
            action: "fetchLeadIdAndRegisterCustomer",
          },
        });
      }
    }

    fetchLeadIdAndRegisterCustomer();
  }, [
    email,
    firstName,
    funnelActionId,
    getLeadIdByFunnelActionId,
    lastName,
    phone,
    practice,
    step,
    registerStripeCustomerMutation,
  ]);

  useEffect(() => {
    async function fetchBookitRequestId() {
      if (!funnelActionId) return;

      const practiceArea: PracticeAreaName =
        practice?.toUpperCase() === PracticeAreaName.Immigration
          ? PracticeAreaName.Immigration
          : PracticeAreaName.Family;

      const [actualStateCode, actualCounty] = isQualifiedInternalEmail(
        email,
        "dlss",
      )
        ? [testStateCode, testCounty]
        : [stateCode, county];

      const fips = getFips({
        county: actualCounty,
        stateCode: actualStateCode,
      });

      const competencies = getCompetencies({
        practice: practiceArea,
        subPractice,
        serviceType,
        sponsorType,
      });

      const bookitRequestPayload: CreateBookitRequestPayload = {
        practiceArea,
        meetingType: BookeeMeetingType.Lss,
        customerEmail: email ?? "",
        customerFirstName: firstName ?? "",
        customerLastName: lastName ?? "",
        competencies,
        metadata: {
          salesforce_id: funnelActionId,
          source: "funnel",
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      };

      if (practiceArea === PracticeAreaName.Family) {
        bookitRequestPayload.stateCode = actualStateCode;
        bookitRequestPayload.fips = fips;
      }

      await createBookitRequestMutation({
        variables: { payload: bookitRequestPayload },
        onCompleted: (data) => {
          const newRequestId = data?.createBookitRequest?.id || null;
          setBookitRequestId(newRequestId);
        },
        onError: (error) => {
          analytics.track(BiEventsNames.WebFunnelError, {
            current_step_key: step,
            error_type: "create_bookit_request",
            error_message: error.message,
            practice,
          });
        },
      });
    }

    if (!bookitRequestId) {
      fetchBookitRequestId();
    }
  }, [
    bookitRequestId,
    county,
    createBookitRequestMutation,
    email,
    firstName,
    funnelActionId,
    lastName,
    practice,
    setBookitRequestId,
    stateCode,
    step,
    subPractice,
    serviceType,
    sponsorType,
  ]);

  const options = { clientSecret };

  return clientSecret && bookitRequestId ? (
    <Elements stripe={stripePromise} options={options}>
      <BookitProvider baseUrl={bookitBaseUrl} meetingId={bookitRequestId}>
        <CheckoutForm
          clientSecret={clientSecret}
          leadId={leadId}
          builderState={builderState}
        />
      </BookitProvider>
    </Elements>
  ) : (
    <FormSkeleton />
  );
}

Builder.registerComponent(LssCheckout, {
  name: FUNNEL_LSS_CHECKOUT,
  inputs: [],
});
