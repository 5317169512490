import { competenciesMap } from "@/consts/competencies";
import { PracticeAreaName } from "@/generated/graphql.vinny";

import { getImmigrationCompetenciesKey } from "./get-competencies-key";

export function getCompetencies({
  practice,
  subPractice,
  serviceType,
  sponsorType,
}: {
  practice?: string;
  serviceType?: string;
  sponsorType?: string;
  subPractice?: string;
}): string[] {
  if (!practice || !subPractice) {
    return [];
  }

  const competencies = competenciesMap[practice.toLowerCase()]?.[subPractice];
  if (Array.isArray(competencies)) {
    return competencies;
  }

  if (practice?.toUpperCase() !== PracticeAreaName.Immigration) {
    return [];
  }

  const competenciesKey = getImmigrationCompetenciesKey({
    practice,
    serviceType,
    sponsorType,
  });

  return competencies?.[competenciesKey] || [];
}
