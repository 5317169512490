function g(o) {
  return o && o.__esModule && Object.prototype.hasOwnProperty.call(o, "default") ? o.default : o;
}
var u = {}, s = {};
(function(o) {
  Object.defineProperty(o, "__esModule", {
    value: !0
  }), Object.defineProperty(o, "cloneDeep", {
    enumerable: !0,
    get: function() {
      return r;
    }
  });
  function r(a) {
    return Array.isArray(a) ? a.map((i) => r(i)) : typeof a == "object" && a !== null ? Object.fromEntries(Object.entries(a).map(([i, e]) => [
      i,
      r(e)
    ])) : a;
  }
})(s);
var m = {
  content: [],
  presets: [],
  darkMode: "media",
  // or 'class'
  theme: {
    accentColor: ({ theme: o }) => ({
      ...o("colors"),
      auto: "auto"
    }),
    animation: {
      none: "none",
      spin: "spin 1s linear infinite",
      ping: "ping 1s cubic-bezier(0, 0, 0.2, 1) infinite",
      pulse: "pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite",
      bounce: "bounce 1s infinite"
    },
    aria: {
      busy: 'busy="true"',
      checked: 'checked="true"',
      disabled: 'disabled="true"',
      expanded: 'expanded="true"',
      hidden: 'hidden="true"',
      pressed: 'pressed="true"',
      readonly: 'readonly="true"',
      required: 'required="true"',
      selected: 'selected="true"'
    },
    aspectRatio: {
      auto: "auto",
      square: "1 / 1",
      video: "16 / 9"
    },
    backdropBlur: ({ theme: o }) => o("blur"),
    backdropBrightness: ({ theme: o }) => o("brightness"),
    backdropContrast: ({ theme: o }) => o("contrast"),
    backdropGrayscale: ({ theme: o }) => o("grayscale"),
    backdropHueRotate: ({ theme: o }) => o("hueRotate"),
    backdropInvert: ({ theme: o }) => o("invert"),
    backdropOpacity: ({ theme: o }) => o("opacity"),
    backdropSaturate: ({ theme: o }) => o("saturate"),
    backdropSepia: ({ theme: o }) => o("sepia"),
    backgroundColor: ({ theme: o }) => o("colors"),
    backgroundImage: {
      none: "none",
      "gradient-to-t": "linear-gradient(to top, var(--tw-gradient-stops))",
      "gradient-to-tr": "linear-gradient(to top right, var(--tw-gradient-stops))",
      "gradient-to-r": "linear-gradient(to right, var(--tw-gradient-stops))",
      "gradient-to-br": "linear-gradient(to bottom right, var(--tw-gradient-stops))",
      "gradient-to-b": "linear-gradient(to bottom, var(--tw-gradient-stops))",
      "gradient-to-bl": "linear-gradient(to bottom left, var(--tw-gradient-stops))",
      "gradient-to-l": "linear-gradient(to left, var(--tw-gradient-stops))",
      "gradient-to-tl": "linear-gradient(to top left, var(--tw-gradient-stops))"
    },
    backgroundOpacity: ({ theme: o }) => o("opacity"),
    backgroundPosition: {
      bottom: "bottom",
      center: "center",
      left: "left",
      "left-bottom": "left bottom",
      "left-top": "left top",
      right: "right",
      "right-bottom": "right bottom",
      "right-top": "right top",
      top: "top"
    },
    backgroundSize: {
      auto: "auto",
      cover: "cover",
      contain: "contain"
    },
    blur: {
      0: "0",
      none: "",
      sm: "4px",
      DEFAULT: "8px",
      md: "12px",
      lg: "16px",
      xl: "24px",
      "2xl": "40px",
      "3xl": "64px"
    },
    borderColor: ({ theme: o }) => ({
      ...o("colors"),
      DEFAULT: o("colors.gray.200", "currentColor")
    }),
    borderOpacity: ({ theme: o }) => o("opacity"),
    borderRadius: {
      none: "0px",
      sm: "0.125rem",
      DEFAULT: "0.25rem",
      md: "0.375rem",
      lg: "0.5rem",
      xl: "0.75rem",
      "2xl": "1rem",
      "3xl": "1.5rem",
      full: "9999px"
    },
    borderSpacing: ({ theme: o }) => ({
      ...o("spacing")
    }),
    borderWidth: {
      DEFAULT: "1px",
      0: "0px",
      2: "2px",
      4: "4px",
      8: "8px"
    },
    boxShadow: {
      sm: "0 1px 2px 0 rgb(0 0 0 / 0.05)",
      DEFAULT: "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
      md: "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
      lg: "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
      xl: "0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
      "2xl": "0 25px 50px -12px rgb(0 0 0 / 0.25)",
      inner: "inset 0 2px 4px 0 rgb(0 0 0 / 0.05)",
      none: "none"
    },
    boxShadowColor: ({ theme: o }) => o("colors"),
    brightness: {
      0: "0",
      50: ".5",
      75: ".75",
      90: ".9",
      95: ".95",
      100: "1",
      105: "1.05",
      110: "1.1",
      125: "1.25",
      150: "1.5",
      200: "2"
    },
    caretColor: ({ theme: o }) => o("colors"),
    colors: ({ colors: o }) => ({
      inherit: o.inherit,
      current: o.current,
      transparent: o.transparent,
      black: o.black,
      white: o.white,
      slate: o.slate,
      gray: o.gray,
      zinc: o.zinc,
      neutral: o.neutral,
      stone: o.stone,
      red: o.red,
      orange: o.orange,
      amber: o.amber,
      yellow: o.yellow,
      lime: o.lime,
      green: o.green,
      emerald: o.emerald,
      teal: o.teal,
      cyan: o.cyan,
      sky: o.sky,
      blue: o.blue,
      indigo: o.indigo,
      violet: o.violet,
      purple: o.purple,
      fuchsia: o.fuchsia,
      pink: o.pink,
      rose: o.rose
    }),
    columns: {
      auto: "auto",
      1: "1",
      2: "2",
      3: "3",
      4: "4",
      5: "5",
      6: "6",
      7: "7",
      8: "8",
      9: "9",
      10: "10",
      11: "11",
      12: "12",
      "3xs": "16rem",
      "2xs": "18rem",
      xs: "20rem",
      sm: "24rem",
      md: "28rem",
      lg: "32rem",
      xl: "36rem",
      "2xl": "42rem",
      "3xl": "48rem",
      "4xl": "56rem",
      "5xl": "64rem",
      "6xl": "72rem",
      "7xl": "80rem"
    },
    container: {},
    content: {
      none: "none"
    },
    contrast: {
      0: "0",
      50: ".5",
      75: ".75",
      100: "1",
      125: "1.25",
      150: "1.5",
      200: "2"
    },
    cursor: {
      auto: "auto",
      default: "default",
      pointer: "pointer",
      wait: "wait",
      text: "text",
      move: "move",
      help: "help",
      "not-allowed": "not-allowed",
      none: "none",
      "context-menu": "context-menu",
      progress: "progress",
      cell: "cell",
      crosshair: "crosshair",
      "vertical-text": "vertical-text",
      alias: "alias",
      copy: "copy",
      "no-drop": "no-drop",
      grab: "grab",
      grabbing: "grabbing",
      "all-scroll": "all-scroll",
      "col-resize": "col-resize",
      "row-resize": "row-resize",
      "n-resize": "n-resize",
      "e-resize": "e-resize",
      "s-resize": "s-resize",
      "w-resize": "w-resize",
      "ne-resize": "ne-resize",
      "nw-resize": "nw-resize",
      "se-resize": "se-resize",
      "sw-resize": "sw-resize",
      "ew-resize": "ew-resize",
      "ns-resize": "ns-resize",
      "nesw-resize": "nesw-resize",
      "nwse-resize": "nwse-resize",
      "zoom-in": "zoom-in",
      "zoom-out": "zoom-out"
    },
    divideColor: ({ theme: o }) => o("borderColor"),
    divideOpacity: ({ theme: o }) => o("borderOpacity"),
    divideWidth: ({ theme: o }) => o("borderWidth"),
    dropShadow: {
      sm: "0 1px 1px rgb(0 0 0 / 0.05)",
      DEFAULT: ["0 1px 2px rgb(0 0 0 / 0.1)", "0 1px 1px rgb(0 0 0 / 0.06)"],
      md: ["0 4px 3px rgb(0 0 0 / 0.07)", "0 2px 2px rgb(0 0 0 / 0.06)"],
      lg: ["0 10px 8px rgb(0 0 0 / 0.04)", "0 4px 3px rgb(0 0 0 / 0.1)"],
      xl: ["0 20px 13px rgb(0 0 0 / 0.03)", "0 8px 5px rgb(0 0 0 / 0.08)"],
      "2xl": "0 25px 25px rgb(0 0 0 / 0.15)",
      none: "0 0 #0000"
    },
    fill: ({ theme: o }) => ({
      none: "none",
      ...o("colors")
    }),
    flex: {
      1: "1 1 0%",
      auto: "1 1 auto",
      initial: "0 1 auto",
      none: "none"
    },
    flexBasis: ({ theme: o }) => ({
      auto: "auto",
      ...o("spacing"),
      "1/2": "50%",
      "1/3": "33.333333%",
      "2/3": "66.666667%",
      "1/4": "25%",
      "2/4": "50%",
      "3/4": "75%",
      "1/5": "20%",
      "2/5": "40%",
      "3/5": "60%",
      "4/5": "80%",
      "1/6": "16.666667%",
      "2/6": "33.333333%",
      "3/6": "50%",
      "4/6": "66.666667%",
      "5/6": "83.333333%",
      "1/12": "8.333333%",
      "2/12": "16.666667%",
      "3/12": "25%",
      "4/12": "33.333333%",
      "5/12": "41.666667%",
      "6/12": "50%",
      "7/12": "58.333333%",
      "8/12": "66.666667%",
      "9/12": "75%",
      "10/12": "83.333333%",
      "11/12": "91.666667%",
      full: "100%"
    }),
    flexGrow: {
      0: "0",
      DEFAULT: "1"
    },
    flexShrink: {
      0: "0",
      DEFAULT: "1"
    },
    fontFamily: {
      sans: [
        "ui-sans-serif",
        "system-ui",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
        '"Noto Color Emoji"'
      ],
      serif: ["ui-serif", "Georgia", "Cambria", '"Times New Roman"', "Times", "serif"],
      mono: [
        "ui-monospace",
        "SFMono-Regular",
        "Menlo",
        "Monaco",
        "Consolas",
        '"Liberation Mono"',
        '"Courier New"',
        "monospace"
      ]
    },
    fontSize: {
      xs: ["0.75rem", { lineHeight: "1rem" }],
      sm: ["0.875rem", { lineHeight: "1.25rem" }],
      base: ["1rem", { lineHeight: "1.5rem" }],
      lg: ["1.125rem", { lineHeight: "1.75rem" }],
      xl: ["1.25rem", { lineHeight: "1.75rem" }],
      "2xl": ["1.5rem", { lineHeight: "2rem" }],
      "3xl": ["1.875rem", { lineHeight: "2.25rem" }],
      "4xl": ["2.25rem", { lineHeight: "2.5rem" }],
      "5xl": ["3rem", { lineHeight: "1" }],
      "6xl": ["3.75rem", { lineHeight: "1" }],
      "7xl": ["4.5rem", { lineHeight: "1" }],
      "8xl": ["6rem", { lineHeight: "1" }],
      "9xl": ["8rem", { lineHeight: "1" }]
    },
    fontWeight: {
      thin: "100",
      extralight: "200",
      light: "300",
      normal: "400",
      medium: "500",
      semibold: "600",
      bold: "700",
      extrabold: "800",
      black: "900"
    },
    gap: ({ theme: o }) => o("spacing"),
    gradientColorStops: ({ theme: o }) => o("colors"),
    gradientColorStopPositions: {
      "0%": "0%",
      "5%": "5%",
      "10%": "10%",
      "15%": "15%",
      "20%": "20%",
      "25%": "25%",
      "30%": "30%",
      "35%": "35%",
      "40%": "40%",
      "45%": "45%",
      "50%": "50%",
      "55%": "55%",
      "60%": "60%",
      "65%": "65%",
      "70%": "70%",
      "75%": "75%",
      "80%": "80%",
      "85%": "85%",
      "90%": "90%",
      "95%": "95%",
      "100%": "100%"
    },
    grayscale: {
      0: "0",
      DEFAULT: "100%"
    },
    gridAutoColumns: {
      auto: "auto",
      min: "min-content",
      max: "max-content",
      fr: "minmax(0, 1fr)"
    },
    gridAutoRows: {
      auto: "auto",
      min: "min-content",
      max: "max-content",
      fr: "minmax(0, 1fr)"
    },
    gridColumn: {
      auto: "auto",
      "span-1": "span 1 / span 1",
      "span-2": "span 2 / span 2",
      "span-3": "span 3 / span 3",
      "span-4": "span 4 / span 4",
      "span-5": "span 5 / span 5",
      "span-6": "span 6 / span 6",
      "span-7": "span 7 / span 7",
      "span-8": "span 8 / span 8",
      "span-9": "span 9 / span 9",
      "span-10": "span 10 / span 10",
      "span-11": "span 11 / span 11",
      "span-12": "span 12 / span 12",
      "span-full": "1 / -1"
    },
    gridColumnEnd: {
      auto: "auto",
      1: "1",
      2: "2",
      3: "3",
      4: "4",
      5: "5",
      6: "6",
      7: "7",
      8: "8",
      9: "9",
      10: "10",
      11: "11",
      12: "12",
      13: "13"
    },
    gridColumnStart: {
      auto: "auto",
      1: "1",
      2: "2",
      3: "3",
      4: "4",
      5: "5",
      6: "6",
      7: "7",
      8: "8",
      9: "9",
      10: "10",
      11: "11",
      12: "12",
      13: "13"
    },
    gridRow: {
      auto: "auto",
      "span-1": "span 1 / span 1",
      "span-2": "span 2 / span 2",
      "span-3": "span 3 / span 3",
      "span-4": "span 4 / span 4",
      "span-5": "span 5 / span 5",
      "span-6": "span 6 / span 6",
      "span-7": "span 7 / span 7",
      "span-8": "span 8 / span 8",
      "span-9": "span 9 / span 9",
      "span-10": "span 10 / span 10",
      "span-11": "span 11 / span 11",
      "span-12": "span 12 / span 12",
      "span-full": "1 / -1"
    },
    gridRowEnd: {
      auto: "auto",
      1: "1",
      2: "2",
      3: "3",
      4: "4",
      5: "5",
      6: "6",
      7: "7",
      8: "8",
      9: "9",
      10: "10",
      11: "11",
      12: "12",
      13: "13"
    },
    gridRowStart: {
      auto: "auto",
      1: "1",
      2: "2",
      3: "3",
      4: "4",
      5: "5",
      6: "6",
      7: "7",
      8: "8",
      9: "9",
      10: "10",
      11: "11",
      12: "12",
      13: "13"
    },
    gridTemplateColumns: {
      none: "none",
      subgrid: "subgrid",
      1: "repeat(1, minmax(0, 1fr))",
      2: "repeat(2, minmax(0, 1fr))",
      3: "repeat(3, minmax(0, 1fr))",
      4: "repeat(4, minmax(0, 1fr))",
      5: "repeat(5, minmax(0, 1fr))",
      6: "repeat(6, minmax(0, 1fr))",
      7: "repeat(7, minmax(0, 1fr))",
      8: "repeat(8, minmax(0, 1fr))",
      9: "repeat(9, minmax(0, 1fr))",
      10: "repeat(10, minmax(0, 1fr))",
      11: "repeat(11, minmax(0, 1fr))",
      12: "repeat(12, minmax(0, 1fr))"
    },
    gridTemplateRows: {
      none: "none",
      subgrid: "subgrid",
      1: "repeat(1, minmax(0, 1fr))",
      2: "repeat(2, minmax(0, 1fr))",
      3: "repeat(3, minmax(0, 1fr))",
      4: "repeat(4, minmax(0, 1fr))",
      5: "repeat(5, minmax(0, 1fr))",
      6: "repeat(6, minmax(0, 1fr))",
      7: "repeat(7, minmax(0, 1fr))",
      8: "repeat(8, minmax(0, 1fr))",
      9: "repeat(9, minmax(0, 1fr))",
      10: "repeat(10, minmax(0, 1fr))",
      11: "repeat(11, minmax(0, 1fr))",
      12: "repeat(12, minmax(0, 1fr))"
    },
    height: ({ theme: o }) => ({
      auto: "auto",
      ...o("spacing"),
      "1/2": "50%",
      "1/3": "33.333333%",
      "2/3": "66.666667%",
      "1/4": "25%",
      "2/4": "50%",
      "3/4": "75%",
      "1/5": "20%",
      "2/5": "40%",
      "3/5": "60%",
      "4/5": "80%",
      "1/6": "16.666667%",
      "2/6": "33.333333%",
      "3/6": "50%",
      "4/6": "66.666667%",
      "5/6": "83.333333%",
      full: "100%",
      screen: "100vh",
      svh: "100svh",
      lvh: "100lvh",
      dvh: "100dvh",
      min: "min-content",
      max: "max-content",
      fit: "fit-content"
    }),
    hueRotate: {
      0: "0deg",
      15: "15deg",
      30: "30deg",
      60: "60deg",
      90: "90deg",
      180: "180deg"
    },
    inset: ({ theme: o }) => ({
      auto: "auto",
      ...o("spacing"),
      "1/2": "50%",
      "1/3": "33.333333%",
      "2/3": "66.666667%",
      "1/4": "25%",
      "2/4": "50%",
      "3/4": "75%",
      full: "100%"
    }),
    invert: {
      0: "0",
      DEFAULT: "100%"
    },
    keyframes: {
      spin: {
        to: {
          transform: "rotate(360deg)"
        }
      },
      ping: {
        "75%, 100%": {
          transform: "scale(2)",
          opacity: "0"
        }
      },
      pulse: {
        "50%": {
          opacity: ".5"
        }
      },
      bounce: {
        "0%, 100%": {
          transform: "translateY(-25%)",
          animationTimingFunction: "cubic-bezier(0.8,0,1,1)"
        },
        "50%": {
          transform: "none",
          animationTimingFunction: "cubic-bezier(0,0,0.2,1)"
        }
      }
    },
    letterSpacing: {
      tighter: "-0.05em",
      tight: "-0.025em",
      normal: "0em",
      wide: "0.025em",
      wider: "0.05em",
      widest: "0.1em"
    },
    lineHeight: {
      none: "1",
      tight: "1.25",
      snug: "1.375",
      normal: "1.5",
      relaxed: "1.625",
      loose: "2",
      3: ".75rem",
      4: "1rem",
      5: "1.25rem",
      6: "1.5rem",
      7: "1.75rem",
      8: "2rem",
      9: "2.25rem",
      10: "2.5rem"
    },
    listStyleType: {
      none: "none",
      disc: "disc",
      decimal: "decimal"
    },
    listStyleImage: {
      none: "none"
    },
    margin: ({ theme: o }) => ({
      auto: "auto",
      ...o("spacing")
    }),
    lineClamp: {
      1: "1",
      2: "2",
      3: "3",
      4: "4",
      5: "5",
      6: "6"
    },
    maxHeight: ({ theme: o }) => ({
      ...o("spacing"),
      none: "none",
      full: "100%",
      screen: "100vh",
      svh: "100svh",
      lvh: "100lvh",
      dvh: "100dvh",
      min: "min-content",
      max: "max-content",
      fit: "fit-content"
    }),
    maxWidth: ({ theme: o, breakpoints: r }) => ({
      ...o("spacing"),
      none: "none",
      xs: "20rem",
      sm: "24rem",
      md: "28rem",
      lg: "32rem",
      xl: "36rem",
      "2xl": "42rem",
      "3xl": "48rem",
      "4xl": "56rem",
      "5xl": "64rem",
      "6xl": "72rem",
      "7xl": "80rem",
      full: "100%",
      min: "min-content",
      max: "max-content",
      fit: "fit-content",
      prose: "65ch",
      ...r(o("screens"))
    }),
    minHeight: ({ theme: o }) => ({
      ...o("spacing"),
      full: "100%",
      screen: "100vh",
      svh: "100svh",
      lvh: "100lvh",
      dvh: "100dvh",
      min: "min-content",
      max: "max-content",
      fit: "fit-content"
    }),
    minWidth: ({ theme: o }) => ({
      ...o("spacing"),
      full: "100%",
      min: "min-content",
      max: "max-content",
      fit: "fit-content"
    }),
    objectPosition: {
      bottom: "bottom",
      center: "center",
      left: "left",
      "left-bottom": "left bottom",
      "left-top": "left top",
      right: "right",
      "right-bottom": "right bottom",
      "right-top": "right top",
      top: "top"
    },
    opacity: {
      0: "0",
      5: "0.05",
      10: "0.1",
      15: "0.15",
      20: "0.2",
      25: "0.25",
      30: "0.3",
      35: "0.35",
      40: "0.4",
      45: "0.45",
      50: "0.5",
      55: "0.55",
      60: "0.6",
      65: "0.65",
      70: "0.7",
      75: "0.75",
      80: "0.8",
      85: "0.85",
      90: "0.9",
      95: "0.95",
      100: "1"
    },
    order: {
      first: "-9999",
      last: "9999",
      none: "0",
      1: "1",
      2: "2",
      3: "3",
      4: "4",
      5: "5",
      6: "6",
      7: "7",
      8: "8",
      9: "9",
      10: "10",
      11: "11",
      12: "12"
    },
    outlineColor: ({ theme: o }) => o("colors"),
    outlineOffset: {
      0: "0px",
      1: "1px",
      2: "2px",
      4: "4px",
      8: "8px"
    },
    outlineWidth: {
      0: "0px",
      1: "1px",
      2: "2px",
      4: "4px",
      8: "8px"
    },
    padding: ({ theme: o }) => o("spacing"),
    placeholderColor: ({ theme: o }) => o("colors"),
    placeholderOpacity: ({ theme: o }) => o("opacity"),
    ringColor: ({ theme: o }) => ({
      DEFAULT: o("colors.blue.500", "#3b82f6"),
      ...o("colors")
    }),
    ringOffsetColor: ({ theme: o }) => o("colors"),
    ringOffsetWidth: {
      0: "0px",
      1: "1px",
      2: "2px",
      4: "4px",
      8: "8px"
    },
    ringOpacity: ({ theme: o }) => ({
      DEFAULT: "0.5",
      ...o("opacity")
    }),
    ringWidth: {
      DEFAULT: "3px",
      0: "0px",
      1: "1px",
      2: "2px",
      4: "4px",
      8: "8px"
    },
    rotate: {
      0: "0deg",
      1: "1deg",
      2: "2deg",
      3: "3deg",
      6: "6deg",
      12: "12deg",
      45: "45deg",
      90: "90deg",
      180: "180deg"
    },
    saturate: {
      0: "0",
      50: ".5",
      100: "1",
      150: "1.5",
      200: "2"
    },
    scale: {
      0: "0",
      50: ".5",
      75: ".75",
      90: ".9",
      95: ".95",
      100: "1",
      105: "1.05",
      110: "1.1",
      125: "1.25",
      150: "1.5"
    },
    screens: {
      sm: "640px",
      md: "768px",
      lg: "1024px",
      xl: "1280px",
      "2xl": "1536px"
    },
    scrollMargin: ({ theme: o }) => ({
      ...o("spacing")
    }),
    scrollPadding: ({ theme: o }) => o("spacing"),
    sepia: {
      0: "0",
      DEFAULT: "100%"
    },
    skew: {
      0: "0deg",
      1: "1deg",
      2: "2deg",
      3: "3deg",
      6: "6deg",
      12: "12deg"
    },
    space: ({ theme: o }) => ({
      ...o("spacing")
    }),
    spacing: {
      px: "1px",
      0: "0px",
      0.5: "0.125rem",
      1: "0.25rem",
      1.5: "0.375rem",
      2: "0.5rem",
      2.5: "0.625rem",
      3: "0.75rem",
      3.5: "0.875rem",
      4: "1rem",
      5: "1.25rem",
      6: "1.5rem",
      7: "1.75rem",
      8: "2rem",
      9: "2.25rem",
      10: "2.5rem",
      11: "2.75rem",
      12: "3rem",
      14: "3.5rem",
      16: "4rem",
      20: "5rem",
      24: "6rem",
      28: "7rem",
      32: "8rem",
      36: "9rem",
      40: "10rem",
      44: "11rem",
      48: "12rem",
      52: "13rem",
      56: "14rem",
      60: "15rem",
      64: "16rem",
      72: "18rem",
      80: "20rem",
      96: "24rem"
    },
    stroke: ({ theme: o }) => ({
      none: "none",
      ...o("colors")
    }),
    strokeWidth: {
      0: "0",
      1: "1",
      2: "2"
    },
    supports: {},
    data: {},
    textColor: ({ theme: o }) => o("colors"),
    textDecorationColor: ({ theme: o }) => o("colors"),
    textDecorationThickness: {
      auto: "auto",
      "from-font": "from-font",
      0: "0px",
      1: "1px",
      2: "2px",
      4: "4px",
      8: "8px"
    },
    textIndent: ({ theme: o }) => ({
      ...o("spacing")
    }),
    textOpacity: ({ theme: o }) => o("opacity"),
    textUnderlineOffset: {
      auto: "auto",
      0: "0px",
      1: "1px",
      2: "2px",
      4: "4px",
      8: "8px"
    },
    transformOrigin: {
      center: "center",
      top: "top",
      "top-right": "top right",
      right: "right",
      "bottom-right": "bottom right",
      bottom: "bottom",
      "bottom-left": "bottom left",
      left: "left",
      "top-left": "top left"
    },
    transitionDelay: {
      0: "0s",
      75: "75ms",
      100: "100ms",
      150: "150ms",
      200: "200ms",
      300: "300ms",
      500: "500ms",
      700: "700ms",
      1e3: "1000ms"
    },
    transitionDuration: {
      DEFAULT: "150ms",
      0: "0s",
      75: "75ms",
      100: "100ms",
      150: "150ms",
      200: "200ms",
      300: "300ms",
      500: "500ms",
      700: "700ms",
      1e3: "1000ms"
    },
    transitionProperty: {
      none: "none",
      all: "all",
      DEFAULT: "color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter",
      colors: "color, background-color, border-color, text-decoration-color, fill, stroke",
      opacity: "opacity",
      shadow: "box-shadow",
      transform: "transform"
    },
    transitionTimingFunction: {
      DEFAULT: "cubic-bezier(0.4, 0, 0.2, 1)",
      linear: "linear",
      in: "cubic-bezier(0.4, 0, 1, 1)",
      out: "cubic-bezier(0, 0, 0.2, 1)",
      "in-out": "cubic-bezier(0.4, 0, 0.2, 1)"
    },
    translate: ({ theme: o }) => ({
      ...o("spacing"),
      "1/2": "50%",
      "1/3": "33.333333%",
      "2/3": "66.666667%",
      "1/4": "25%",
      "2/4": "50%",
      "3/4": "75%",
      full: "100%"
    }),
    size: ({ theme: o }) => ({
      auto: "auto",
      ...o("spacing"),
      "1/2": "50%",
      "1/3": "33.333333%",
      "2/3": "66.666667%",
      "1/4": "25%",
      "2/4": "50%",
      "3/4": "75%",
      "1/5": "20%",
      "2/5": "40%",
      "3/5": "60%",
      "4/5": "80%",
      "1/6": "16.666667%",
      "2/6": "33.333333%",
      "3/6": "50%",
      "4/6": "66.666667%",
      "5/6": "83.333333%",
      "1/12": "8.333333%",
      "2/12": "16.666667%",
      "3/12": "25%",
      "4/12": "33.333333%",
      "5/12": "41.666667%",
      "6/12": "50%",
      "7/12": "58.333333%",
      "8/12": "66.666667%",
      "9/12": "75%",
      "10/12": "83.333333%",
      "11/12": "91.666667%",
      full: "100%",
      min: "min-content",
      max: "max-content",
      fit: "fit-content"
    }),
    width: ({ theme: o }) => ({
      auto: "auto",
      ...o("spacing"),
      "1/2": "50%",
      "1/3": "33.333333%",
      "2/3": "66.666667%",
      "1/4": "25%",
      "2/4": "50%",
      "3/4": "75%",
      "1/5": "20%",
      "2/5": "40%",
      "3/5": "60%",
      "4/5": "80%",
      "1/6": "16.666667%",
      "2/6": "33.333333%",
      "3/6": "50%",
      "4/6": "66.666667%",
      "5/6": "83.333333%",
      "1/12": "8.333333%",
      "2/12": "16.666667%",
      "3/12": "25%",
      "4/12": "33.333333%",
      "5/12": "41.666667%",
      "6/12": "50%",
      "7/12": "58.333333%",
      "8/12": "66.666667%",
      "9/12": "75%",
      "10/12": "83.333333%",
      "11/12": "91.666667%",
      full: "100%",
      screen: "100vw",
      svw: "100svw",
      lvw: "100lvw",
      dvw: "100dvw",
      min: "min-content",
      max: "max-content",
      fit: "fit-content"
    }),
    willChange: {
      auto: "auto",
      scroll: "scroll-position",
      contents: "contents",
      transform: "transform"
    },
    zIndex: {
      auto: "auto",
      0: "0",
      10: "10",
      20: "20",
      30: "30",
      40: "40",
      50: "50"
    }
  },
  plugins: []
};
(function(o) {
  Object.defineProperty(o, "__esModule", {
    value: !0
  }), Object.defineProperty(o, "default", {
    enumerable: !0,
    get: function() {
      return e;
    }
  });
  const r = s, a = /* @__PURE__ */ i(m);
  function i(t) {
    return t && t.__esModule ? t : {
      default: t
    };
  }
  const e = (0, r.cloneDeep)(a.default.theme);
})(u);
let c = u;
var f = (c.__esModule ? c : { default: c }).default, p = {}, d = {};
(function(o) {
  Object.defineProperty(o, "__esModule", {
    value: !0
  }), Object.defineProperty(o, "default", {
    enumerable: !0,
    get: function() {
      return a;
    }
  });
  function r(i, e) {
    return {
      handler: i,
      config: e
    };
  }
  r.withOptions = function(i, e = () => ({})) {
    const t = function(n) {
      return {
        __options: n,
        handler: i(n),
        config: e(n)
      };
    };
    return t.__isOptionsFunction = !0, t.__pluginFunction = i, t.__configFunction = e, t;
  };
  const a = r;
})(d);
(function(o) {
  Object.defineProperty(o, "__esModule", {
    value: !0
  }), Object.defineProperty(o, "default", {
    enumerable: !0,
    get: function() {
      return i;
    }
  });
  const r = /* @__PURE__ */ a(d);
  function a(e) {
    return e && e.__esModule ? e : {
      default: e
    };
  }
  const i = r.default;
})(p);
let l = p;
var v = (l.__esModule ? l : { default: l }).default;
const b = /* @__PURE__ */ g(v), h = ["var(--fui-font-family-sans)", ...f.fontFamily.sans], y = ["var(--fui-font-family-serif)", ...f.fontFamily.serif], x = b(
  ({ addBase: o, matchUtilities: r, theme: a }) => {
    r(
      {
        "animation-delay": (i) => ({
          "animation-delay": i
        })
      },
      {
        values: a("transitionDelay")
      }
    ), o({
      ":root": {
        /* ~~~~~~~~~~ Grid ~~~~~~~~~~ */
        "--fui-baseline": "24px",
        "--fui-subline": "4px",
        /* ~~~~~~~~~~ Corner radius ~~~~~~~~~~ */
        /* Base corner radius */
        "--fui-br-xs": "var(--fui-subline)",
        "--fui-br-sm": "calc(var(--fui-subline) * 2)",
        "--fui-br-md": "calc(var(--fui-subline) * 3)",
        "--fui-br-lg": "calc(var(--fui-subline) * 4)",
        "--fui-br-full": "calc(var(--fui-baseline) * 5)",
        /* ~~~~~~~~~~ Spacing ~~~~~~~~~~ */
        /* Base spacing */
        "--fui-sp-1": "var(--fui-subline)",
        "--fui-sp-2": "calc(var(--fui-subline) * 2)",
        "--fui-sp-3": "calc(var(--fui-subline) * 3)",
        "--fui-sp-4": "calc(var(--fui-subline) * 4)",
        "--fui-sp-5": "calc(var(--fui-subline) * 5)",
        "--fui-sp-6": "var(--fui-baseline)",
        "--fui-sp-7": "calc(var(--fui-baseline) + var(--fui-subline) * 2)",
        "--fui-sp-8": "calc(var(--fui-baseline) + var(--fui-subline) * 4)",
        "--fui-sp-9": "calc(var(--fui-baseline) * 3)",
        /* ~~~~~~~~~~ Sizing ~~~~~~~~~~ */
        /* Base sizing */
        "--fui-s-1": "var(--fui-subline)",
        "--fui-s-2": "calc(var(--fui-subline) * 4)",
        "--fui-s-3": "calc(var(--fui-baseline) - var(--fui-subline))",
        "--fui-s-4": "var(--fui-baseline)",
        "--fui-s-5": "calc(var(--fui-baseline) * 1.5)",
        "--fui-s-6": "calc(var(--fui-baseline) + 5 * var(--fui-subline))",
        "--fui-s-7": "calc(var(--fui-baseline) * 2 + var(--fui-subline))",
        "--fui-s-8": "calc(var(--fui-baseline) * 2.5)",
        "--fui-s-9": "calc(var(--fui-baseline) * 3)",
        "--fui-s-10": "calc(var(--fui-baseline) * 4.5 + var(--fui-subline))",
        /* ~~~~~~~~~~ Typography ~~~~~~~~~~ */
        /* Font family */
        "--fui-font-family-sans": "'Roobert'",
        "--fui-font-family-serif": "'Bogue'",
        /* Font size */
        "--fui-font-s-0": "0.75rem",
        "--fui-font-s-1": "0.875rem",
        "--fui-font-s-2": "1rem",
        "--fui-font-s-3": "1.13rem",
        "--fui-font-s-4": "1.25rem",
        "--fui-font-s-5": "1.5rem",
        "--fui-font-s-6": "1.75rem",
        "--fui-font-s-7": "2.125rem",
        "--fui-font-s-8": "2.625rem",
        "--fui-font-s-9": "3.06rem",
        "--fui-font-s-10": "3.44rem",
        "--fui-font-s-11": "3.75rem",
        "--fui-font-s-12": "4rem",
        /* Font weight */
        "--fui-font-weight-1": "400",
        "--fui-font-weight-2": "500",
        "--fui-font-weight-3": "600",
        /* ~~~~~~~~~~ Colors ~~~~~~~~~~ */
        /* ~~~~~~~~~~ Global semantic colors ~~~~~~~~~~ */
        // Primary colors
        "--fui-color-primary-0": "hsl(226 45.2% 16.5%)",
        "--fui-color-primary-1": "hsl(226 34% 19.6%)",
        "--fui-color-primary-2": "hsl(227 23% 23.9%)",
        "--fui-color-primary-3": "hsl(226 13.7% 30%)",
        "--fui-color-primary-4": "hsl(231 3.3% 41.8%)",
        "--fui-color-primary-5": "hsl(38 4.2% 62.4%)",
        "--fui-color-primary-6": "hsl(40 11.3% 79.2%)",
        "--fui-color-primary-7": "hsl(36 16.1% 87.8%)",
        "--fui-color-primary-8": "hsl(43 17.9% 92.4%)",
        "--fui-color-primary-9": "hsl(36 20% 95.1%)",
        "--fui-color-primary-10": "hsl(40 23.1% 97.5%)",
        "--fui-color-primary-11": "hsl(30 33.3% 98.8%)",
        // Primary alpha colors
        "--fui-color-primary_alpha-1-a": "hsl(226 45.2% 16.5% / 94%)",
        "--fui-color-primary_alpha-2-a": "hsl(226 45.2% 16.5% / 84%)",
        "--fui-color-primary_alpha-3-a": "hsl(226 45.2% 16.5% / 72%)",
        "--fui-color-primary_alpha-4-a": "hsl(226 45.2% 16.5% / 56%)",
        "--fui-color-primary_alpha-5-a": "hsl(226 45.2% 16.5% / 39%)",
        "--fui-color-primary_alpha-6-a": "hsl(226 45.2% 16.5% / 25%)",
        "--fui-color-primary_alpha-7-a": "hsl(226 45.2% 16.5% / 15%)",
        "--fui-color-primary_alpha-8-a": "hsl(226 45.2% 16.5% / 8%)",
        "--fui-color-primary_alpha-9-a": "hsl(226 45.2% 16.5% / 3%)",
        "--fui-color-primary_alpha-zero-a": "hsl(226 45.2% 16.5% / 0%)",
        // Accent colors
        "--fui-color-accent-0-3": "hsl(168 51.4% 13.7%)",
        "--fui-color-accent-0-2": "hsl(168 51.6% 24.3%)",
        "--fui-color-accent-0-1": "hsl(168 51.2% 32.2%)",
        "--fui-color-accent-0": "hsl(168 51.3% 37.1%)",
        "--fui-color-accent-1": "hsl(168 39.4% 43.3%)",
        "--fui-color-accent-2": "hsl(168 30.3% 52.7%)",
        "--fui-color-accent-3": "hsl(168 30.1% 64.1%)",
        "--fui-color-accent-4": "hsl(168 29.5% 76.1%)",
        "--fui-color-accent-5": "hsl(169 29.7% 85.5%)",
        "--fui-color-accent-6": "hsl(164 31.4% 93.1%)",
        "--fui-color-accent-7": "hsl(165 33.3% 97.6%)",
        // Disable colors
        "--fui-color-disable-0": "hsl(0 0% 45.1%)",
        "--fui-color-disable-1": "hsl(0 0% 57.6%)",
        "--fui-color-disable-2": "hsl(0 0% 68.6%)",
        "--fui-color-disable-3": "hsl(0 0% 77.6%)",
        "--fui-color-disable-4": "hsl(0 0% 85.1%)",
        "--fui-color-disable-5": "hsl(0 0% 90.6%)",
        "--fui-color-disable-6": "hsl(0 0% 94.9%)",
        "--fui-color-disable-7": "hsl(0 0% 98.4%)",
        // Success colors
        "--fui-color-success-0-3": "hsl(128 30.7% 19.8%)",
        "--fui-color-success-0-2": "hsl(127 29.7% 39.6%)",
        "--fui-color-success-0-1": "hsl(127 34.5% 53.3%)",
        "--fui-color-success-0": "hsl(127 45.1% 60%)",
        "--fui-color-success-1": "hsl(127 45.1% 70%)",
        "--fui-color-success-2": "hsl(127 44.6% 78%)",
        "--fui-color-success-3": "hsl(127 44.7% 85.1%)",
        "--fui-color-success-4": "hsl(129 46.7% 91.2%)",
        "--fui-color-success-5": "hsl(127 44.4% 96.5%)",
        // Warning colors
        "--fui-color-warning-0-3": "hsl(44 59.6% 20.4%)",
        "--fui-color-warning-0-2": "hsl(43 60.4% 39.6%)",
        "--fui-color-warning-0-1": "hsl(43 68.9% 53.3%)",
        "--fui-color-warning-0": "hsl(43 90.2% 60%)",
        "--fui-color-warning-1": "hsl(43 90.1% 64.3%)",
        "--fui-color-warning-2": "hsl(43 90.5% 71.2%)",
        "--fui-color-warning-3": "hsl(43 90.7% 78.8%)",
        "--fui-color-warning-4": "hsl(43 91% 86.9%)",
        "--fui-color-warning-5": "hsl(43 93.3% 94.1%)",
        // Danger colors
        "--fui-color-danger-0-3": "hsl(1 61.4% 16.3%)",
        "--fui-color-danger-0-2": "hsl(1 61.1% 35.3%)",
        "--fui-color-danger-0-1": "hsl(1 61.6% 49%)",
        "--fui-color-danger-0": "hsl(1 77.8% 55.9%)",
        "--fui-color-danger-1": "hsl(1 78.3% 69.2%)",
        "--fui-color-danger-2": "hsl(1 77.4% 79.2%)",
        "--fui-color-danger-3": "hsl(1 77.1% 86.3%)",
        "--fui-color-danger-4": "hsl(2 76.7% 91.6%)",
        "--fui-color-danger-5": "hsl(0 80% 96.1%)",
        // Status colors
        "--fui-color-status-0-3": "hsl(210 34.5% 21.6%)",
        "--fui-color-status-0-2": "hsl(209 34.9% 41%)",
        "--fui-color-status-0-1": "hsl(209 44.6% 56.1%)",
        "--fui-color-status-0": "hsl(209 65.2% 65.1%)",
        "--fui-color-status-1": "hsl(209 65.4% 73.9%)",
        "--fui-color-status-2": "hsl(209 64.6% 81.2%)",
        "--fui-color-status-3": "hsl(209 65.6% 87.5%)",
        "--fui-color-status-4": "hsl(209 65.7% 93.1%)",
        "--fui-color-status-5": "hsl(210 71.4% 97.3%)",
        // Marker 1 colors
        "--fui-color-marker-1-0-3": "hsl(314 32.1% 20.8%)",
        "--fui-color-marker-1-0-2": "hsl(314 32.1% 42.2%)",
        "--fui-color-marker-1-0-1": "hsl(314 43.1% 57.3%)",
        "--fui-color-marker-1-0": "hsl(314 59.8% 64.9%)",
        "--fui-color-marker-1-1": "hsl(314 59.7% 73.7%)",
        "--fui-color-marker-1-2": "hsl(314 60.4% 81.2%)",
        "--fui-color-marker-1-3": "hsl(314 59.4% 87.5%)",
        "--fui-color-marker-1-4": "hsl(314 61.1% 92.9%)",
        "--fui-color-marker-1-5": "hsl(315 57.1% 97.3%)",
        // Marker 2 colors
        "--fui-color-marker-2-0-3": "hsl(263 34.6% 21%)",
        "--fui-color-marker-2-0-2": "hsl(263 34.2% 44.1%)",
        "--fui-color-marker-2-0-1": "hsl(263 55.1% 61.6%)",
        "--fui-color-marker-2-0": "hsl(263 80.4% 70%)",
        "--fui-color-marker-2-1": "hsl(263 80.7% 77.6%)",
        "--fui-color-marker-2-2": "hsl(263 80.7% 83.7%)",
        "--fui-color-marker-2-3": "hsl(263 81.8% 89.2%)",
        "--fui-color-marker-2-4": "hsl(264 80.6% 93.9%)",
        "--fui-color-marker-2-5": "hsl(264 83.3% 97.6%)",
        // Marker 3 colors
        "--fui-color-marker-3-0-3": "hsl(227 54% 19.6%)",
        "--fui-color-marker-3-0-2": "hsl(226 54.2% 37.6%)",
        "--fui-color-marker-3-0-1": "hsl(226 56.2% 50.8%)",
        "--fui-color-marker-3-0": "hsl(226 78% 59%)",
        "--fui-color-marker-3-1": "hsl(226 78.1% 71.4%)",
        "--fui-color-marker-3-2": "hsl(227 77.6% 80.8%)",
        "--fui-color-marker-3-3": "hsl(226 78.5% 87.3%)",
        "--fui-color-marker-3-4": "hsl(226 79.5% 92.4%)",
        "--fui-color-marker-3-5": "hsl(224 78.9% 96.3%)",
        // Marker 4 colors
        "--fui-color-marker-4-0-3": "hsl(18 65.2% 22.5%)",
        "--fui-color-marker-4-0-2": "hsl(18 65.2% 40.6%)",
        "--fui-color-marker-4-0-1": "hsl(18 71.2% 52.4%)",
        "--fui-color-marker-4-0": "hsl(18 89.7% 58%)",
        "--fui-color-marker-4-1": "hsl(18 90 68%.6%)",
        "--fui-color-marker-4-2": "hsl(18 89.5% 77.6%)",
        "--fui-color-marker-4-3": "hsl(18 89% 85.7%)",
        "--fui-color-marker-4-4": "hsl(18 90.2% 92%)",
        "--fui-color-marker-4-5": "hsl(18 86.7% 97.1%)",
        /* ~~~~~~~~~~ Action Palette ~~~~~~~~~~ */
        // Primary
        "--fui-color-action-primary-0-2": "var(--fui-color-accent-0-2)",
        "--fui-color-action-primary-0-1": "var(--fui-color-accent-0-1)",
        "--fui-color-action-primary-0": "var(--fui-color-accent-0)",
        "--fui-color-action-primary-1": "var(--fui-color-accent-6)",
        // Secondary
        "--fui-color-action-secondary-0": "var(--fui-color-primary-0)",
        "--fui-color-action-secondary-1": "var(--fui-color-primary-2)",
        "--fui-color-action-secondary-2": "var(--fui-color-primary-4)",
        "--fui-color-action-secondary-3": "var(--fui-color-primary-7)",
        "--fui-color-action-secondary-4": "var(--fui-color-primary-8)",
        "--fui-color-action-secondary-5": "var(--fui-color-primary-9)",
        "--fui-color-action-secondary-6": "var(--fui-color-primary-11)",
        // Secondary alpha
        "--fui-color-action-secondary-alpha-0": "var(--fui-color-primary_alpha-zero-a)",
        "--fui-color-action-secondary-alpha-1": "var(--fui-color-primary_alpha-8-a)",
        "--fui-color-action-secondary-alpha-2": "var(--fui-color-primary_alpha-7-a)",
        "--fui-color-action-secondary-alpha-3": "var(--fui-color-primary_alpha-6-a)",
        // Disable
        "--fui-color-action-disable-0": "var(--fui-color-disable-1)",
        "--fui-color-action-disable-1": "var(--fui-color-disable-2)",
        "--fui-color-action-disable-2": "var(--fui-color-disable-6)",
        // Danger
        "--fui-color-action-danger-0-2": "var(--fui-color-danger-0-2)",
        "--fui-color-action-danger-0-1": "var(--fui-color-danger-0-1)",
        "--fui-color-action-danger-0": "var(--fui-color-danger-0)",
        "--fui-color-action-danger-1": "var(--fui-color-danger-1)",
        "--fui-color-action-danger-2": "var(--fui-color-danger-4)",
        // Focus
        "--fui-color-action-focus": "var(--fui-color-marker-3-0)",
        /**
         * ~~~~~~~~~~~~~ Local semantic colors ~~~~~~~~~~~~~
         * Local semantic colors are the colors that are actively utilized and
         * exposed through the Tailwind theme.
         */
        // Typography
        "--fui-color-text-primary": "var(--fui-color-primary-0)",
        "--fui-color-text-secondary": "var(--fui-color-primary-2)",
        "--fui-color-text-primary-inverted": "var(--fui-color-primary-11)",
        "--fui-color-text-danger": "var(--fui-color-danger-0-1)",
        "--fui-color-text-success": "var(--fui-color-accent-0-1)",
        // Background
        "--fui-color-bg-primary": "var(--fui-color-primary-9)",
        "--fui-color-bg-secondary": "var(--fui-color-primary-11)",
        // Background - system
        "--fui-color-bg-danger": "var(--fui-color-danger-5)",
        "--fui-color-bg-pop_up": "var(--fui-color-primary_alpha-4-a)",
        "--fui-color-bg-warning": "var(--fui-color-warning-5)",
        "--fui-color-bg-success": "var(--fui-color-success-4)",
        "--fui-color-bg-status": "var(--fui-color-status-4)",
        "--fui-color-bg-tooltip": "var(--fui-color-primary-0)",
        // Background - extra
        "--fui-color-bg-extra-1": "var(--fui-color-accent-5)",
        "--fui-color-bg-extra-2": "var(--fui-color-primary-8)",
        "--fui-color-bg-extra-3": "var(--fui-color-marker_4-4)",
        "--fui-color-bg-extra-4": "var(--fui-color-marker_3-4)",
        // Element - stroke
        "--fui-color-el-stroke-primary": "var(--fui-color-primary_alpha-8-a)",
        "--fui-color-el-stroke-secondary": "var(--fui-color-primary_alpha-7-a)",
        // Element - icon
        "--fui-color-el-icon-primary": "var(--fui-color-primary-0)",
        "--fui-color-el-icon-secondary": "var(--fui-color-primary-2)",
        "--fui-color-el-icon-primary-inverted": "var(--fui-color-primary-11)",
        // Element - progress primary
        "--fui-color-el-content-progress-primary": "var(--fui-color-accent-0)",
        "--fui-color-el-bg-progress-primary": "var(--fui-color-accent-6)",
        // Element - progress secondary
        "--fui-color-el-content-progress-secondary": "var(--fui-color-primary-0)",
        "--fui-color-el-bg-progress-secondary": "var(--fui-color-primary_alpha-7-a)",
        // Element - Tooltip
        "--fui-color-el-bg-tooltip": "var(--fui-color-primary-0)",
        /* Actions */
        // Primary
        "--fui-color-action-content-primary-default": "var(--fui-color-action-secondary-6)",
        "--fui-color-action-content-primary-hover": "var(--fui-color-action-secondary-6)",
        "--fui-color-action-content-primary-pressed": "var(--fui-color-action-secondary-6)",
        "--fui-color-action-content-primary-disabled": "var(--fui-color-action-secondary-2)",
        "--fui-color-action-bg-primary-default": "var(--fui-color-action-primary-0)",
        "--fui-color-action-bg-primary-hover": "var(--fui-color-action-primary-0-1)",
        "--fui-color-action-bg-primary-pressed": "var(--fui-color-action-primary-0-2)",
        "--fui-color-action-bg-primary-disabled": "var(--fui-color-action-disable-2)",
        // Extra
        "--fui-color-action-content-extra-default": "var(--fui-color-action-secondary-0)",
        "--fui-color-action-content-extra-hover": "var(--fui-color-action-secondary-0)",
        "--fui-color-action-content-extra-pressed": "var(--fui-color-action-secondary-0)",
        "--fui-color-action-content-extra-disabled": "var(--fui-color-action-secondary-2)",
        "--fui-color-action-bg-extra-default": "var(--fui-color-action-secondary-5)",
        "--fui-color-action-bg-extra-hover": "var(--fui-color-action-secondary-4)",
        "--fui-color-action-bg-extra-pressed": "var(--fui-color-action-secondary-3)",
        "--fui-color-action-bg-extra-disabled": "var(--fui-color-action-disable-2)",
        // Outline
        "--fui-color-action-content-outline-default": "var(--fui-color-action-secondary-0)",
        "--fui-color-action-content-outline-hover": "var(--fui-color-action-secondary-0)",
        "--fui-color-action-content-outline-pressed": "var(--fui-color-action-secondary-0)",
        "--fui-color-action-content-outline-disabled": "var(--fui-color-action-secondary-2)",
        "--fui-color-action-stroke-outline-default": "var(--fui-color-action-secondary-0)",
        "--fui-color-action-stroke-outline-hover": "var(--fui-color-action-secondary-0)",
        "--fui-color-action-stroke-outline-pressed": "var(--fui-color-action-secondary-0)",
        "--fui-color-action-stroke-outline-disabled": "var(--fui-color-action-secondary-alpha-1)",
        "--fui-color-action-bg-outline-default": "var(--fui-color-action-secondary-alpha-0)",
        "--fui-color-action-bg-outline-hover": "var(--fui-color-action-secondary-alpha-1)",
        "--fui-color-action-bg-outline-pressed": "var(--fui-color-action-secondary-alpha-2)",
        "--fui-color-action-bg-outline-disabled": "var(--fui-color-action-secondary-alpha-0)",
        // Ghost
        "--fui-color-action-content-ghost-default": "var(--fui-color-action-secondary-0)",
        "--fui-color-action-content-ghost-hover": "var(--fui-color-action-secondary-0)",
        "--fui-color-action-content-ghost-pressed": "var(--fui-color-action-secondary-0)",
        "--fui-color-action-content-ghost-disabled": "var(--fui-color-action-secondary-2)",
        "--fui-color-action-bg-ghost-default": "var(--fui-color-action-secondary-alpha-0)",
        "--fui-color-action-bg-ghost-hover": "var(--fui-color-action-secondary-alpha-1)",
        "--fui-color-action-bg-ghost-pressed": "var(--fui-color-action-secondary-alpha-2)",
        "--fui-color-action-bg-ghost-disabled": "var(--fui-color-action-secondary-alpha-0)",
        // Danger
        "--fui-color-action-content-danger-default": "var(--fui-color-action-secondary-6)",
        "--fui-color-action-content-danger-hover": "var(--fui-color-action-secondary-6)",
        "--fui-color-action-content-danger-pressed": "var(--fui-color-action-secondary-6)",
        "--fui-color-action-content-danger-disabled": "var(--fui-color-action-secondary-2)",
        "--fui-color-action-bg-danger-default": "var(--fui-color-action-danger-0)",
        "--fui-color-action-bg-danger-hover": "var(--fui-color-action-danger-0-1)",
        "--fui-color-action-bg-danger-pressed": "var(--fui-color-action-danger-0-2)",
        "--fui-color-action-bg-danger-disabled": "var(--fui-color-action-disable-2)",
        /* Inputs */
        // Input primary
        "--fui-color-action-content-input-primary-default": "var(--fui-color-action-secondary-2)",
        "--fui-color-action-content-input-primary-hover": "var(--fui-color-action-secondary-1)",
        "--fui-color-action-content-input-primary-active": "var(--fui-color-action-secondary-0)",
        "--fui-color-action-content-input-primary-filled": "var(--fui-color-action-secondary-0)",
        "--fui-color-action-content-input-primary-disabled": "var(--fui-color-action-disable-0)",
        "--fui-color-action-stroke-input-primary-default": "var(--fui-color-action-secondary-alpha-2)",
        "--fui-color-action-stroke-input-primary-hover": "var(--fui-color-action-secondary-alpha-3)",
        "--fui-color-action-stroke-input-primary-active": "var(--fui-color-action-primary-0)",
        "--fui-color-action-stroke-input-primary-filled": "var(--fui-color-action-secondary-alpha-2)",
        "--fui-color-action-stroke-input-primary-disabled": "var(--fui-color-action-secondary-alpha-1)",
        // Input danger
        "--fui-color-action-content-input-danger-default": "var(--fui-color-action-secondary-2)",
        "--fui-color-action-content-input-danger-hover": "var(--fui-color-action-secondary-1)",
        "--fui-color-action-content-input-danger-active": "var(--fui-color-action-secondary-0)",
        "--fui-color-action-content-input-danger-filled": "var(--fui-color-action-secondary-0)",
        "--fui-color-action-content-input-danger-disabled": "var(--fui-color-action-disable-0)",
        "--fui-color-action-stroke-input-danger-default": "var(--fui-color-action-danger-2)",
        "--fui-color-action-stroke-input-danger-hover": "var(--fui-color-action-danger-1)",
        "--fui-color-action-stroke-input-danger-active": "var(--fui-color-action-danger-0)",
        "--fui-color-action-stroke-input-danger-filled": "var(--fui-color-action-danger-2)",
        "--fui-color-action-stroke-input-danger-disabled": "var(--fui-color-action-secondary-alpha-1)",
        /* Tags */
        // Status
        "--fui-color-tag-content-status": "var(--fui-color-status-0-3)",
        "--fui-color-tag-bg-status": "var(--fui-color-status-3)",
        // Success
        "--fui-color-tag-content-success": "var(--fui-color-accent-0-2)",
        "--fui-color-tag-bg-success": "var(--fui-color-success-4)",
        // Warning
        "--fui-color-tag-content-warning": "var(--fui-color-warning-0-3)",
        "--fui-color-tag-bg-warning": "var(--fui-color-warning-4)",
        // Danger
        "--fui-color-tag-content-danger": "var(--fui-color-danger-0-3)",
        "--fui-color-tag-bg-danger": "var(--fui-color-danger-4)",
        // 1
        "--fui-color-tag-content-1": "var(--fui-color-primary-0)",
        "--fui-color-tag-bg-1": "var(--fui-color-primary-7)",
        // 2
        "--fui-color-tag-content-2": "var(--fui-color-marker-1-0-3)",
        "--fui-color-tag-bg-2": "var(--fui-color-marker-1-3)",
        // 3
        "--fui-color-tag-content-3": "var(--fui-color-marker-2-0-3)",
        "--fui-color-tag-bg-3": "var(--fui-color-marker-2-3)",
        // 4
        "--fui-color-tag-content-4": "var(--fui-color-marker-3-0-3)",
        "--fui-color-tag-bg-4": "var(--fui-color-marker-4-3)",
        // 5
        "--fui-color-tag-content-5": "var(--fui-color-marker-4-0-1)",
        "--fui-color-tag-bg-5": "var(--fui-color-marker-4-2)",
        // 6
        "--fui-color-tag-content-6": "var(--fui-color-accent-0-2)",
        "--fui-color-tag-bg-6": "var(--fui-color-accent-6)"
      }
    });
  },
  /**
   * Set extendable Tailwind theme with CSS variables
   */
  {
    theme: {
      borderRadius: {
        xs: "var(--fui-br-xs)",
        sm: "var(--fui-br-sm)",
        md: "var(--fui-br-md)",
        lg: "var(--fui-br-lg)",
        full: "var(--fui-br-full)"
      },
      spacing: {
        /**
         * Spacing scale is inherited by the:
         * padding, margin, width, minWidth, maxWidth, height, minHeight, maxHeight,
         * gap, inset, space, translate, scrollMargin, and scrollPadding
         *
         * This is the reason why spacing and sizing are defined in the same scale
         */
        /* Spacing */
        "sp-1": "var(--fui-sp-1)",
        "sp-2": "var(--fui-sp-2)",
        "sp-3": "var(--fui-sp-3)",
        "sp-4": "var(--fui-sp-4)",
        "sp-5": "var(--fui-sp-5)",
        "sp-6": "var(--fui-sp-6)",
        "sp-7": "var(--fui-sp-7)",
        "sp-8": "var(--fui-sp-8)",
        "sp-9": "var(--fui-sp-9)",
        /* Sizing */
        "s-1": "var(--fui-s-1)",
        "s-2": "var(--fui-s-2)",
        "s-3": "var(--fui-s-3)",
        "s-4": "var(--fui-s-4)",
        "s-5": "var(--fui-s-5)",
        "s-6": "var(--fui-s-6)",
        "s-7": "var(--fui-s-7)",
        "s-8": "var(--fui-s-8)",
        "s-9": "var(--fui-s-9)",
        "s-10": "var(--fui-s-10)"
      },
      colors: {
        // Utils colors
        current: "currentColor",
        transparent: "transparent",
        /* ~~~~~~~~~~ Local semantic colors ~~~~~~~~~~ */
        /* ~~~~~~~~~~ Generic colors ~~~~~~~~~~ */
        // Typography
        "text-primary": "var(--fui-color-text-primary)",
        "text-secondary": "var(--fui-color-text-secondary)",
        "text-primary-inverted": "var(--fui-color-text-primary-inverted)",
        "text-danger": "var(--fui-color-text-danger)",
        "text-success": "var(--fui-color-text-success)",
        // Background
        "bg-primary": "var(--fui-color-bg-primary)",
        "bg-secondary": "var(--fui-color-bg-secondary)",
        // Background - system
        "bg-danger": "var(--fui-color-bg-danger)",
        "bg-warning": "var(--fui-color-bg-warning)",
        "bg-success": "var(--fui-color-bg-success)",
        "bg-status": "var(--fui-color-bg-status)",
        // Background - extra
        "bg-extra-1": "var(--fui-color-bg-extra-1)",
        "bg-extra-2": "var(--fui-color-bg-extra-2)",
        "bg-extra-3": "var(--fui-color-bg-extra-3)",
        "bg-extra-4": "var(--fui-color-bg-extra-4)",
        // Element - stroke
        "el-stroke-primary": "var(--fui-color-el-stroke-primary)",
        "el-stroke-secondary": "var(--fui-color-el-stroke-secondary)",
        // Element - icon
        "el-icon-primary": "var(--fui-color-el-icon-primary)",
        "el-icon-secondary": "var(--fui-color-el-icon-secondary)",
        "el-icon-primary-inverted": "var(--fui-color-el-icon-primary-inverted)",
        // Element - progress primary
        "el-content-progress-primary": "var(--fui-color-el-content-progress-primary)",
        "el-bg-progress-primary": "var(--fui-color-el-bg-progress-primary)",
        // Element - progress secondary
        "el-content-progress-secondary": "var(--fui-color-el-content-progress-secondary)",
        "el-bg-progress-secondary": "var(--fui-color-el-bg-progress-secondary)",
        // Element - Tooltip
        "el-bg-tooltip": "var(--fui-color-el-bg-tooltip)",
        /* ~~~~~~~~~~ Action Palette ~~~~~~~~~~ */
        // Primary
        "action-primary-0-1": "var(--fui-color-action-primary-0-1)",
        "action-primary-0-2": "var(--fui-color-action-primary-0-2)",
        "action-primary-0": "var(--fui-color-action-primary-0)",
        "action-primary-1": "var(--fui-color-action-primary-1)",
        // Secondary
        "action-secondary-0": "var(--fui-color-action-secondary-0)",
        "action-secondary-1": "var(--fui-color-action-secondary-1)",
        "action-secondary-2": "var(--fui-color-action-secondary-2)",
        "action-secondary-3": "var(--fui-color-action-secondary-3)",
        "action-secondary-4": "var(--fui-color-action-secondary-4)",
        "action-secondary-5": "var(--fui-color-action-secondary-5)",
        "action-secondary-6": "var(--fui-color-action-secondary-6)",
        // Disable
        "action-disable-0": "var(--fui-color-action-disable-0)",
        "action-disable-1": "var(--fui-color-action-disable-1)",
        "action-disable-2": "var(--fui-color-action-disable-2)",
        /* ~~~~~~~~~~ Action colors ~~~~~~~~~~ */
        // Primary
        "action-content-primary-default": "var(--fui-color-action-content-primary-default)",
        "action-content-primary-hover": "var(--fui-color-action-content-primary-hover)",
        "action-content-primary-pressed": "var(--fui-color-action-content-primary-pressed)",
        "action-content-primary-disabled": "var(--fui-color-action-content-primary-disabled)",
        "action-bg-primary-default": "var(--fui-color-action-bg-primary-default)",
        "action-bg-primary-hover": "var(--fui-color-action-bg-primary-hover)",
        "action-bg-primary-pressed": "var(--fui-color-action-bg-primary-pressed)",
        "action-bg-primary-disabled": "var(--fui-color-action-bg-primary-disabled)",
        // Extra
        "action-content-extra-default": "var(--fui-color-action-content-extra-default)",
        "action-content-extra-hover": "var(--fui-color-action-content-extra-hover)",
        "action-content-extra-pressed": "var(--fui-color-action-content-extra-pressed)",
        "action-content-extra-disabled": "var(--fui-color-action-content-extra-disabled)",
        "action-bg-extra-default": "var(--fui-color-action-bg-extra-default)",
        "action-bg-extra-hover": "var(--fui-color-action-bg-extra-hover)",
        "action-bg-extra-pressed": "var(--fui-color-action-bg-extra-pressed)",
        "action-bg-extra-disabled": "var(--fui-color-action-bg-extra-disabled)",
        // Outline
        "action-content-outline-default": "var(--fui-color-action-content-outline-default)",
        "action-content-outline-hover": "var(--fui-color-action-content-outline-hover)",
        "action-content-outline-pressed": "var(--fui-color-action-content-outline-pressed)",
        "action-content-outline-disabled": "var(--fui-color-action-content-outline-disabled)",
        "action-stroke-outline-default": "var(--fui-color-action-stroke-outline-default)",
        "action-stroke-outline-hover": "var(--fui-color-action-stroke-outline-hover)",
        "action-stroke-outline-pressed": "var(--fui-color-action-stroke-outline-pressed)",
        "action-stroke-outline-disabled": "var(--fui-color-action-stroke-outline-disabled)",
        "action-bg-outline-default": "var(--fui-color-action-bg-outline-default)",
        "action-bg-outline-hover": "var(--fui-color-action-bg-outline-hover)",
        "action-bg-outline-pressed": "var(--fui-color-action-bg-outline-pressed)",
        "action-bg-outline-disabled": "var(--fui-color-action-bg-outline-disabled)",
        // Ghost
        "action-content-ghost-default": "var(--fui-color-action-content-ghost-default)",
        "action-content-ghost-hover": "var(--fui-color-action-content-ghost-hover)",
        "action-content-ghost-pressed": "var(--fui-color-action-content-ghost-pressed)",
        "action-content-ghost-disabled": "var(--fui-color-action-content-ghost-disabled)",
        "action-bg-ghost-default": "var(--fui-color-action-bg-ghost-default)",
        "action-bg-ghost-hover": "var(--fui-color-action-bg-ghost-hover)",
        "action-bg-ghost-pressed": "var(--fui-color-action-bg-ghost-pressed)",
        "action-bg-ghost-disabled": "var(--fui-color-action-bg-ghost-disabled)",
        // Danger
        "action-content-danger-default": "var(--fui-color-action-content-danger-default)",
        "action-content-danger-hover": "var(--fui-color-action-content-danger-hover)",
        "action-content-danger-pressed": "var(--fui-color-action-content-danger-pressed)",
        "action-content-danger-disabled": "var(--fui-color-action-content-danger-disabled)",
        "action-bg-danger-default": "var(--fui-color-action-bg-danger-default)",
        "action-bg-danger-hover": "var(--fui-color-action-bg-danger-hover)",
        "action-bg-danger-pressed": "var(--fui-color-action-bg-danger-pressed)",
        "action-bg-danger-disabled": "var(--fui-color-action-bg-danger-disabled)",
        // Focus
        "action-focus": "var(--fui-color-action-focus)",
        // Input primary
        "action-content-input-primary-default": "var(--fui-color-action-content-input-primary-default)",
        "action-content-input-primary-hover": "var(--fui-color-action-content-input-primary-hover)",
        "action-content-input-primary-active": "var(--fui-color-action-content-input-primary-active)",
        "action-content-input-primary-filled": "var(--fui-color-action-content-input-primary-filled)",
        "action-content-input-primary-disabled": "var(--fui-color-action-content-input-primary-disabled)",
        "action-stroke-input-primary-default": "var(--fui-color-action-stroke-input-primary-default)",
        "action-stroke-input-primary-hover": "var(--fui-color-action-stroke-input-primary-hover)",
        "action-stroke-input-primary-active": "var(--fui-color-action-stroke-input-primary-active)",
        "action-stroke-input-primary-filled": "var(--fui-color-action-stroke-input-primary-filled)",
        "action-stroke-input-primary-disabled": "var(--fui-color-action-stroke-input-primary-disabled)",
        // Input danger
        "action-content-input-danger-default": "var(--fui-color-action-content-input-danger-default)",
        "action-content-input-danger-hover": "var(--fui-color-action-content-input-danger-hover)",
        "action-content-input-danger-active": "var(--fui-color-action-content-input-danger-active)",
        "action-content-input-danger-filled": "var(--fui-color-action-content-input-danger-filled)",
        "action-content-input-danger-disabled": "var(--fui-color-action-content-input-danger-disabled)",
        "action-stroke-input-danger-default": "var(--fui-color-action-stroke-input-danger-default)",
        "action-stroke-input-danger-hover": "var(--fui-color-action-stroke-input-danger-hover)",
        "action-stroke-input-danger-active": "var(--fui-color-action-stroke-input-danger-active)",
        "action-stroke-input-danger-filled": "var(--fui-color-action-stroke-input-danger-filled)",
        "action-stroke-input-danger-disabled": "var(--fui-color-action-stroke-input-danger-disabled)",
        // Tags
        // Status
        "tag-content-status": "var(--fui-color-tag-content-status)",
        "tag-bg-status": "var(--fui-color-tag-bg-status)",
        // Success
        "tag-content-success": "var(--fui-color-tag-content-success)",
        "tag-bg-success": "var(--fui-color-tag-bg-success)",
        // Warning
        "tag-content-warning": "var(--fui-color-tag-content-warning)",
        "tag-bg-warning": "var(--fui-color-tag-bg-warning)",
        // Danger
        "tag-content-danger": "var(--fui-color-tag-content-danger)",
        "tag-bg-danger": "var(--fui-color-tag-bg-danger)",
        // 1
        "tag-content-1": "var(--fui-color-tag-content-1)",
        "tag-bg-1": "var(--fui-color-tag-bg-1)",
        // 2
        "tag-content-2": "var(--fui-color-tag-content-2)",
        "tag-bg-2": "var(--fui-color-tag-bg-2)",
        // 3
        "tag-content-3": "var(--fui-color-tag-content-3)",
        "tag-bg-3": "var(--fui-color-tag-bg-3)",
        // 4
        "tag-content-4": "var(--fui-color-tag-content-4)",
        "tag-bg-4": "var(--fui-color-tag-bg-4)",
        // 5
        "tag-content-5": "var(--fui-color-tag-content-5)",
        "tag-bg-5": "var(--fui-color-tag-bg-5)",
        // 6
        "tag-content-6": "var(--fui-color-tag-content-6)",
        "tag-bg-6": "var(--fui-color-tag-bg-6)"
      },
      fontFamily: {
        sans: [
          h,
          {
            fontFeatureSettings: '"tnum" on, "lnum" on, "ss02" on, "salt" on, "liga" off'
          }
        ],
        serif: y
      },
      fontSize: {
        t1: [
          "var(--fui-font-s-2)",
          { fontWeight: "var(--fui-font-weight-1)", lineHeight: "1.5" }
        ],
        "t1-style-1": [
          "var(--fui-font-s-2)",
          { fontWeight: "var(--fui-font-weight-2)", lineHeight: "1.5" }
        ],
        "t1-style-2": [
          "var(--fui-font-s-2)",
          { fontWeight: "var(--fui-font-weight-2)", lineHeight: "0.7" }
        ],
        t2: [
          "var(--fui-font-s-1)",
          { fontWeight: "var(--fui-font-weight-1)", lineHeight: "1.42857143" }
        ],
        "t2-style-1": [
          "var(--fui-font-s-1)",
          { fontWeight: "var(--fui-font-weight-2)", lineHeight: "1.42857143" }
        ],
        "t2-style-2": [
          "var(--fui-font-s-1)",
          { fontWeight: "var(--fui-font-weight-3)", lineHeight: "1.42857143" }
        ],
        "t2-style-3": [
          "var(--fui-font-s-1)",
          { fontWeight: "var(--fui-font-weight-2)", lineHeight: "0.7" }
        ],
        t3: [
          "var(--fui-font-s-0)",
          { fontWeight: "var(--fui-font-weight-1)", lineHeight: "1.33333333" }
        ],
        "t3-style-1": [
          "var(--fui-font-s-0)",
          { fontWeight: "var(--fui-font-weight-2)", lineHeight: "1.33333333" }
        ],
        "t3-style-2": [
          "var(--fui-font-s-0)",
          { fontWeight: "var(--fui-font-weight-2)", lineHeight: "0.7" }
        ],
        h1: [
          "var(--fui-font-s-8)",
          { fontWeight: "var(--fui-font-weight-1)", lineHeight: "1.14285714" }
        ],
        h2: [
          "var(--fui-font-s-7)",
          { fontWeight: "var(--fui-font-weight-1)", lineHeight: "1.17647059" }
        ],
        h3: [
          "var(--fui-font-s-4)",
          { fontWeight: "var(--fui-font-weight-3)", lineHeight: "1.2" }
        ],
        h4: [
          "var(--fui-font-s-2)",
          { fontWeight: "var(--fui-font-weight-3)", lineHeight: "1.25" }
        ]
      },
      fontWeight: {
        normal: "var(--fui-font-weight-1)",
        medium: "var(--fui-font-weight-2)",
        semibold: "var(--fui-font-weight-3)"
      },
      extend: {
        keyframes: {
          flashing: {
            "50%": { opacity: "0.5" }
          },
          hide: {
            from: { opacity: "1" },
            to: { opacity: "0" }
          },
          "slide-up-and-fade": {
            from: { opacity: "0", transform: "translateY(6px)" },
            to: { opacity: "1", transform: "translateY(0)" }
          }
        },
        animation: {
          "dot-flashing": "flashing 1s cubic-bezier(0.4, 0, 0.6, 1) infinite alternate",
          hide: "hide 150ms cubic-bezier(0.16, 1, 0.3, 1)",
          "slide-up-and-fade": "slide-up-and-fade 150ms cubic-bezier(0.16, 1, 0.3, 1)"
        }
      }
    }
  }
);
export {
  x as flareUiPlugin
};
