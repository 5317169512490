import { jsxs as _, jsx as o } from "react/jsx-runtime";
import * as s from "react";
import { forwardRef as j } from "react";
import { u as D } from "../../index-CCavb-1K.js";
import { c as M, a as O, b as w } from "../../index-C5hYt6jn.js";
import { u as z } from "../../index-BZPx6jYI.js";
import { u as A } from "../../index-CicxFo47.js";
import { P as H } from "../../index-DXZDAYJp.js";
import { P } from "../../index-u2zzFwMH.js";
import { cn as K } from "../../utils/cn.js";
import { focusRing as q } from "../../utils/focus-ring.js";
var C = "Checkbox", [T, ae] = M(C), [X, F] = T(C), E = s.forwardRef(
  (e, d) => {
    const {
      __scopeCheckbox: t,
      name: i,
      checked: p,
      defaultChecked: n,
      required: m,
      disabled: l,
      value: b = "on",
      onCheckedChange: f,
      ...x
    } = e, [a, k] = s.useState(null), L = D(d, (r) => k(r)), v = s.useRef(!1), y = a ? !!a.closest("form") : !0, [u = !1, g] = O({
      prop: p,
      defaultProp: n,
      onChange: f
    }), B = s.useRef(u);
    return s.useEffect(() => {
      const r = a == null ? void 0 : a.form;
      if (r) {
        const h = () => g(B.current);
        return r.addEventListener("reset", h), () => r.removeEventListener("reset", h);
      }
    }, [a, g]), /* @__PURE__ */ _(X, { scope: t, state: u, disabled: l, children: [
      /* @__PURE__ */ o(
        P.button,
        {
          type: "button",
          role: "checkbox",
          "aria-checked": c(u) ? "mixed" : u,
          "aria-required": m,
          "data-state": I(u),
          "data-disabled": l ? "" : void 0,
          disabled: l,
          value: b,
          ...x,
          ref: L,
          onKeyDown: w(e.onKeyDown, (r) => {
            r.key === "Enter" && r.preventDefault();
          }),
          onClick: w(e.onClick, (r) => {
            g((h) => c(h) ? !0 : !h), y && (v.current = r.isPropagationStopped(), v.current || r.stopPropagation());
          })
        }
      ),
      y && /* @__PURE__ */ o(
        W,
        {
          control: a,
          bubbles: !v.current,
          name: i,
          value: b,
          checked: u,
          required: m,
          disabled: l,
          style: { transform: "translateX(-100%)" }
        }
      )
    ] });
  }
);
E.displayName = C;
var R = "CheckboxIndicator", S = s.forwardRef(
  (e, d) => {
    const { __scopeCheckbox: t, forceMount: i, ...p } = e, n = F(R, t);
    return /* @__PURE__ */ o(H, { present: i || c(n.state) || n.state === !0, children: /* @__PURE__ */ o(
      P.span,
      {
        "data-state": I(n.state),
        "data-disabled": n.disabled ? "" : void 0,
        ...p,
        ref: d,
        style: { pointerEvents: "none", ...e.style }
      }
    ) });
  }
);
S.displayName = R;
var W = (e) => {
  const { control: d, checked: t, bubbles: i = !0, ...p } = e, n = s.useRef(null), m = z(t), l = A(d);
  return s.useEffect(() => {
    const b = n.current, f = window.HTMLInputElement.prototype, a = Object.getOwnPropertyDescriptor(f, "checked").set;
    if (m !== t && a) {
      const k = new Event("click", { bubbles: i });
      b.indeterminate = c(t), a.call(b, c(t) ? !1 : t), b.dispatchEvent(k);
    }
  }, [m, t, i]), /* @__PURE__ */ o(
    "input",
    {
      type: "checkbox",
      "aria-hidden": !0,
      defaultChecked: c(t) ? !1 : t,
      ...p,
      tabIndex: -1,
      ref: n,
      style: {
        ...e.style,
        ...l,
        position: "absolute",
        pointerEvents: "none",
        opacity: 0,
        margin: 0
      }
    }
  );
};
function c(e) {
  return e === "indeterminate";
}
function I(e) {
  return c(e) ? "indeterminate" : e ? "checked" : "unchecked";
}
var N = E, $ = S;
const G = j(({ checked: e, className: d, ...t }, i) => /* @__PURE__ */ o(
  N,
  {
    ref: i,
    checked: e,
    className: K(
      // Base
      "peer flex size-s-2 shrink-0 items-center justify-center rounded-xs border border-action-stroke-outline-default",
      // Checked
      "enabled:data-[state=checked]:border-action-bg-primary-default enabled:data-[state=checked]:bg-action-bg-primary-default enabled:data-[state=checked]:text-action-content-primary-default",
      // Indeterminate
      "enabled:data-[state=indeterminate]:border-action-bg-primary-default  enabled:data-[state=indeterminate]:bg-action-bg-primary-default  enabled:data-[state=indeterminate]:text-action-content-primary-default",
      // Hover
      "enabled:hover:bg-action-bg-outline-hover enabled:hover:data-[state=checked]:border-action-bg-primary-hover enabled:hover:data-[state=indeterminate]:border-action-bg-primary-hover enabled:hover:data-[state=checked]:bg-action-bg-primary-hover enabled:hover:data-[state=indeterminate]:bg-action-bg-primary-hover",
      // Disabled
      "disabled:border-action-stroke-outline-disabled disabled:bg-action-bg-primary-disabled disabled:text-action-content-primary-disabled disabled:data-[state=checked]:border-action-bg-primary-disabled disabled:data-[state=indeterminate]:border-action-bg-primary-disabled",
      // Focus
      q,
      d
    ),
    ...t,
    children: /* @__PURE__ */ o($, { children: e === "indeterminate" ? /* @__PURE__ */ o(
      "svg",
      {
        xmlns: "http://www.w3.org/2000/svg",
        width: "16",
        height: "16",
        viewBox: "0 0 16 16",
        fill: "none",
        children: /* @__PURE__ */ o("rect", { x: "4", y: "7.25", width: "8", height: "1.5", fill: "currentColor" })
      }
    ) : /* @__PURE__ */ o(
      "svg",
      {
        xmlns: "http://www.w3.org/2000/svg",
        width: "16",
        height: "16",
        viewBox: "0 0 16 16",
        fill: "none",
        children: /* @__PURE__ */ o(
          "path",
          {
            d: "M4.49985 8.12824L6.03096 9.85389C6.46957 10.3482 7.24196 10.347 7.67896 9.85122L11.5145 5.5",
            stroke: "currentColor",
            strokeWidth: "1.2",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        )
      }
    ) })
  }
));
G.displayName = N.displayName;
export {
  G as Checkbox
};
