import * as Sentry from "@sentry/nextjs";

import { analytics } from "@flare/analytics";

import type {
  GetVisitorStatusQuery,
  VisitorStatusCode,
} from "@/generated/graphql.vinny";
import { GetVisitorStatusDocument } from "@/generated/graphql.vinny";
import { client as apolloClient } from "@/lib/apollo-client";
import { BiEventsNames } from "@/services/analytics/event-names";

import type { FunnelAnswers } from "../config";

type GetVisitorStatusCodeArgs = {
  answers: FunnelAnswers;
  currentStep: string;
};

export async function getVisitorStatusCode({
  answers,
  currentStep,
}: GetVisitorStatusCodeArgs) {
  try {
    const res = await apolloClient.query<GetVisitorStatusQuery>({
      query: GetVisitorStatusDocument,
      variables: {
        visitorStatusInput: { answers },
      },
    });

    const { statusCode } = res.data.getVisitorStatus;

    return statusCode;
  } catch (error) {
    analytics.track(BiEventsNames.WebFunnelError, {
      current_step_key: currentStep,
      error_type: "error-get-visitor-status-code-for-navigation",
      error_message: error instanceof Error ? error.message : "unknown error",
    });
    Sentry.captureException(error, {
      extra: {
        message: `error fetching visitor status code for funnel navigation`,
      },
    });
    return "ERROR";
  }
}

export function isVisitorStatusCodeAllowLss(
  statusCode: VisitorStatusCode | "ERROR",
) {
  // allow lss for client who get is eligible for lss or get an error during getVisitorStatusCode validation
  return statusCode === "ELG1" || statusCode === "ERROR";
}
