import { Checkmark } from "@/components/icons/Checkmark";
import { Heading } from "@/components/v2/Heading/Heading";
import { Text } from "@/components/v2/Text/Text";

export function PriceInfoCard({
  nextStepsText,
  price,
}: {
  nextStepsText: string;
  price: number;
}) {
  return (
    <div className="flex flex-col gap-4 rounded-lg bg-green-20 px-5 pb-6 pt-5">
      <div className="flex items-center justify-between">
        <Text variant="text-2">One-time fee</Text>
        <Heading variant="h5">{`$${price}`}</Heading>
      </div>
      <div className="h-px w-full rounded-[100px] bg-blue-40"></div>
      <ul className="flex flex-col gap-3">
        <li className="flex items-center gap-2">
          <Checkmark height={18} width={18} />
          <Text variant="text-5">20 min call with an experienced attorney</Text>
        </li>
        <li className="flex items-center  gap-2">
          <Checkmark height={18} width={18} />
          <Text variant="text-5">{nextStepsText}</Text>
        </li>
      </ul>
    </div>
  );
}
