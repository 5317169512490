import type { JSX } from "react";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import isEmpty from "lodash/isEmpty";

import { ExclamationMarkCircle } from "@/components/icons/ExclamationMarkCircle";
import { Text } from "@/components/v2/Text/Text";

import { DayPicker } from "./components/DayPicker";
import { TimePicker } from "./components/TimePicker";
import { getTimeZoneAbbreviation } from "./Scheduler.utils";

const clientTimeZone = getTimeZoneAbbreviation() || "";

export type Slot = {
  [key: string]: unknown;
  start: Date;
};

export type Slots = Record<string, Slot[]>;

type SchedulerProps = {
  getDefaultSelectedDay?: (slots: Slots) => string;
  loading?: boolean;
  name: string;
  onDayChange?: (selectedDay: string) => void;
  renderSlotListTopElement?: (selectedDay: string) => JSX.Element | undefined;
  shouldShowSlot?: (slot: Slot) => boolean;
  slots: Slots;
};

export function Scheduler({
  name,
  loading = false,
  slots,
  onDayChange,
  getDefaultSelectedDay,
  shouldShowSlot = () => true,
  renderSlotListTopElement,
}: SchedulerProps) {
  const {
    setValue,
    formState: { errors },
  } = useFormContext();

  const [selectedDay, setSelectedDay] = useState("");

  const slotListTopElement = renderSlotListTopElement?.(selectedDay);

  function resetSelectedTimeSlot() {
    setValue(name, null);
  }

  function handleDayChange(selectedDay: string) {
    setSelectedDay(selectedDay);
    resetSelectedTimeSlot();
    onDayChange?.(selectedDay);
    setValue("date", undefined);
  }

  const defaultSelectedDay = getDefaultSelectedDay?.(slots);

  useEffect(() => {
    if (isEmpty(slots) || !defaultSelectedDay) return;
    setSelectedDay(defaultSelectedDay);
  }, [defaultSelectedDay, slots]);

  const selectedDayTimeSlots = slots?.[selectedDay];

  const hasError = Boolean(errors?.[name]);
  const errorMessage = hasError && (errors?.[name]?.message as string);

  return (
    <>
      {hasError && (
        <div className="absolute right-1/2 top-4 flex translate-x-1/2 items-center justify-center gap-2 rounded-full  border border-orange-100 bg-white-80 px-3 py-2 shadow-md transition-opacity md:top-5">
          <ExclamationMarkCircle height="18" width="18" />
          <Text variant="text-5" className="min-w-max">
            {errorMessage}
          </Text>
        </div>
      )}
      <DayPicker
        slots={slots}
        onValueChange={handleDayChange}
        loading={loading || !selectedDay}
        defaultValue={selectedDay}
      />
      <TimePicker
        timeSlots={selectedDayTimeSlots}
        timeZone={clientTimeZone}
        loading={loading || !selectedDayTimeSlots}
        name={name}
        slotListTopElement={slotListTopElement}
        shouldShowSlot={shouldShowSlot}
      />
    </>
  );
}
