import type { JSX } from "react";
import { format } from "date-fns";

import { Skeleton } from "@/components/Skeleton";
import { Text } from "@/components/v2/Text/Text";

import { ChoiceBox, ChoiceBoxItem } from "../../ChoiceBox";
import type { Slot } from "../Scheduler";

const SKELETON_COUNT = 7;

type TimePickerProps = {
  loading?: boolean;
  name: string;
  shouldShowSlot: (slot: Slot) => boolean;
  slotListTopElement?: JSX.Element;
  timeSlots: { start: Date }[];
  timeZone: string;
};

export function TimePicker({
  name,
  timeSlots = [],
  timeZone,
  loading,
  shouldShowSlot,
  slotListTopElement,
}: TimePickerProps) {
  return (
    <div className="flex-1 overflow-y-auto">
      <ChoiceBox
        name={name}
        aria-label="Time picker"
        required
        className="gap-2 md:gap-2"
      >
        {loading ? (
          [...Array(SKELETON_COUNT)].map((_, index) => (
            <Skeleton key={index} className="h-[64px] w-full md:h-[68px]" />
          ))
        ) : (
          <>
            {slotListTopElement}
            {timeSlots?.map((slot) => {
              const startTimeDisplay = format(slot.start, "h:mm aaa");
              const startTimeInUTC = slot.start.toISOString();
              return (
                shouldShowSlot(slot) && (
                  <ChoiceBoxItem
                    aria-label="Time slot"
                    value={startTimeInUTC}
                    key={startTimeInUTC}
                    className="py-5 hover:bg-green-20"
                  >
                    <Text variant="text-3">{`${startTimeDisplay} ${timeZone}`}</Text>
                  </ChoiceBoxItem>
                )
              );
            })}
          </>
        )}
      </ChoiceBox>
    </div>
  );
}
