import React, { useState } from "react";
import { useController, useFormContext } from "react-hook-form";
import { Builder } from "@builder.io/react";
import { startCase } from "lodash";

import { analytics } from "@flare/analytics";

import { ZIP_CODE } from "@/builder/components-sections";
import { LocationIcon } from "@/components/icons/LocationIcon";
import { Text } from "@/components/v2/Text/Text";
import { funnelInputsNameMap } from "@/modules/v2/funnel/config";
import { useFunnelAnswers } from "@/modules/v2/funnel/hooks/useFunnelAnswers";
import { BiEventsNames } from "@/services/analytics/event-names";

import { Input } from "../base/Input";

import type { ZipCodeProps } from "./ZipCode.types";
import {
  formatAddress,
  getAddress,
  isStateSupported,
  normalizeStateName,
  supportedPractices,
} from "./ZipCode.utils";

const ERROR_MESSAGE = "Enter a valid zip code";
const INPUT_NAME = funnelInputsNameMap.zipCode;
const DEFAULT_PRACTICE = supportedPractices.FAMILY;

export function ZipCode({ practice = DEFAULT_PRACTICE }: ZipCodeProps) {
  const {
    watch,
    setValue,
    setError,
    clearErrors,
    trigger,
    formState: { errors },
  } = useFormContext();
  const { field } = useController({
    name: INPUT_NAME,
    rules: { required: true, minLength: 5 },
  });
  const { funnelAnswers } = useFunnelAnswers();
  const [inputText, setInputText] = useState(funnelAnswers?.zipCode || "");

  const county = watch("county", funnelAnswers?.county);
  const stateDisplayText = watch(
    "stateDisplayText",
    funnelAnswers?.stateDisplayText,
  );

  const formattedAddress = formatAddress({ county, state: stateDisplayText });

  const hasError = Boolean(errors?.[field.name]);
  const error = hasError && (errors?.[field.name]?.message || ERROR_MESSAGE);

  const setValues = ({
    county = "",
    stateName = "",
    stateCode = "",
    stateDisplayText = "",
    zipCode = "",
  } = {}) => {
    setValue("county", county);
    setValue("state", stateName);
    setValue("stateCode", stateCode);
    setValue("stateDisplayText", stateDisplayText);
    setValue(field.name, zipCode);
  };

  async function validate(zipCode: string) {
    const { county, state, stateCode } = await getAddress(zipCode);
    const normalizedStateName = normalizeStateName(state);
    const stateName = isStateSupported(normalizedStateName, practice)
      ? normalizedStateName
      : "unsupported";
    const stateDisplayText = startCase(state);
    if (!state) {
      setError(field.name, { message: ERROR_MESSAGE });
      setValues();
      trigger();
      analytics.track(BiEventsNames.WebFunnelError, {
        current_step_key: field.name,
        answer_value: { zipCode, county, state },
        error_type: "invalid_zip_code",
      });
    } else {
      if (state && !county) {
        analytics.track(BiEventsNames.WebFunnelAlert, {
          current_step_key: field.name,
          answer_value: { zipCode, state },
          error_type: "no_county_zip_code",
        });
      }
      clearErrors();
      setValues({ county, stateName, stateCode, stateDisplayText, zipCode });
      trigger();
    }
  }

  async function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value: newValue } = event.target;
    const isNumber = /^[\d]*$/.test(newValue);
    const isLengthValid = isNumber && newValue.length === 5;
    if (!isNumber || newValue.length > 5) return;
    setInputText(newValue);
    if (isLengthValid) {
      validate(newValue);
    } else {
      clearErrors();
      setValues();
    }
  }

  return (
    <div className="flex min-h-[114px] flex-col justify-start gap-2">
      <Input
        name={field.name}
        ref={field.ref}
        inputMode="decimal"
        label="Zip code"
        placeholder="Enter your zip code"
        error={hasError}
        hint={typeof error === "string" ? error : undefined}
        value={inputText ?? ""}
        onChange={handleChange}
      />
      {formattedAddress && (
        <div className="flex items-center justify-start gap-1">
          <LocationIcon height={24} width={24} />
          <Text variant="text-5" className="subpixel-antialiased">
            {formattedAddress}
          </Text>
        </div>
      )}
    </div>
  );
}

Builder.registerComponent(ZipCode, {
  name: ZIP_CODE,
  inputs: [
    {
      name: "practice",
      type: "string",
      required: true,
      enum: Object.keys(supportedPractices),
      defaultValue: DEFAULT_PRACTICE,
      helperText: "Practice determines the states that are available to select",
    },
  ],
});
