import { jsx as n } from "react/jsx-runtime";
import * as w from "react";
import c, { forwardRef as C, useState as A } from "react";
import { a as G, b as E, c as M } from "../../index-C5hYt6jn.js";
import { P as v } from "../../index-u2zzFwMH.js";
import { c as T, u as k, R as F, I as D } from "../../index-4Zu8hh6E.js";
import { c as z } from "../../index-BwYCDOHZ.js";
import { cn as h } from "../../utils/cn.js";
import { focusRing as O } from "../../utils/focus-ring.js";
var B = "Toggle", I = w.forwardRef((e, o) => {
  const { pressed: r, defaultPressed: t = !1, onPressedChange: i, ...s } = e, [l = !1, a] = G({
    prop: r,
    onChange: i,
    defaultProp: t
  });
  return /* @__PURE__ */ n(
    v.button,
    {
      type: "button",
      "aria-pressed": l,
      "data-state": l ? "on" : "off",
      "data-disabled": e.disabled ? "" : void 0,
      ...s,
      ref: o,
      onClick: E(e.onClick, () => {
        e.disabled || a(!l);
      })
    }
  );
});
I.displayName = B;
var g = "ToggleGroup", [y, ae] = M(g, [
  T
]), _ = T(), P = c.forwardRef((e, o) => {
  const { type: r, ...t } = e;
  if (r === "single")
    return /* @__PURE__ */ n(j, { ...t, ref: o });
  if (r === "multiple")
    return /* @__PURE__ */ n(H, { ...t, ref: o });
  throw new Error(`Missing prop \`type\` expected on \`${g}\``);
});
P.displayName = g;
var [R, x] = y(g), j = c.forwardRef((e, o) => {
  const {
    value: r,
    defaultValue: t,
    onValueChange: i = () => {
    },
    ...s
  } = e, [l, a] = G({
    prop: r,
    defaultProp: t,
    onChange: i
  });
  return /* @__PURE__ */ n(
    R,
    {
      scope: e.__scopeToggleGroup,
      type: "single",
      value: l ? [l] : [],
      onItemActivate: a,
      onItemDeactivate: c.useCallback(() => a(""), [a]),
      children: /* @__PURE__ */ n(S, { ...s, ref: o })
    }
  );
}), H = c.forwardRef((e, o) => {
  const {
    value: r,
    defaultValue: t,
    onValueChange: i = () => {
    },
    ...s
  } = e, [l = [], a] = G({
    prop: r,
    defaultProp: t,
    onChange: i
  }), p = c.useCallback(
    (d) => a((u = []) => [...u, d]),
    [a]
  ), m = c.useCallback(
    (d) => a((u = []) => u.filter((V) => V !== d)),
    [a]
  );
  return /* @__PURE__ */ n(
    R,
    {
      scope: e.__scopeToggleGroup,
      type: "multiple",
      value: l,
      onItemActivate: p,
      onItemDeactivate: m,
      children: /* @__PURE__ */ n(S, { ...s, ref: o })
    }
  );
});
P.displayName = g;
var [L, U] = y(g), S = c.forwardRef(
  (e, o) => {
    const {
      __scopeToggleGroup: r,
      disabled: t = !1,
      rovingFocus: i = !0,
      orientation: s,
      dir: l,
      loop: a = !0,
      ...p
    } = e, m = _(r), d = k(l), u = { role: "group", dir: d, ...p };
    return /* @__PURE__ */ n(L, { scope: r, rovingFocus: i, disabled: t, children: i ? /* @__PURE__ */ n(
      F,
      {
        asChild: !0,
        ...m,
        orientation: s,
        dir: d,
        loop: a,
        children: /* @__PURE__ */ n(v.div, { ...u, ref: o })
      }
    ) : /* @__PURE__ */ n(v.div, { ...u, ref: o }) });
  }
), f = "ToggleGroupItem", N = c.forwardRef(
  (e, o) => {
    const r = x(f, e.__scopeToggleGroup), t = U(f, e.__scopeToggleGroup), i = _(e.__scopeToggleGroup), s = r.value.includes(e.value), l = t.disabled || e.disabled, a = { ...e, pressed: s, disabled: l }, p = c.useRef(null);
    return t.rovingFocus ? /* @__PURE__ */ n(
      D,
      {
        asChild: !0,
        ...i,
        focusable: !l,
        active: s,
        ref: p,
        children: /* @__PURE__ */ n(b, { ...a, ref: o })
      }
    ) : /* @__PURE__ */ n(b, { ...a, ref: o });
  }
);
N.displayName = f;
var b = c.forwardRef(
  (e, o) => {
    const { __scopeToggleGroup: r, value: t, ...i } = e, s = x(f, r), l = { role: "radio", "aria-checked": e.pressed, "aria-pressed": void 0 }, a = s.type === "single" ? l : void 0;
    return /* @__PURE__ */ n(
      I,
      {
        ...a,
        ...i,
        ref: o,
        onPressedChange: (p) => {
          p ? s.onItemActivate(t) : s.onItemDeactivate(t);
        }
      }
    );
  }
), W = P, $ = N;
const q = z(
  "group inline-grid auto-cols-fr grid-flow-col items-stretch gap-[calc(var(--fui-subline)/2)] rounded-md bg-action-secondary-4 p-[calc(var(--fui-subline)/2)]",
  {
    variants: {
      size: {
        sm: "h-s-7 text-t2-style-3",
        md: "h-s-8 text-t1-style-2"
      },
      fullWidth: { true: "grid" }
    }
  }
), J = C(
  ({
    className: e,
    size: o = "md",
    fullWidth: r = !1,
    defaultValue: t,
    children: i,
    onValueChange: s,
    ...l
  }, a) => {
    const [p, m] = A(t);
    function d(u) {
      u && (m(u), s == null || s(u));
    }
    return /* @__PURE__ */ n(
      W,
      {
        ref: a,
        className: h(q({ size: o, fullWidth: r }), e),
        type: "single",
        "data-size": o,
        value: p,
        onValueChange: d,
        ...l,
        children: i
      }
    );
  }
);
J.displayName = "SegmentedControl";
const K = C(({ className: e, children: o, ...r }, t) => /* @__PURE__ */ n(
  $,
  {
    ref: t,
    className: h(
      O,
      "rounded-[calc(var(--fui-br-md)-var(--fui-subline)/2)] transition-colors disabled:text-action-disable-0 data-[state=on]:bg-action-secondary-6 hover:data-[state=off]:bg-action-secondary-5 disabled:data-[state=off]:bg-transparent group-data-[size=md]:p-sp-3 group-data-[size=sm]:p-sp-2",
      e
    ),
    ...r,
    children: o
  }
));
K.displayName = "SegmentedControlItem";
export {
  J as SegmentedControl,
  K as SegmentedControlItem
};
