import * as c from "react";
import { jsx as P } from "react/jsx-runtime";
function $(t, e = []) {
  let n = [];
  function s(u, f) {
    const r = c.createContext(f), a = n.length;
    n = [...n, f];
    function i(l) {
      const { scope: m, children: x, ...d } = l, h = (m == null ? void 0 : m[t][a]) || r, p = c.useMemo(() => d, Object.values(d));
      return /* @__PURE__ */ P(h.Provider, { value: p, children: x });
    }
    function C(l, m) {
      const x = (m == null ? void 0 : m[t][a]) || r, d = c.useContext(x);
      if (d) return d;
      if (f !== void 0) return f;
      throw new Error(`\`${l}\` must be used within \`${u}\``);
    }
    return i.displayName = u + "Provider", [i, C];
  }
  const o = () => {
    const u = n.map((f) => c.createContext(f));
    return function(r) {
      const a = (r == null ? void 0 : r[t]) || u;
      return c.useMemo(
        () => ({ [`__scope${t}`]: { ...r, [t]: a } }),
        [r, a]
      );
    };
  };
  return o.scopeName = t, [s, b(o, ...e)];
}
function b(...t) {
  const e = t[0];
  if (t.length === 1) return e;
  const n = () => {
    const s = t.map((o) => ({
      useScope: o(),
      scopeName: o.scopeName
    }));
    return function(u) {
      const f = s.reduce((r, { useScope: a, scopeName: i }) => {
        const l = a(u)[`__scope${i}`];
        return { ...r, ...l };
      }, {});
      return c.useMemo(() => ({ [`__scope${e.scopeName}`]: f }), [f]);
    };
  };
  return n.scopeName = e.scopeName, n;
}
function y(t, e, { checkForDefaultPrevented: n = !0 } = {}) {
  return function(o) {
    if (t == null || t(o), n === !1 || !o.defaultPrevented)
      return e == null ? void 0 : e(o);
  };
}
function S(t) {
  const e = c.useRef(t);
  return c.useEffect(() => {
    e.current = t;
  }), c.useMemo(() => (...n) => {
    var s;
    return (s = e.current) == null ? void 0 : s.call(e, ...n);
  }, []);
}
function M({
  prop: t,
  defaultProp: e,
  onChange: n = () => {
  }
}) {
  const [s, o] = _({ defaultProp: e, onChange: n }), u = t !== void 0, f = u ? t : s, r = S(n), a = c.useCallback(
    (i) => {
      if (u) {
        const l = typeof i == "function" ? i(t) : i;
        l !== t && r(l);
      } else
        o(i);
    },
    [u, t, o, r]
  );
  return [f, a];
}
function _({
  defaultProp: t,
  onChange: e
}) {
  const n = c.useState(t), [s] = n, o = c.useRef(s), u = S(e);
  return c.useEffect(() => {
    o.current !== s && (u(s), o.current = s);
  }, [s, o, u]), n;
}
var R = globalThis != null && globalThis.document ? c.useLayoutEffect : () => {
};
export {
  M as a,
  y as b,
  $ as c,
  S as d,
  R as u
};
